import { combineReducers } from 'redux';

import * as contentdeliveryframeworkConstants from './contentdeliveryframework/constants';
import contentdeliveryframeworkReducer from './contentdeliveryframework/reducer';
import * as receiptsConstants from './receipts/constants';
import receiptsReducer from './receipts/reducer';
import * as serverSettingsConstants from './serverSettings/constants';
import serverSettingsReducer from './serverSettings/reducer';
import * as analyticsConstants from './analytics/constants';
import analyticsReducer from './analytics/reducer';
import * as teamsConstants from './teams/constants';
import teamsReducer from './teams/reducer';
import * as playersConstants from './players/constants';
import playersReducer from './players/reducer';
import * as inventoryValidatorConstants from './inventoryValidator/constants';
import inventoryValidatorReducer from './inventoryValidator/reducer';
import * as cheaterManagementConstants from './cheaterManagement/constants';
import cheaterManagementReducer from './cheaterManagement/reducer';
import * as chatManagementConstants from './chatManagement/constants';
import chatManagementReducer from './chatManagement/reducer';
import * as leaderboardsConstants from './leaderboards/constants';
import leaderboardsReducer from './leaderboards/reducer';
import * as tournamentsConstants from './tournaments/constants';
import tournamentsReducer from './tournaments/reducer';
import * as tournamentConfigConstants from './tournamentManagement/constants';
import tournamentConfigReducer from './tournamentManagement/reducer';
import * as susanConstants from './susan/constants';
import susanReducer from './susan/reducer';

// TODO - Once Material UI is updated, we should start using the same event stream BF1 uses.
// The reason why we are not using it now is that some components are not
// functioning expectedly with our current version of Material UI. This would
// have resulted in an inconvenient workflow for player support.
import * as eventStreamsConstants from './eventStreams/constants';
import eventStreamsReducer from './eventStreams/reducer';

export default combineReducers({
  [contentdeliveryframeworkConstants.NAME]: contentdeliveryframeworkReducer,
  [receiptsConstants.NAME]: receiptsReducer,
  [serverSettingsConstants.NAME]: serverSettingsReducer,
  [analyticsConstants.NAME]: analyticsReducer,
  [cheaterManagementConstants.NAME]: cheaterManagementReducer,
  [chatManagementConstants.NAME]: chatManagementReducer,
  [teamsConstants.NAME]: teamsReducer,
  [playersConstants.NAME]: playersReducer,
  [leaderboardsConstants.NAME]: leaderboardsReducer,
  [inventoryValidatorConstants.NAME]: inventoryValidatorReducer,
  [tournamentsConstants.NAME]: tournamentsReducer,
  [eventStreamsConstants.NAME]: eventStreamsReducer,
  [tournamentConfigConstants.NAME]: tournamentConfigReducer,
  [susanConstants.NAME]: susanReducer
});
