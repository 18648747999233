/*eslint no-console: 0*/
import isoFetch from 'isomorphic-fetch';
import { type, isNil } from 'ramda';
import { api_path } from '~/config';
import R from 'ramda';
import { dateFormat } from './constants';
import dayjs from '~/dayjs';

import queryString from 'query-string';

export const adjustPath = R.curry((path, fn, obj) => R.assocPath(path, fn(R.path(path, obj)), obj));

export const Console = {
  log: console.log.bind(console),
  error: console.error.bind(console),
  warn: console.warn.bind(console)
};

export function timeout(ms) {
  return new Promise(res => setTimeout(res, ms));
}

var mochs = {};
export function mochFetchApi(url, response) {
  console.log('url', url);

  mochs[url] = response;
}

export function fetchApi(url, options) {
  if (type(url) !== 'String') return Promise.reject(url + ' is not a string.');

  if (typeof mochs[url] !== 'undefined') {
    var promise = Promise.resolve(mochs[url]);
    delete mochs[url];
    return promise;
  }

  if (url[0] != '/') url = '/' + url;

  let headers = {
    Accept: 'application/json'
  };

  let contentType = options.contentType || 'application/json';

  // FormData cannot contain contentType because the browser has to set the 'boundary'.
  if (!(options.body instanceof FormData)) {
    headers['Content-Type'] = contentType;
  }

  return isoFetch(api_path + url, {
    ...options,
    headers: {
      ...headers,
      ...options.headers
    },
    body:
      type(options.body) === 'Object' || type(options.body) === 'Array' ? JSON.stringify(options.body) : options.body
  });
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

var ls = typeof localStorage !== 'undefined' ? localStorage : null;
export const LocalStorage = {
  get: id => {
    if (!isNil(ls)) {
      return ls[id];
    } else {
      return;
    }
  },

  set: (id, value) => {
    if (!isNil(ls)) {
      ls[id] = value;
    }
  },
  delete: id => {
    if (!isNil(ls)) delete ls[id];
  }
};

export function isEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

/**
 *  Debounce function calls to func by given number of milliseconds.
 *  In other words, calling the function is throttled by a given number of milliseconds.
 *
 *  func: Function to call
 *  waitInMs: Number of milliseconds to wait
 *  immediate: Should func always be triggered on first call.
 */
export function debounce(func, waitInMs, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, waitInMs);
    if (callNow) func.apply(context, args);
  };
}

/**
 *  Converts a given version string to an integer which is comparable with other converted version numbers.
 *  Assumes that major, minor and patch versions never exceed 255.
 */
export function versionStringToNumeric(version) {
  if (R.isNil(version)) throw new Error('versionStringToNumeric: Invalid version number ' + version);
  let split = version.split('.').map(v => parseInt(v));
  return split[0] * 255 * 255 + split[1] * 255 + split[2];
}

// inPairsOf(2, [1, 2, 3, 4]) -> [[1, 2], [3, 4]]
export const inPairsOf = R.curry(function(n, list) {
  var reduceIndexed = R.addIndex(R.reduce);
  return reduceIndexed(
    function(a, e, idx) {
      if (idx % n == 0) {
        a.push([e]);
      } else {
        R.last(a).push(e);
      }
      return a;
    },
    [],
    list
  );
});

export const deepmerge = (a, b) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return R.union(a, b);
  } else if (R.is(Object, a) && R.is(Object, b)) {
    return R.mergeWith(deepmerge, a, b);
  } else if (R.isNil(b)) {
    return a;
  } else {
    return b;
  }
};

export function objectToQueryString(obj) {
  if (R.isNil(obj)) return '';
  if (typeof obj === 'string') return '?' + obj;
  return '?' + queryString.stringify(obj);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// Converts data URI to a blob, so that it can for example be put in a FormData and uploaded.
export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

/**
    Converts
    { 
        a: { 
            b: 2, 
            c: { 
                d: true
            }
        }
    }

    to:
    {
        "a.b": 2,
        "a.c.d": true
    }

*/
export const flatten = (objectOrArray, prefix = '') => {
  const nestElement = (prev, value, key) =>
    value && typeof value === 'object'
      ? { ...prev, ...flatten(value, `${prefix}${key}.`) }
      : { ...prev, ...{ [`${prefix}${key}`]: value } };

  return Array.isArray(objectOrArray)
    ? objectOrArray.reduce(nestElement, {})
    : Object.keys(objectOrArray).reduce((prev, element) => nestElement(prev, objectOrArray[element], element), {});
};
// Adds ordinal suffix (2nd, 3rd, 10th, etc) to a given number
export function ordinalSuffixOf(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export function getHash(str) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const getUtcDateString = date => {
  const dateString = dayjs(date)
    .utc()
    .format(dateFormat);

  return dateString;
};

export const localAsUtc = date =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  );

export const utcAsLocal = date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = isoCode => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
};

export const isValidVersionNumber = (version, includePatch = true) => {
  const match = version.match(/\d+/g);
  
  if (includePatch && match?.length === 3) {
    return true;
  } else if (!includePatch && match?.length === 2) {
    return true;
  }

  return false;
}

export function formatVersionNumber(input) {
  if(input.length < 3){
    console.error('Trying to format invalid version number'+input);
    return input;
  }
    
  //parses e.g. 1020 into 10.2.0 or 345 into 3.4.5
  //It's agreed practice that two last numbers never go above 9
  const [minor, major, ...first] = input.split('').reverse();
  return `${first.reverse().join('')}.${major}.${minor}`
}

export const heroLevelRegex = /Hero Area_\d{3,} Level_\d{2,}/;

export const isValidHeroLevel = (level) => {
  const match = level.match(heroLevelRegex);

  return match !== null;
}

export const parseHeroLevelNumber = (level) => {
  const match = level.match(heroLevelRegex)
  const isValidMatch = match?.length === 3;

  return {
    area: isValidMatch ? parseInt(match[1]) : NaN,
    level: isValidMatch ? parseInt(match[2]) : NaN
  }
}

export const questLevelRegex = /Level [a-zA-Z]+_01_(\d+)/;

export const isValidQuestLevel = (level) => {
  const match = level.match(questLevelRegex);
  
  return match !== null;
}

export const parseQuestLevelNumber = (level) => {
  const match = level.match(questLevelRegex);
  const isValidMatch = match?.length === 2;

  return isValidMatch ? parseInt(match[1]) : NaN;
}