import { API } from './api';
import { fetchApi, objectToQueryString } from '~/utils';
import R from 'ramda';
import Q from 'q';
import * as authActions from './core/auth/actions';
import { addNotification } from './core/notifications/actions';
import { msalInstance } from './authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

var transactionIdCounter = 0;
const getTransactionId = () => 'uti_' + transactionIdCounter++;

// Middleware which does API requests when proper redux actions are dispatched.
export default function api(context) {
  return next => async action => {
    let config = action[API];
    const transactionId = getTransactionId();
    if (typeof config === 'object') {
      delete action[API];

      const type = action.type;

      next({
        ...action,
        type: type,
        pending: true,
        transactionId
      });

      const authRequest = {
        scopes: [AD_CLIENT_ID + '/.default']
      };
      try {
        if (!msalInstance.getActiveAccount()) {
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }
        const tokenResponse = await msalInstance.acquireTokenSilent(authRequest);
        const userData = {
          id: tokenResponse.idTokenClaims.oid,
          email: tokenResponse.idTokenClaims.preferred_username,
          name: tokenResponse.idTokenClaims.name
        };
        const encodedUserData = btoa(JSON.stringify(userData));
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${tokenResponse.accessToken}`,
          ['X-DashboardUser']: encodedUserData
        };
      } catch (err) {
        console.error('Error loading token', err);
        if (err instanceof InteractionRequiredAuthError) {
          return msalInstance.acquireTokenRedirect(authRequest);
        }
      }

      return doApiCall(config.method, config.url, config.body, config.query, config.headers)
        .then(response => {
          return Q(response.text()).then(body => {
            if (body != null && body.length > 0) {
              try {
                body = JSON.parse(body);
                if (typeof body === 'object' && !R.isNil(body.version)) {
                  if (body.version !== DASHBOARD_VERSION) {
                    next(authActions.serverClientVersionMistmatch(body.version));
                  }
                }
              } catch (e) {
                return Q.reject({
                  status: response.status,
                  payload: body
                });
              }
            }

            if (response.ok) {
              if (body) {
                if (body.success == false) {
                  // Json has success flag false, so we reject.
                  return Q.reject(body);
                } else {
                  return body;
                }
              } else {
                // There was no body, so we assume that because the status code is ok it was a success.
                return {
                  status: response.status,
                  payload: null
                };
              }
            } else {
              // Response was not ok, so we reject.
              if (body) {
                return Q.reject(body);
              } else {
                return Q.reject({
                  status: response.status,
                  payload: null
                });
              }
            }
          });
        })
        .then(result => {
          if (!R.isNil(config.successNotification)) {
            next(addNotification(config.successNotification));
          }

          return next({
            ...action,
            type: type,
            payload: result.payload,
            transactionId
          });
        })
        .catch(result => {
          if (!R.isNil(config.errorNotification)) {
            if (typeof config.errorNotification === 'function') {
              next(addNotification(config.errorNotification(result), 'error'));
            } else {
              next(addNotification(config.errorNotification, 'error'));
            }
          }

          if (result instanceof Error) {
            console.error(`Reducer for the action "${type}" threw an error:`, result);
            return next({
              ...action,
              type: type,
              error: true,
              status: result.status,
              payload: new Error('Call failed with "' + result.toString() + '"'),
              transactionId
            });
          } else {
            return next({
              ...result,
              ...action,
              type: type,
              error: true,
              status: result.status,
              payload: result.error || result.errors || result.payload,
              transactionId
            });
          }
        });
    } else {
      return next(action);
    }
  };
}

export const doApiCall = (method, url, body, query, headers = {}) => {
  return fetchApi(url + objectToQueryString(query), {
    method: method || 'get',
    body: body,
    headers
  });
};
