import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const SEARCH_PLAYER = `${parentName}/${NAME}/SEARCH_PLAYER`;
export const FETCH_PLAYER_DATA = `${parentName}/${NAME}/FETCH_PLAYER_DATA`;
export const FETCH_PLAYER_INVENTORY_HISTORY = `${parentName}/${NAME}/FETCH_PLAYER_INVENTORY_HISTORY`;
export const FETCH_PLAYER_INVENTORY = `${parentName}/${NAME}/FETCH_PLAYER_INVENTORY`;
export const FETCH_PLAYER_AUDIT_LOG_PAGE = `${parentName}/${NAME}/FETCH_PLAYER_AUDIT_LOG_PAGE`;
export const UPDATE_PLAYER_INVENTORY = `${parentName}/${NAME}/UPDATE_PLAYER_INVENTORY`;
export const LOAD_PLAYER_INVENTORY_SNAPSHOT = `${parentName}/${NAME}/LOAD_PLAYER_INVENTORY_SNAPSHOT`;
export const UPDATE_LEADERBOARD_NICKNAME = `${parentName}/${NAME}/UPDATE_LEADERBOARD_NICKNAME`;
export const MARK_FOR_ACCOUNT_RESET = `${parentName}/${NAME}/MARK_FOR_ACCOUNT_RESET`;
export const MARK_PLAYER_NOT_TESTER = `${parentName}/${NAME}/MARK_PLAYER_NOT_TESTER`;
export const MARK_PLAYER_TESTER = `${parentName}/${NAME}/MARK_PLAYER_TESTER`;
export const REMOVE_PLAYER_TEAM_BAN = `${parentName}/${NAME}/REMOVE_PLAYER_TEAM_BAN`;
export const DETACH_SOCIAL_PROVIDER = `${parentName}/${NAME}/DETACH_SOCIAL_PROVIDER`;
export const FETCH_PLAYER_BANS = `${parentName}/${NAME}/FETCH_PLAYER_BANS`;
export const REMOVE_PLAYER_BAN = `${parentName}/${NAME}/REMOVE_PLAYER_BAN`;
export const ADD_PLAYER_BAN = `${parentName}/${NAME}/ADD_PLAYER_BAN`;
export const FETCH_PLAYER_MISSION_DATA = `${parentName}/${NAME}/FETCH_PLAYER_MISSION_DATA`;
export const MIGRATE_PLAYER = `${parentName}/${NAME}/MIGRATE_PLAYER`;
export const CANCEL_PLAYER_MIGRATION = `${parentName}/${NAME}/CANCEL_PLAYER_MIGRATION`;
