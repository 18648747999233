import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import BreadcrumbItem from './BreadcrumbItem';
import classNames from 'classnames';
import R from 'ramda';

const styles = () => ({
  root: {
    listStyle: 'none',
    overflow: 'hidden',
    display: 'block',
    marginTop: '5px',
    marginBottom: '5px'
  }
});

class Breadcrumb extends Component {
  render() {
    const { classes } = this.props;
    var childCount = R.isNil(this.props.children.length)
      ? 1
      : this.props.children.length;
    return (
      <ul
        className={classNames(classes.root, this.props.className)}
        style={this.props.style}
      >
        {React.Children.map(this.props.children, (item, i) => {
          if (item.type.displayName !== BreadcrumbItem.displayName) {
            console.error(
              `Breadcrumb component can only have children of type ${BreadcrumbItem.displayName}. Found: ${item.type.displayName}`
            );
          }
          return (
            <BreadcrumbItem
              key={i}
              {...item.props}
              firstChild={i === 0}
              lastChild={i === childCount - 1}
            />
          );
        })}
      </ul>
    );
  }
}

export default withStyles(styles)(Breadcrumb);
