import React, { Component } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';

const linkStyle = {
  textDecoration: 'underline',
  textDecorationColor: '#000',
  WebkitTextDecorationColor: '#000',
  cursor: 'pointer',
  fontSize: '1em'
};

const disabledStyle = {
  textDecoration: 'none',
  cursor: 'not-allowed',
  fontSize: '1em'
};

class LinkButton extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    linkStyle: PropTypes.object,
    color: PropTypes.string,
    secondaryLabel: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.element
    ])
  };

  constructor(props) {
    super(props);
  }

  render() {
    const ls = this.props.linkStyle
      ? R.merge(linkStyle, this.props.linkStyle)
      : linkStyle;
    let style = this.props.disabled ? disabledStyle : ls;
    if (!R.isNil(this.props.color)) {
      style = R.clone(style);
      style.color = this.props.color;
    }

    return (
      <div onClick={!this.props.disabled ? this.props.onClick : undefined}>
        <div style={style}>{this.props.label}</div>
        {this.props.secondaryLabel && (
          <div style={{ marginTop: 5 }}>{this.props.secondaryLabel}</div>
        )}
      </div>
    );
  }
}

export default LinkButton;
