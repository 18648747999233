import React from 'react';
import { CustomizedSnackbar } from '../../../core/components';
import { connect } from 'react-redux';
import { NAME } from '../constants';
import { NAME as parentName } from '../../constants';
import { closeNotifications } from '../actions';

const SnackbarNotification = ({ variant, message, onClose, isOpen }) => {
  return <CustomizedSnackbar variant={variant} onClose={onClose} message={message} open={isOpen} />;
};

const mapStateToProps = state => {
  return {
    message: state[parentName][NAME].message,
    isOpen: state[parentName][NAME].isOpen,
    variant: state[parentName][NAME].variant
  };
};

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(closeNotifications())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SnackbarNotification);
