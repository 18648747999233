import React, { PureComponent } from 'react';
import { isValidField } from './FieldBase';
import PropTypes from 'prop-types';
import R from 'ramda';

class FastForm extends PureComponent {
  static propTypes = {
    value: PropTypes.object,

    disabled: PropTypes.bool,

    hideUndefined: PropTypes.bool, //does not render fields that do not exist in current save

    skipDefaultValuesOnMount: PropTypes.bool, //does not set fields with default values when components mount, use this on fields where it's important that no values get set that don't already exist in form (for example player inventory)

    // (newValue, isErrored) => void
    onChange: PropTypes.func.isRequired
  };

  handleChange = (newValue, isErrored) => {
    if (!R.isNil(newValue)) {
      this.props.onChange(newValue, isErrored);
    } else {
      console.error('Changed value to null or undefined.');
    }
  };

  render() {
    if (R.isNil(this.props.value)) return null;

    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (isValidField(child)) {
        return React.cloneElement(child, {
          value: this.props.value,
          disabled: child.props.disabled || this.props.disabled,
          hideUndefined: child.props.hideUndefined || this.props.hideUndefined,
          skipDefaultValuesOnMount:
            child.props.skipDefaultValuesOnMount ||
            this.props.skipDefaultValuesOnMount,
          onChange: this.handleChange
        });
      } else {
        return child;
      }
    });

    return <div className='row'>{childrenWithProps}</div>;
  }
}

export default FastForm;
