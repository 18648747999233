import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';
import * as actionTypes from './actionTypes';
import { EVENT_TRACKS } from '../constants';

const initialState = {
  eventApiStatus: null,
  events: {},
  eventConfigs: {
    tournaments: [],
    teamChests: [],
    endGameTournaments: [],
    winStreaks: [],
    missions: [],
    sales: []
  },
  options: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_TOURNAMENT_EVENT:
    case actionTypes.CREATE_TEAM_CHEST_EVENT:
    case actionTypes.CREATE_END_GAME_EVENT:
    case actionTypes.CREATE_WIN_STREAK_EVENT:
    case actionTypes.CREATE_MISSION_EVENT:
    case actionTypes.CREATE_SALE_EVENT:
    case actionTypes.UPDATE_TOURNAMENT_EVENT:
    case actionTypes.UPDATE_TEAM_CHEST_EVENT:
    case actionTypes.UPDATE_END_GAME_EVENT:
    case actionTypes.UPDATE_WIN_STREAK_EVENT:
    case actionTypes.UPDATE_MISSION_EVENT:
    case actionTypes.UPDATE_SALE_EVENT:
      return modifyEvent(state, action);
    case actionTypes.DELETE_TOURNAMENT_EVENT:
    case actionTypes.DELETE_TEAM_CHEST_EVENT:
    case actionTypes.DELETE_END_GAME_EVENT:
    case actionTypes.DELETE_WIN_STREAK_EVENT:
    case actionTypes.DELETE_MISSION_EVENT:
    case actionTypes.DELETE_SALE_EVENT:
      return deleteEvent(state, action);
    case actionTypes.LOAD_EVENTS:
      return fetchEvents(state, action);
    case actionTypes.LOAD_ALL_EVENTS:
      return fetchAllEvents(state, action);
    case actionTypes.LOAD_EVENT_CONFIGS_PREFILL:
      return fetchEventConfigsPrefill(state, action);
    case actionTypes.LOAD_MISSION_EVENT_CONFIGS_PREFILL:
      return fetchMissionEventConfigsPrefill(state, action);
    case actionTypes.LOAD_EVENT_CONFIGS:
      return fetchEventConfigs(state, action);
    case actionTypes.CREATE_TOURNAMENT_EVENT_CONFIG:
      return addTournamentEventConfig(state, action);
    case actionTypes.CREATE_TEAM_CHEST_EVENT_CONFIG:
      return addTeamChestEventConfig(state, action);
    case actionTypes.CREATE_END_GAME_EVENT_CONFIG:
      return addEndGameEventConfig(state, action);
    case actionTypes.CREATE_WIN_STREAK_EVENT_CONFIG:
      return addWinStreakEventConfig(state, action);
    case actionTypes.CREATE_MISSION_EVENT_CONFIG:
      return addMissionEventConfig(state, action);
    case actionTypes.UPDATE_TOURNAMENT_EVENT_CONFIG:
      return updateTournamentEventConfig(state, action);
    case actionTypes.UPDATE_TEAM_CHEST_EVENT_CONFIG:
      return updateTeamChestEventConfig(state, action);
    case actionTypes.UPDATE_END_GAME_EVENT_CONFIG:
      return updateEndGameEventConfig(state, action);
    case actionTypes.UPDATE_WIN_STREAK_EVENT_CONFIG:
      return updateWinStreakEventConfig(state, action);
    case actionTypes.UPDATE_MISSION_EVENT_CONFIG:
      return updateMissionEventConfig(state, action);
    case actionTypes.DELETE_TOURNAMENT_EVENT_CONFIG:
    case actionTypes.DELETE_TEAM_CHEST_EVENT_CONFIG:
    case actionTypes.DELETE_END_GAME_EVENT_CONFIG:
    case actionTypes.DELETE_WIN_STREAK_EVENT_CONFIG:
    case actionTypes.DELETE_MISSION_EVENT_CONFIG:
      return deleteEventConfig(state, action);
    default:
      return state;
  }
};

const modifyEvent = compose(
  createAPIStatusReducer('eventApiStatus', 'Failed to modify event'),
  onSuccess((state, action) => ({
    ...state,
    events: {
      ...state.events,
      [action.payload.id]: action.payload
    }
  }))
);

const newAddEventConfigReducer = configType =>
  compose(
    createAPIStatusReducer('eventConfigStatus', 'Failed to add event config'),
    onSuccess((state, action) => ({
      ...state,
      eventConfigs: {
        ...state.eventConfigs,
        [configType]: [...state.eventConfigs[configType], action.payload]
      }
    }))
  );

const {
  TOURNAMENT,
  TEAM_CHEST,
  END_GAME,
  WIN_STREAK,
  MISSION,
  SALE
} = EVENT_TRACKS;
const addTournamentEventConfig = newAddEventConfigReducer(TOURNAMENT);
const addTeamChestEventConfig = newAddEventConfigReducer(TEAM_CHEST);
const addEndGameEventConfig = newAddEventConfigReducer(END_GAME);
const addWinStreakEventConfig = newAddEventConfigReducer(WIN_STREAK);
const addMissionEventConfig = newAddEventConfigReducer(MISSION);
const addSaleEventConfig = newAddEventConfigReducer(SALE);

const newUpdateEventConfigReducer = configType =>
  compose(
    createAPIStatusReducer(
      'eventConfigStatus',
      'Failed to update event config'
    ),
    onSuccess((state, action) => {
      const { payload } = action;
      const idx = state.eventConfigs[configType].findIndex(
        config => config.id === payload.id
      );
      return {
        ...state,
        eventConfigs: {
          ...state.eventConfigs,
          [configType]: [
            ...state.eventConfigs[configType].slice(0, idx),
            payload,
            ...state.eventConfigs[configType].slice(idx + 1)
          ]
        }
      };
    })
  );

const updateTournamentEventConfig = newUpdateEventConfigReducer(TOURNAMENT);
const updateTeamChestEventConfig = newUpdateEventConfigReducer(TEAM_CHEST);
const updateEndGameEventConfig = newUpdateEventConfigReducer(END_GAME);
const updateWinStreakEventConfig = newUpdateEventConfigReducer(WIN_STREAK);
const updateMissionEventConfig = newUpdateEventConfigReducer(MISSION);

const deleteEvent = compose(
  createAPIStatusReducer('eventApiStatus', 'Failed to delete event'),
  onSuccess((state, action) => {
    // Delete event may have payload if event was actually cancelled instead
    // Cancellation means events end time is set to <now>
    if (action.payload) {
      return {
        ...state,
        events: {
          ...state.events,
          [action.payload.id]: action.payload
        }
      };
    } else {
      const {
        [action.extra.id]: deleted,
        ...eventsWithoutDeleted
      } = state.events;
      return {
        ...state,
        events: eventsWithoutDeleted
      };
    }
  })
);

const deleteEventConfig = compose(
  createAPIStatusReducer('eventApiStatus', 'Failed to delete event config'),
  onSuccess((state, action) => {
    const idx = state.eventConfigs[action.extra.type].findIndex(
      config => config.id === action.extra.id
    );
    return {
      ...state,
      eventConfigs: {
        ...state.eventConfigs,
        [action.extra.type]: state.eventConfigs[action.extra.type]
          .slice(0, idx)
          .concat(state.eventConfigs[action.extra.type].slice(idx + 1))
      }
    };
  })
);

const fetchEvents = compose(
  createAPIStatusReducer('eventApiStatus', 'Failed to load events'),
  onSuccess((state, action) => ({
    ...state,
    events: {
      ...state.events,
      ...action.payload.events
        .map(event => ({
          [`${event.id}`]: event
        }))
        .reduce(
          (acc, val) => ({
            ...acc,
            ...val
          }),
          {}
        )
    }
  }))
);

const fetchAllEvents = compose(
  createAPIStatusReducer('eventApiStatus', 'Failed to load events'),
  onSuccess((state, action) => ({
    ...state,
    events: action.payload.events
      .map(event => ({
        [`${event.id}`]: event
      }))
      .reduce(
        (acc, val) => ({
          ...acc,
          ...val
        }),
        {}
      )
  }))
);

const fetchEventConfigs = compose(
  createAPIStatusReducer('eventConfigStatus', 'Failed to load event configs'),
  onSuccess((state, { payload }) => ({
    ...state,
    eventConfigs: payload
  }))
);

const fetchEventConfigsPrefill = compose(
  createAPIStatusReducer(
    'eventConfigPrefillStatus',
    'Failed to load event configs prefill'
  ),
  onSuccess((state, { payload }) => ({
    ...state,
    options: {
      ...state.options,
      ...payload
    }
  }))
);

const fetchMissionEventConfigsPrefill = compose(
  createAPIStatusReducer(
    'missionEventConfigPrefillStatus',
    'Failed to load mission event configs prefill'
  ),
  onSuccess((state, { payload }) => ({
    ...state,
    options: {
      ...state.options,
      missionSegments: payload.availableSegments.sort((s1, s2) =>
        s1.name > s2.name ? 1 : s1.name < s2.name ? -1 : 0
      )
    }
  }))
);
