import React, { ChangeEvent, FC, useState } from 'react';
import { withStyles, WithStyles, createStyles, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

const styles = createStyles({
  newItemInput: {
    width: '95%'
  }
})

interface CustomStringListProps extends WithStyles<typeof styles> {
  items: string[];
  placeholder?: string;
  onItemsChange: (items: string[]) => void;
}

interface CustomStringItem {
  item: string;
  onRemove: (item: string) => void;
}

const CustomStringItem: FC<CustomStringItem> = ({ item, onRemove }) => {
  const handleRemoveItem = () => {
    onRemove(item);
  }

  return (
    <ListItem>
      <ListItemText>{item}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton onClick={handleRemoveItem}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const CustomStringList: FC<CustomStringListProps> = ({ classes, items, placeholder, onItemsChange }) => {
  const [newItem, setNewItem] = useState('');
  const newItemAlreadyAdded = items.includes(newItem);

  if (new Set(items).size !== items.length) {
    console.error('CustomStringList only supports unique string values!');
  }

  const handleNewItemChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewItem(event.target.value);
  }

  const handleAddItem = () => {
    const newItems = items.concat(newItem);
    onItemsChange(newItems);
    setNewItem('');
  }

  const handleRemoveItem = (item: string) => {
    const filteredList = items.filter(i => i !== item);
    onItemsChange(filteredList);
  }

  return (
    <List>
      {items.map((item) => <CustomStringItem key={item} item={item} onRemove={handleRemoveItem} />)}
      <ListItem>
        <TextField
          className={classes.newItemInput}
          value={newItem}
          onChange={handleNewItemChange}
          error={newItemAlreadyAdded}
          helperText={newItemAlreadyAdded && 'Value already added'}
          placeholder={placeholder}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={handleAddItem} disabled={newItem === '' || newItemAlreadyAdded}>
            <Add />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

export default withStyles(styles)(CustomStringList);