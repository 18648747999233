import React from 'react';
import RefreshIndicator from 'material-ui/RefreshIndicator';

export default ({ style, size = 35 }) => {
  return (
    <div className='row center-xs' style={style}>
      <div className='col-xs-1'>
        <RefreshIndicator
          size={size}
          left={0}
          top={-2}
          status='loading'
          style={{ display: 'inline-block', position: 'relative' }}
        />
      </div>
    </div>
  );
};
