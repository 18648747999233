import * as actionTypes from './actionTypes';
import { API } from '~/api';
import Q from 'q';
import * as R from 'ramda';

export const createAssetBundleConfiguration = (gameId, bundleName) => {
  return {
    type: actionTypes.CREATE_ASSET_BUNDEL_CONFIGURATION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/create/assetbundleconfiguration/${bundleName}`,
      method: 'post'
    }
  };
};

export const cloneLatestAssetBundleConfiguration = (
  gameId,
  bundleConfiguration
) => {
  const { linkedId } = bundleConfiguration;
  return {
    type: actionTypes.CLONE_LATEST_ASSET_BUNDLE_CONFIGURATION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/clone/assetbundleconfiguration/${linkedId}`,
      method: 'post'
    }
  };
};

export const updateAssetBundleConfiguration = (
  gameId,
  updatedBundleConfigurationData
) => {
  return {
    type: actionTypes.UPDATE_ASSET_BUNDLE_CONFIGURATION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/update_latest/assetbundleconfiguration/${updatedBundleConfigurationData.linkedId}`,
      method: 'post',
      body: updatedBundleConfigurationData
    },
    meta: { previouseLatestId: updatedBundleConfigurationData.id }
  };
};

export const deleteLatestAssetBundleConfiguration = (
  gameId,
  assetBundleConfiguration
) => {
  const { linkedId } = assetBundleConfiguration;
  return {
    type: actionTypes.DELETE_LATEST_ASSET_BUNDLE_CONFIGURATION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/delete_latest/assetbundleconfiguration/${linkedId}`,
      method: 'post'
    },
    meta: { assetBundleConfiguration }
  };
};

export const purgeLatestUnpublishedAssetBundleConfiguration = (
  gameId,
  assetBundleConfiguration
) => {
  const { linkedId } = assetBundleConfiguration;
  return {
    type: actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_CONFIGURATION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/purge_latest_unpublished/assetbundleconfiguration/${linkedId}`,
      method: 'post'
    },
    meta: { assetBundleConfiguration }
  };
};
