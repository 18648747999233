import * as actionTypes from './actionTypes';
import { API } from '~/api';

export const purgeLatestUnpublishedAssetBundleRule = (gameId, assetBundleRule) => {
  const { linkedId } = assetBundleRule;
  return {
    type: actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_RULE,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/purge_latest_unpublished/assetbundlerule/${linkedId}`,
      method: 'post'
    },
    meta: { assetBundleRule }
  };
};

export const createNewAssetBundleRule = (gameId, assetBundleName) => ({
  type: actionTypes.CREATE_NEW_ASSET_BUNDLE_RULE,
  [API]: {
    url: `/content_delivery_framework_v2/${gameId}/assets/create/assetbundlerule/${assetBundleName}`,
    method: 'post'
  }
});

export const cloneAssetBundleRule = (gameId, assetBundleConfigurationLinkedId) => ({
  type: actionTypes.CLONE_ASSET_BUNDLE_RULE,
  [API]: {
    url: `/content_delivery_framework_v2/${gameId}/assets/clone/assetbundlerule/${assetBundleConfigurationLinkedId}`,
    method: 'post'
  }
});

export const deleteAssetBundleRule = (gameId, assetBundleRule) => ({
  type: actionTypes.DELETE_LATEST_ASSET_BUNDLE_RULE,
  [API]: {
    url: `/content_delivery_framework_v2/${gameId}/assets/delete_latest/assetbundlerule/${assetBundleRule.linkedId}`,
    method: 'post'
  },
  meta: {
    assetBundleRule
  }
});

export const updateAssetBundleRule = (gameId, assetBundleRule) => ({
  type: actionTypes.UPDATE_ASSET_BUNDLE_RULE,
  [API]: {
    url: `/content_delivery_framework_v2/${gameId}/assets/update_latest/assetbundlerule/${assetBundleRule.linkedId}`,
    method: 'post',
    body: assetBundleRule
  },
  meta: {
    previouseLatestId: assetBundleRule.id
  }
});

export const updateAssetBundleRulePriority = (gameId, assetBundleRule) => ({
  type: actionTypes.UPDATE_ASSET_BUNDLE_RULE_PRIORITY,
  [API]: {
    url: `/content_delivery_framework_v2/${gameId}/assets/setpriority_latest/assetbundlerule/${assetBundleRule.linkedId}`,
    method: 'post',
    query: { priority: assetBundleRule.priority }
  }
});
