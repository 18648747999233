export const NAME = 'players';
export const GAME_ID = 'phoenix';
export const inventoryPaths = {
  FIRST_INSTALL: [
    'playerClientInventory',
    'statsInventory',
    'firstInstallDate'
  ],
  PLAYER_ID: ['userProfile', 'playerId'],
  TRACKING_ID: ['userProfile', 'trackingId'],
  NICKNAME: ['userProfile', 'leaderboardNickname'],
  IS_TESTER: ['userProfile', 'tester'],
  COPPA_BIRTH_YEAR: [
    'playerClientInventory',
    'userPreferencesInventory',
    'coppaBirthYear'
  ],
  PROVIDERS: ['userProfile', 'providers'],
  PREVIOUS_NAMES: ['userProfile', 'previousNames'],
  SERIOUSLY_ID: ['playerIdMappings', 'seriouslyId'],
  SUPPORT_ID: ['playerIdMappings', 'supportIdString'],
  LATEST_CLIENT_VERSION: ['userProfile', 'latestClientVersion'],
  INITIAL_CLIENT_VERSION: ['userProfile', 'initialClientVersion'],
  CURRENT_PLATFORM: ['userProfile', 'platform'],
  USED_PLATFORMS: ['userProfile', 'platforms'],
  USER_COUNTRY: ['userProfile', 'country'],
  SESSION_COUNTRY: ['sessionData', 'country'],
  FB_TOKEN_FOR_BUSINESS: ['userProfile', 'fbTokenForBusiness'],
  GAME_LANG: ['playerClientInventory', 'userPreferencesInventory', 'language'],
  LAST_LOGIN: ['userProfile', 'lastSessionOpenTime'],
  PIGGY_BANK_CREATION_TIME: [
    'playerClientInventory',
    'shopInventory',
    'piggyBankCreationTime'
  ],
  LAST_PURCHASE_TIME: [
    'playerClientInventory',
    'shopInventory',
    'lastPurchaseTime'
  ],
  LAST_SEEN_TEAM_CHEST_END_DATE: [
    'playerClientInventory',
    'teamsInventory',
    'lastSeenTeamChestPopupEndDate'
  ],
  TEAMS_INVENTORY: ['playerClientInventory', 'teamsInventory'],
  TOTAL_REVENUE: ['playerClientInventory', 'shopInventory', 'totalRevenue'],
  CURRENT_LEVEL: ['playerClientInventory', 'userInventory', 'currentLevel'],
  TOTAL_LEVELS_WON: [
    'playerClientInventory',
    'statsInventory',
    'totalLevelWins'
  ],
  CURRENT_END_GAME_LEVEL: [
    'playerClientInventory',
    'userInventory',
    'endGameLevel'
  ],
  CURRENT_STAR_CHEST_INDEX: [
    'playerClientInventory',
    'userInventory',
    'currentStarChestIndex'
  ],
  STAR_PASS_INVENTORY: ['playerClientInventory', 'seasonPassInventory'],
  SPIN_WHEEL_INVENTORY: ['playerClientInventory', 'spinWheelInventory'],
  UPDATE_TOKEN: ['playerData', 'playerClientInventoryUpdateToken'],
  TEAM_ID: ['playerTeamData', 'currentTeamId'],
  TEAM_NAME: ['playerTeamData', 'teamName'],
  CURRENT_CRAFTING_EVENT_ID: [
    'playerClientInventory',
    'craftingInventory',
    'lastSeenCraftingEventId'
  ],
  CURRENT_CRAFTING_EVENT_VARIANT: [
    'playerClientInventory',
    'craftingInventory',
    'craftingVariantName'
  ],
  CRAFTING_INGREDIENTS: [
    'playerClientInventory',
    'craftingInventory',
    'ingredients'
  ],
  CRAFTING_RESULTS: ['playerClientInventory', 'craftingInventory', 'results'],
  CLAIMED_CRAFTING_CATEGORIES: [
    'playerClientInventory',
    'craftingInventory',
    'ClaimedCategoryIds'
  ],
  CRAFTING_WIN_STREAK: ['playerClientInventory', 'craftingWinStreakInventory'],
  MIGRATION_DATA: ['playerMigration'],
  MIGRATION_APPLIED: ['playerMigration', 'applied']
};
