import * as actionTypes from './actionTypes';
import R from 'ramda';

import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';

const initialState = {
  fetchSettingsStatus: null,
  setMaintenanceModeStatus: null,
  setRequiredClientVersionStatus: null,
  gameServerCacheFlushStatus: null,
  invalidateSessionsStatus: null,
  setTracingStatus: null,
  setRedisInfraMonitoringStatus: null,
  resetAllEndGameLeaderboardLevelsStatus: null,
  requiredClientVersion: undefined,
  maintenanceModeActive: undefined,
  traceEnabled: undefined,
  redisInfraMonitoringEnabled: undefined,
  preventCheatersFromAccessingSocialFeatures: undefined,
  tournamentEventsUnlockLevel: null,
  chatServerCacheFlushStatus: null,
  closeAllClientConnectionsStatus: null,
  fetchChatServerSettingsStatus: null,
  fetchChatServerSettingsResult: null,
  enableChatServerMaintenanceModeStatus: null,
  disableChatServerMaintenanceModeStatus: null,
  configs: {},
  configsStatus: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CONFIGS:
      return reduceGetConfigs(state, action);
    case actionTypes.GET_CONFIG:
    case actionTypes.POST_CONFIG:
    case actionTypes.PUT_CONFIG:
      return reduceGetConfig(state, action);
    case actionTypes.DELETE_CONFIG:
      return reduceDeleteConfig(state, action);
    case actionTypes.FETCH_SETTINGS:
      return reduceFetchSettings(state, action);
    case actionTypes.SET_MAINTENANCE_MODE:
      return reduceSetMaintenanceMode(state, action);
    case actionTypes.SET_REQUIRED_CLIENT_VERSION:
      return reduceSetRequiredClientVersion(state, action);
    case actionTypes.FLUSH_GAME_SERVER_CACHE:
      return flushGameServerCache(state, action);
    case actionTypes.INVALIDATE_SESSIONS:
      return reduceInvalidateSessions(state, action);
    case actionTypes.SET_TRACING:
      return reduceSetTracing(state, action);
    case actionTypes.SET_REDIS_MONITORING:
      return reduceSetRedisMonitoring(state, action);
    case actionTypes.RESET_ALL_ENDGAME_LEADERBOARD_LEVELS:
      return resetAllEndGameLeaderboardLevels(state, action);
    case actionTypes.SET_PREVENT_CHEATERS_FROM_JOINING_SOCIAL_FEATURES:
      return setPreventCheatersFromJoiningSocialFeatures(state, action);
    case actionTypes.CLOSE_ALL_CLIENT_CONNECTIONS:
      return closeAllClientConnections(state, action);
    case actionTypes.FLUSH_CHAT_SERVER_CACHE:
      return flushChatServerCache(state, action);
    case actionTypes.FETCH_CHAT_SERVER_SETTINGS:
      return fetchChatServerSettings(state, action);
    case actionTypes.ENABLE_CHAT_SERVER_MAINTENANCE_MODE:
      return enableChatServerMaintenanceMode(state, action);
    case actionTypes.DISABLE_CHAT_SERVER_MAINTENANCE_MODE:
      return disableChatServerMaintenanceMode(state, action);
    case actionTypes.SET_TOURNAMENT_EVENTS_UNLOCK_LEVEL:
      return setTournamentEventsUnlockLevel(state, action);
    default:
      return state;
  }
};

const _copySettingsFromResponse = (state, payload) => {
  return R.merge(
    R.clone(state),
    R.pick(
      [
        'requiredClientVersion',
        'maintenanceModeActive',
        'traceEnabled',
        'redisInfraMonitoringEnabled',
        'preventCheatersFromAccessingSocialFeatures',
        'tournamentEventsUnlockLevel'
      ],
      payload || {}
    )
  );
};

const flushGameServerCache = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      gameServerCacheFlushStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      gameServerCacheFlushStatus: 'failed'
    };
  } else {
    return {
      ...state,
      gameServerCacheFlushStatus: null
    };
  }
};

const resetAllEndGameLeaderboardLevels = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      resetAllEndGameLeaderboardLevelsStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      resetAllEndGameLeaderboardLevelsStatus: 'error'
    };
  } else {
    return {
      ...state,
      resetAllEndGameLeaderboardLevelsStatus: null
    };
  }
};

const reduceFetchSettings = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      fetchSettingsStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      fetchSettingsStatus: 'failed'
    };
  } else {
    return {
      ..._copySettingsFromResponse(state, action.payload),
      fetchSettingsStatus: null
    };
  }
};

const reduceSetMaintenanceMode = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      setMaintenanceModeStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      setMaintenanceModeStatus: 'failed'
    };
  } else {
    return {
      ..._copySettingsFromResponse(state, action.payload),
      setMaintenanceModeStatus: null
    };
  }
};

const reduceSetRequiredClientVersion = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      setRequiredClientVersionStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      setRequiredClientVersionStatus: 'failed'
    };
  } else {
    return {
      ..._copySettingsFromResponse(state, action.payload),
      setRequiredClientVersionStatus: null
    };
  }
};

const reduceInvalidateSessions = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      invalidateSessionsStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      invalidateSessionsStatus: 'failed'
    };
  } else {
    return {
      ...state,
      invalidateSessionsStatus: null
    };
  }
};

const reduceSetTracing = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      setTracingStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      setTracingStatus: 'failed'
    };
  } else {
    return {
      ..._copySettingsFromResponse(state, action.payload),
      setTracingStatus: null
    };
  }
};

const reduceSetRedisMonitoring = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      setRedisInfraMonitoringStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      setRedisInfraMonitoringStatus: 'failed'
    };
  } else {
    return {
      ..._copySettingsFromResponse(state, action.payload),
      setRedisInfraMonitoringStatus: null
    };
  }
};

const setTournamentEventsUnlockLevel = compose(
  createAPIStatusReducer(
    'setTournamentEventsUnlockLevelStatus',
    'Failed to modify tournament events unlock level status'
  ),
  onSuccess((state, action) => _copySettingsFromResponse(state, action.payload))
);

const setPreventCheatersFromJoiningSocialFeatures = compose(
  createAPIStatusReducer(
    'preventCheatersFromJoiningSocialFeaturesStatus',
    'Failed to set prevent cheaters from joining social features setting.'
  ),
  onSuccess((state, action) => ({
    ..._copySettingsFromResponse(state, action.payload)
  }))
);

const flushChatServerCache = compose(
  createAPIStatusReducer(
    'flushChatServerCacheStatus',
    'Failed to flush the chat server cache.'
  )
);

const closeAllClientConnections = compose(
  createAPIStatusReducer(
    'closeAllClientConnectionsStatus',
    'Failed to close all client connections.'
  )
);

const fetchChatServerSettings = compose(
  createAPIStatusReducer(
    'fetchChatServerSettingsStatus',
    'Failed to fetch chat server settings.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchChatServerSettingsResult: action.payload
  }))
);

const enableChatServerMaintenanceMode = compose(
  createAPIStatusReducer(
    'enableChatServerMaintenanceModeStatus',
    'Failed to enable chat server maintenance mode.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchChatServerSettingsResult: action.payload
  }))
);

const disableChatServerMaintenanceMode = compose(
  createAPIStatusReducer(
    'disableChatServerMaintenanceModeStatus',
    'Failed to disable chat server maintenance mode.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchChatServerSettingsResult: action.payload
  }))
);

const reduceGetConfigs = function(state, action) {
  if (action.pending) {
    return {
      ...state,
      configsLoading: true
    };
  } else if (action.error) {
    return {
      ...state,
      configsLoading: false
    };
  } else {
    return {
      ...state,
      configsLoading: false,
      configs: {
        ...state.configs,
        ...action.payload.items
          .map(item => ({
            ...(state.configs[item.name] || {}),
            ...item,
            updatedAt: Date.now()
          }))
          .reduce((acc, val) => ({ ...acc, [val.name]: val }), {})
      }
    };
  }
};

const reduceGetConfig = function(state, action) {
  if (action.pending) {
    return {
      ...state,
      configsLoading: true
    };
  } else if (action.error) {
    return {
      ...state,
      configsLoading: false
    };
  } else {
    return {
      ...state,
      configsLoading: false,
      configs: {
        ...state.configs,
        [action.payload.name]: {
          ...action.payload,
          updatedAt: Date.now()
        }
      }
    };
  }
};

const reduceDeleteConfig = function(state, action) {
  if (action.pending) {
    return {
      ...state,
      configsLoading: true
    };
  } else if (action.error) {
    return {
      ...state,
      configsLoading: false
    };
  } else {
    const { [action.payload.name]: _, ...configs } = state.configs;
    return {
      ...state,
      configsLoading: false,
      configs
    };
  }
};
