import React, { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * Copied from: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
const useOutsideAlerter = <T extends Node>(ref: React.RefObject<T>, callback: (...args: any[]) => any) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export default useOutsideAlerter;
