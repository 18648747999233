// REVIEW (Johannes)
// @ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import * as R from 'ramda';

const styles = {
  closeButton: {
    float: 'left'
  }
};

class AlertButton extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    alertTitle: PropTypes.string.isRequired,
    onAccept: PropTypes.func,
    alertMessage: PropTypes.node,
    alertAcceptLabel: PropTypes.string,
    alertCancelLabel: PropTypes.string,

    // If enabled, the use is required to type in a confirmation string before being allowed to accept the action.
    typeToAccept: PropTypes.bool,
    typeToAcceptString: PropTypes.string,

    buttonComponent: PropTypes.func,

    // Button props
    disabled: PropTypes.bool,
    color: PropTypes.string,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool
  };

  static defaultProps = {
    alertAcceptLabel: 'Ok',
    alertCancelLabel: 'Cancel'
  };

  state = {
    dialogOpen: false,
    typed: ''
  };

  close = () => {
    this.setState({ dialogOpen: false });
  };

  open = () => {
    this.setState({ dialogOpen: true, typed: '' });
  };

  acceptAndClose = event => {
    const { onAccept } = this.props;

    if (!R.isNil(onAccept)) {
      onAccept(event);
    }

    this.close();
  };

  handleTypedChange = e => {
    const typed = e.target.value;

    this.setState({ typed });
  };

  render() {
    const {
      classes,
      disabled,
      label,
      alertTitle,
      alertMessage,
      alertAcceptLabel,
      alertCancelLabel,
      typeToAccept,
      typeToAcceptString,
      // Disabled because we do not want pass onAccept to the ButtonComponent
      // eslint-disable-next-line no-unused-vars
      onAccept,
      style,
      buttonComponent,
      ...other
    } = this.props;
    const ButtonComponent = buttonComponent || Button;

    return (
      <span style={style}>
        <ButtonComponent onClick={this.open} disabled={disabled} {...other}>
          {label}
        </ButtonComponent>
        <Dialog open={this.state.dialogOpen} onClose={this.close}>
          <DialogTitle>{alertTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{alertMessage}</DialogContentText>
            <br />
            {typeToAccept && (
              <TextField
                value={this.state.typed}
                onChange={this.handleTypedChange}
                helperText={`Please type "${typeToAcceptString}" to continue with action`}
                fullWidth
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.closeButton}
              variant='contained'
              color='secondary'
              onClick={this.close}
            >
              {alertCancelLabel}
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.acceptAndClose}
              disabled={typeToAccept && this.state.typed !== typeToAcceptString}
            >
              {alertAcceptLabel}
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

export default withStyles(styles)(AlertButton);
