import React, { Component } from 'react';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
import PropTypes from 'prop-types';
import R from 'ramda';

class ListTable extends Component {
  static propTypes = {
    entities: PropTypes.array.isRequired,
    /**
         *  Array of properties to render for each row.
         *  Example:
         *  [
         *      {
                    key: 'isRedeemed', // The key to render (if no selector defined)
                    title: 'Redeemed At', // Table header title
                    format: (value, entity) => (value ? supportCode.redeemedAt && dayjs.utc(supportCode.redeemedAt).format('YYYY-MM-DD HH:mm') || 'Unknown date' 
                                                            : 'Not redeemed'), // Formats a value before it is rendered
                    style: value => ({
                            backgroundColor: (value ? Colors.lightGreen500 : Colors.red500),
                            textAlign: 'center'
                        }) // Defines a style for a row cell depending on the value.
                    selector: entity => entity['isRedeemed2'] // Allows for custom selection logic.
                    width: '40%' // Column will take up 40% of the width of the table
                    multiline: true // Cell text will wrap instead of using elipses (...)
                    align: 'right'
                }
         *  ]
         */
    schema: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        title: PropTypes.string.isRequired,
        format: PropTypes.func,
        style: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
        selector: PropTypes.func,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        multiline: PropTypes.bool,
        ignoreClick: PropTypes.bool,
        align: PropTypes.string
      })
    ).isRequired,
    onRowClicked: PropTypes.func,
    rowStyle: PropTypes.object,

    // Path to unique property of entity to use in selection. This allows for selections to be maintained even between object mutations.
    selectionId: PropTypes.array,

    onSelectedChange: PropTypes.func,
    selected: PropTypes.array,
    multiSelectable: PropTypes.bool,

    title: PropTypes.string
  };

  handleRowCellClick = (row, col) => {
    const {
      onRowClicked,
      entities,
      selected,
      selectionId,
      onSelectedChange,
      schema
    } = this.props;

    if (col >= 0 && col <= schema.length) {
      const schemaItem = schema[col];
      if (schemaItem.ignoreClick === true) return;
    }

    const entity = entities[row];
    if (!R.isNil(onRowClicked)) {
      onRowClicked(entity, row, col);
    }
    if (!R.isNil(selected)) {
      if (R.isNil(onSelectedChange)) {
        throw new Error(
          '[ListTable] Prop onSelectedChange cannot be undefined if selected is defined.'
        );
      }
      if (R.isNil(selectionId)) {
        throw new Error(
          '[ListTable] Prop selectionId cannot be undefined if selected is defined.'
        );
      }
      const selectionIdValue = R.path(selectionId, entity);
      const index = selected.indexOf(selectionIdValue);
      if (index === -1) {
        onSelectedChange([...selected, selectionIdValue]);
      } else {
        onSelectedChange(R.remove(index, 1, selected));
      }
    }
  };

  isSelected = entity => {
    const { selected, selectionId } = this.props;

    if (!R.isNil(selected)) {
      if (R.isNil(selectionId)) {
        throw new Error(
          '[ListTable] Prop selectionId cannot be undefined if selected is defined.'
        );
      }
      const selectionIdValue = R.path(selectionId, entity);
      return selected.indexOf(selectionIdValue) >= 0;
    } else {
      return false;
    }
  };

  renderRow = (entity, index) => {
    return (
      <TableRow key={index} hoverable={true} selected={this.isSelected(entity)}>
        {this.props.schema.map((col, i) => {
          let value;
          if (!R.isNil(col.selector)) {
            value = col.selector(entity, index);
          } else {
            if (Array.isArray(col.key)) {
              value = R.path(col.key, entity);
            } else {
              value = entity[col.key];
            }
          }
          const formatedValue =
            (col.format && col.format(value, entity)) || value;
          let style =
            typeof col.style === 'function'
              ? col.style(value, entity)
              : col.style;
          if (col.multiline === true) {
            style = R.merge(
              {
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              },
              style || {}
            );
          }
          if (col.align === 'right') {
            style = R.merge(
              {
                textAlign: 'right'
              },
              style || {}
            );
          }
          return (
            <TableRowColumn
              key={i}
              width={col.width}
              style={style}
              className={'listTableRow'}
            >
              {' '}
              {formatedValue}{' '}
            </TableRowColumn>
          );
        })}
      </TableRow>
    );
  };

  render() {
    try {
      const { entities, schema, selected, multiSelectable, title } = this.props;
      const useCheckboxes = !R.isNil(selected);
      return (
        <React.Fragment>
          <div style={{ textAlign: 'center' }}>{title}</div>
          <Table
            onCellClick={this.handleRowCellClick}
            multiSelectable={useCheckboxes === true && multiSelectable}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={useCheckboxes === true}
            >
              <TableRow>
                {schema.map((col, i) => {
                  let style = {};
                  if (col.align === 'right') {
                    style = R.merge(
                      {
                        textAlign: 'right'
                      },
                      style || {}
                    );
                  }
                  return (
                    <TableHeaderColumn key={i} width={col.width} style={style}>
                      {' '}
                      {col.title || col.key}{' '}
                    </TableHeaderColumn>
                  );
                })}
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={useCheckboxes === true}
              showRowHover={true}
              className={this.props.rowStyle}
              deselectOnClickaway={false}
            >
              {entities && entities.map(this.renderRow)}
            </TableBody>
          </Table>
        </React.Fragment>
      );
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}

export default ListTable;
