import * as actionTypes from './actionTypes';
import * as R from 'ramda';
import * as utils from './utils';
import * as selectors from './selectors';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  createFullStateResponseReducer,
  sortDiffEntitySet,
  sortPublishHistoryEntries
} from './reducerUtils';

import assetBundleConfigurationsModule from './assetBundleConfigurations';
import assetBundleDefinitionsModule from './assetBundleDefinitions';
import assetBundleOptionsModule from './assetBundleOptions';
import assetBundleRulesModule from './assetBundleRules';
import userGroupTagsModule from './userGroupTags';
import databaseLinksModule from './databaseLinks';
import testUsersModule from './testUsers';
import buildInformations from './buildInformations';

const emptyEntitySet = {
  assetBundleConfigurations: [],
  assetBundleDefinitions: [],
  assetBundleOptions: [],
  assetBundleRules: [],
  buildInformation: [],
  databaseLinks: [],
  testUsers: [],
  userGroupTags: []
};

const initialState = {
  // Used by UI blocking API requests (see isUIBlockingAPIAction)
  pendingRequests: [],
  snackbars: [],

  latest: emptyEntitySet,

  nonPublishedEntities: {
    ...emptyEntitySet
  },
  publishHistoryEntries: [],
  publishHistoryPageCount: 0,
  nonPublishedEntitiesCount: 0,
  latestPublishHistoryFetchPage: -1,

  simulatedRun: null,

  // Environment Promotion
  exportedDumpUrl: null
};

export default compose(
  assetBundleConfigurationsModule.reducer,
  assetBundleDefinitionsModule.reducer,
  assetBundleOptionsModule.reducer,
  assetBundleRulesModule.reducer,
  userGroupTagsModule.reducer,
  databaseLinksModule.reducer,
  testUsersModule.reducer,
  buildInformations.reducer,
  rootReducer
);

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLOSE_SNACKBAR:
      return closeSnackbar(state, action);

    // State
    case actionTypes.FETCH_FULL_STATE:
      return fetchFullState(state, action);
    case actionTypes.FETCH_UNPUBLISHED_CHANGES:
      return fetchUnpublishedChanges(state, action);
    case actionTypes.FETCH_PUBLISH_HISTORY:
      return fetchPublishHistory(state, action);
    case actionTypes.CLEAR_PUBLISH_HISTORY:
      return clearPublishHistory(state, action);

    // Simulated run
    case actionTypes.GET_SIMULATED_RUN:
      return getSimulatedRun(state, action);
    case actionTypes.CLEAR_SIMULATED_RUN:
      return { ...state, simulatedRun: null };

    // Publish
    case actionTypes.PUBLISH_CHANGES:
      return publishChanges(state, action);
    case actionTypes.DELETE_ALL_CHANGES:
      return deleteAllChanges(state, action);

    // Batch tools - START
    case actionTypes.BATCH_LOCK_ALWAYS_LATEST_BUNDLE_VERSION:
      return reduceBatchLockAlwaysLatestBundleVersion(state, action);
    case actionTypes.BATCH_ADD_CLIENT_VERSIONS_IN_CONFIGURATIONS:
      return batchAddClientVersionsInConfigurations(state, action);
    case actionTypes.BATCH_ADD_DEFAULT_CONFIGURATIONS_AND_RULES:
      return batchAddDefaultConfigurationsAndRules(state, action);

    default:
      return state;
  }
}

const closeSnackbar = (state, action) => ({
  ...state,
  snackbars: R.reject(
    R.propEq('id', action.payload.snackbarId),
    state.snackbars
  )
});

const publishChanges = compose(
  createUIBlockingActionReducer('Failed to publish all changes'),
  createFullStateResponseReducer()
);
const deleteAllChanges = compose(
  createUIBlockingActionReducer('Failed to delete all changes'),
  createFullStateResponseReducer(),
  onSuccess((state, action) => {
    let nonPublishedEntities = action.payload.nonPublishedEntities;
    if (action.payload.nonPublishedEntitiesCount === 0)
      nonPublishedEntities = emptyEntitySet;

    return {
      ...state,
      nonPublishedEntities
    };
  })
);

// Batch tools -- START
const reduceBatchLockAlwaysLatestBundleVersion = compose(
  createUIBlockingActionReducer(
    'Failed to batch lock always latest bundle version'
  ),
  createFullStateResponseReducer()
);

const batchAddClientVersionsInConfigurations = compose(
  createUIBlockingActionReducer(
    'Failed to batch add client version to configurations'
  ),
  createFullStateResponseReducer()
);

const batchAddDefaultConfigurationsAndRules = compose(
  createUIBlockingActionReducer(
    'Failed to batch add default configurations and rules'
  ),
  createFullStateResponseReducer()
);

// Batch tools -- END

const getSimulatedRun = compose(
  createUIBlockingActionReducer('Failed to simulate run'),
  onSuccess((state, action) => ({
    ...state,
    simulatedRun: action.payload
  }))
);

const fetchFullState = compose(
  createUIBlockingActionReducer('Failed to fetch state'),
  createFullStateResponseReducer()
);

const fetchUnpublishedChanges = compose(
  createUIBlockingActionReducer('Failed to fetch unpublished changes'),
  onSuccess((state, action) => ({
    ...state,
    nonPublishedEntities: sortDiffEntitySet(action.payload.nonPublishedEntities)
  }))
);

const fetchPublishHistory = compose(
  createUIBlockingActionReducer('Failed to fetch publish history'),
  onSuccess((state, action) => ({
    ...state,
    publishHistoryEntries: sortPublishHistoryEntries(
      action.payload.publishHistoryEntries
    ).map(({ diff, ...rest }) => ({
      ...rest,
      diff: sortDiffEntitySet(diff)
    })),
    publishHistoryPageCount: action.payload.pageCount,
    latestPublishHistoryFetchPage: action.meta.pageIndex
  }))
);

const clearPublishHistory = state => ({
  ...state,
  publishHistoryEntries: [],
  publishHistoryPageCount: 0,
  latestPublishHistoryFetchPage: -1
});
