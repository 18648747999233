import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root';
import { Provider } from 'react-redux';
import { msalInstance } from './authConfig';
import { configureStore } from '~/store';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';

const history = createBrowserHistory();

// React router v4 no longer parses the query string, so we do it manually.
function addLocationQuery(history) {
  history.location = Object.assign(history.location, {
    query: queryString.parse(history.location.search)
  });
}

addLocationQuery(history);

history.listen(() => {
  addLocationQuery(history);
});

const store = configureStore(history);

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Root store={store} history={history} instance={msalInstance} />
    </Provider>,
    document.getElementById('root')
  );
};

render();
