export const NAME = 'playerQuery';

// `id` matches a value in the `InventoryBatchUpdate.BatchAction` enum on the game server.
export const batchUpdateActions = [
  {
    id: 'MARK_AS_CHEATER',
    label: 'Mark as cheater'
  },
  {
    id: 'MARK_AS_SUSPICIOUS',
    label: 'Mark as suspiciouse'
  },
  {
    id: 'BAN_FROM_ONLINE_ACTIVITY',
    label: 'Ban from online activity'
  },
  {
    id: 'MARK_AS_NOT_CHEATER',
    label: 'Mark as not cheater'
  }
];

export const limitTypeToOperator = {
  Boolean: ['=']
};
