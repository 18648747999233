import reducer from './reducer';
import * as constants from './constants';
import * as selectors from './selectors';
import * as actions from './actions';

export default {
  reducer,
  constants,
  selectors,
  actions
};
