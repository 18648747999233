import React, { FC } from 'react';
import { makeStyles, ButtonBase } from '@material-ui/core';
import classNames from 'classnames';

interface FileInputProps {
  width?: number;
  height?: number;
  onFiles: (files: FileList | null) => void;
}

const FileInput: FC<FileInputProps & React.HTMLProps<HTMLInputElement>> = ({
  className,
  children,
  onFiles,
  width = 30,
  height = 30,
  ...rest
}) => {
  const classes = makeStyles({
    root: {
      width,
      height
    },
    label: {
      borderRadius: '6px',
      border: '1px solid #ccc',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '100%',
      height: '100%',
      minWidth: width,
      minHeight: height
    },
    input: {
      display: 'none'
    }
  })();

  return (
    <ButtonBase className={classNames(className, classes.root)}>
      <label className={classes.label}>
        <input {...rest} onChange={event => onFiles(event.target.files)} className={classes.input} type='file' />
        {children}
      </label>
    </ButtonBase>
  );
}

export default FileInput;