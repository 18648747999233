import React, { PureComponent } from 'react';
import {
  basePropTypes,
  getValue,
  getTitle,
  setValue,
  componentMounts,
  assertProps,
  wrap,
  fieldExists,
  getTooltip,
  getTooltipDelay
} from './FieldBase';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';

class SingleBitToggleField extends PureComponent {
  static propTypes = {
    // How many bits can be toggled.
    bitIndex: PropTypes.number,

    ...basePropTypes
  };

  static defaultProps = {
    disabled: false,
    bitCount: 5,
    indexStartsFrom: 0,
    defaultValue: 0
  };

  constructor(props) {
    assertProps(props, SingleBitToggleField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnCheck = () => {
    const { bitIndex } = this.props;
    let value = getValue(this.props);
    // Toggle bit at index
    setValue(this.props, value ^ (1 << bitIndex));
  };

  render() {
    if (this.props.visible === false) return null;
    const { className, disabled, bitIndex } = this.props;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    let value = getValue(this.props);
    value = (value & (1 << bitIndex)) > 0;

    return (
      <div className={className || 'col-xs'}>
        <Tooltip
          title={getTooltip(this.props)}
          enterDelay={getTooltipDelay(this.props)}
        >
          <FormControlLabel
            control={
              <Switch
                checked={value}
                disabled={disabled}
                onChange={this.handleOnCheck}
                color='primary'
              />
            }
            label={getTitle(this.props)}
          />
        </Tooltip>
      </div>
    );
  }
}

export default wrap(SingleBitToggleField);
