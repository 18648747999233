import * as actionTypes from './actionTypes';
import R from 'ramda';
import { NAME } from './constants';
import { NAME as parentName } from '../constants';
import splitReducerByGameId from '../splitReducerByGameId';

const actionPrefix = `${parentName}/${NAME}`;

const initialState = {
  filters: {
    platform: null,
    isStoreBuild: true
  },
  archives: [],
  archivedGraphDataStatus: {},
  archivedGraphData: {},
  comparison: null,
  meta: null,
  getMetaStatus: null,
  listStatus: null,
  createArchiveStatus: null,
  compareArchiveAgainstStatus: null,
  deleteArchvieStatus: null,
  getArchiveStatusStatus: null
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST:
      return list(state, action);
    case actionTypes.GET_ARCHIVED_GRAPH:
      return getArchivedGraph(state, action);
    case actionTypes.GET_META:
      return getMeta(state, action);
    case actionTypes.SET_FILTERS:
      return setFilters(state, action);
    case actionTypes.CREATE_ARCHIVE:
      return createArchive(state, action);
    case actionTypes.DELETE_ARCHIVE:
      return deleteArchvie(state, action);
    case actionTypes.CLEAR_ARCHIVE_ERRORS:
      return cleanArchiveErrors(state, action);
    case actionTypes.COMPARE:
    case actionTypes.GET_COMPARISON:
      return compareArchiveAgainst(state, action);
    case actionTypes.GET_ARCHIVE_STATUS:
      return getArchiveStatus(state, action);
    case actionTypes.CLEAR_ARCHIVE_STATUS:
      return clearArchiveStatus(state, action);
    default:
      return state;
  }
};

export default splitReducerByGameId(actionPrefix, reduce);

const cleanArchiveErrors = (state, action) => ({
  ...state,
  createArchiveStatus: null
});

const deleteArchvie = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      deleteArchvieStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      deleteArchvieStatus: action.payload || 'Failed to delete archive'
    };
  } else {
    return {
      ...state,
      deleteArchvieStatus: null
    };
  }
};

const createArchive = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      createArchiveStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      createArchiveStatus: action.payload || 'Unknown error'
    };
  } else {
    return {
      ...state,
      createArchiveStatus: null,
      lastArchiveStatus: action.payload
    };
  }
};

const compareArchiveAgainst = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      compareArchiveAgainstStatus: 'pending',
      comparison: null
    };
  } else if (action.error) {
    return {
      ...state,
      compareArchiveAgainstStatus: action.payload || 'Unknown error'
    };
  } else {
    return {
      ...state,
      compareArchiveAgainstStatus: null,
      comparison: action.payload
    };
  }
};

const getMeta = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getMetaStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getMetaStatus: 'Failed to get meta data'
    };
  } else {
    const meta = action.payload;
    return {
      ...state,
      getMetaStatus: null,
      meta,
      filters: {
        ...state.filters,
        platform:
          state.filters.platform ||
          (meta.platforms.length > 0 ? meta.platforms[0] : null)
      }
    };
  }
};

const list = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      listStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      listStatus: 'Failed to get list of archives'
    };
  } else {
    return {
      ...state,
      listStatus: null,
      archives: action.payload
    };
  }
};

const getArchivedGraph = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      archivedGraphDataStatus: {
        ...state.archivedGraphDataStatus,
        [action.meta.archiveBuildNumber]: 'pending'
      }
    };
  } else if (action.error) {
    return {
      ...state,
      archivedGraphDataStatus: {
        ...state.archivedGraphDataStatus,
        [action.meta.archiveBuildNumber]: 'Failed to get archived graph data'
      }
    };
  } else {
    return {
      ...state,
      archivedGraphDataStatus: {
        ...state.archivedGraphDataStatus,
        [action.meta.archiveBuildNumber]: null
      },
      archivedGraphData: {
        ...state.archivedGraphData,
        [action.meta.archiveBuildNumber]: action.payload
      }
    };
  }
};

const setFilters = (state, action) => ({
  ...state,
  filters: {
    ...state.filters,
    ...action.payload
  }
});

const getArchiveStatus = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getArchiveStatusStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getArchiveStatusStatus: action.payload || 'Unknown error'
    };
  } else {
    return {
      ...state,
      getArchiveStatusStatus: null,
      lastArchiveStatus: action.payload
    };
  }
};

const clearArchiveStatus = state => ({
  ...state,
  lastArchiveStatus: null,
  getArchiveStatusStatus: null
});
