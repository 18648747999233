import R from 'ramda';

export const isAllSelected = selection => selection.isAllSelected === true;

export const toggleSelectAll = (result, selection) => {
  if (isAllSelected(selection) && allResultsSelected(result, selection)) {
    return {
      ...selection,
      isAllSelected: false,
      selected: {}
    };
  } else {
    return {
      ...selection,
      isAllSelected: true,
      notSelected: {}
    };
  }
};

export const isSelected = (selection, seriouslyId) => {
  if (isAllSelected(selection)) {
    return R.path(['notSelected', seriouslyId], selection) !== true;
  } else {
    return R.path(['selected', seriouslyId], selection) === true;
  }
};

export const toggleSelected = (selection, seriouslyId) => {
  if (isAllSelected(selection)) {
    if (selection.notSelected[seriouslyId]) {
      return R.dissocPath(['notSelected', seriouslyId], selection);
    } else {
      return R.assocPath(['notSelected', seriouslyId], true, selection);
    }
  } else {
    if (selection.selected[seriouslyId]) {
      return R.dissocPath(['selected', seriouslyId], selection);
    } else {
      return R.assocPath(['selected', seriouslyId], true, selection);
    }
  }
};

export const allResultsSelected = (result, selection) =>
  getSelectedCount(result, selection) === result.totalCount;

export const getSelectedCount = (result, selection) =>
  isAllSelected(selection)
    ? result.totalCount - R.keys(selection.notSelected).length
    : R.keys(selection.selected).length;

export const isFilterConjunction = value => R.is(String, value);
export const isFilter = value =>
  !Array.isArray(value) && !R.isNil(value.field) && !R.isNil(value.operator);
