import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import TextFieldDebounce from '../TextFieldDebounce';
import {
  basePropTypes,
  getValue,
  componentMounts,
  setValue,
  getTitle,
  validate,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import Big from 'big.js';

class BigNumberField extends PureComponent {
  static propTypes = {
    // Min value of the field
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Max value of the field
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    // Optional offset for the displayed value. This is used for example in world progress, where value 0 is level 1 in the game.
    offset: PropTypes.number,

    ...basePropTypes
  };

  static defaultProps = {
    offset: 0,
    defaultValue: 0
  };

  constructor(props) {
    assertProps(props, BigNumberField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnChange = value => {
    const { disabled } = this.props;
    if (disabled) return;
    var offset = this.props.offset || 0;
    value = value || '';

    try {
      value = Big(value).minus(offset);
      value = value.toFixed();
    } catch (e) {
      // Noop
    }
    setValue(this.props, value, !R.isNil(this.getError(value)));
  };

  getError = overrideValue => {
    const { offset } = this.props;
    let { min, max } = this.props;
    let value = overrideValue || getValue(this.props);
    var error;
    try {
      if (!R.isNil(min)) min = Big(min);
      if (!R.isNil(max)) max = Big(max);
    } catch (e) {
      error = 'Invalid min or max values.';
    }

    if (!error) {
      try {
        value = Big(value).minus(offset);
        if (!R.isNil(min) && value.lt(min))
          error = `Minimum value is ${min.plus(offset).toFixed()}`;
        if (!R.isNil(max) && value.gt(max))
          error = `Maximum value is ${max.plus(offset).toFixed()}`;
        value = value.toFixed();
      } catch (e) {
        error = 'Invalid number.';
      }
    }

    if (R.isNil(error)) error = validate(this.props, value);

    return error;
  };

  render() {
    if (this.props.visible === false) return null;
    const { offset, disabled, className, field } = this.props;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    let value = getValue(this.props);

    try {
      if (!R.isNil(offset))
        value = Big(value)
          .plus(offset)
          .toFixed();
    } catch (e) {
      // Value might not be valid Big number, so we ignore the error.
    }

    const title = getTitle(this.props);

    let name = field || title;
    if (Array.isArray(name)) {
      name = name.join('');
    }
    const error = !R.isNil(this.getError());

    return (
      <div className={className || 'col-xs'}>
        <TextFieldDebounce
          fullWidth={true}
          type='text'
          disabled={disabled}
          label={title}
          name={name}
          value={value}
          onChange={this.handleOnChange}
          error={error}
          helperText={error ? this.getError() : ''}
          style={null}
        />
      </div>
    );
  }
}

export default wrap(BigNumberField);
