import * as R from 'ramda';
import { getState } from '../selectorUtils';
import { createAPIStatusSelectors } from '~/selectorUtils';
import { flatMapEntitySet } from '../entitySet';

export const getDatabaseLinks = state => getState(state).latest.databaseLinks;
export const getDatabaseLinkByLinkedId = (state, linkedId) =>
  R.find(R.propEq('linkedId', linkedId), getDatabaseLinks(state));

export const getActiveDatabaseLink = state =>
  R.head(getState(state).latest.activeDatabaseLink);

export const getNewestBuildInformationForDatabaseLinks = state =>
  getState(state).newestBuildInformationForDatabaseLinks;

export const getNewestBuildInformationFor = (state, databaseLinks) =>
  databaseLinks.map(
    ({ linkedId }) => getNewestBuildInformationForDatabaseLinks(state)[linkedId]
  );

export const getLinkedIdsFromDiff = state =>
  R.uniq([
    ...flatMapEntitySet(
      getState(state).databaseDiff,
      R.path(['newEntity', 'linkedId'])
    ),
    ...flatMapEntitySet(
      getState(state).databaseDiff,
      R.path(['oldEntity', 'linkedId'])
    )
  ]);

export const getDatabaseDiff = state => getState(state).databaseDiff;

export const getExportedDumpUrl = state => getState(state).exportedDumpUrl;

const [
  isDatabaseExportPending,
  getDatabaseExportError
] = createAPIStatusSelectors(getState, 'databaseExport');
export { isDatabaseExportPending, getDatabaseExportError };

const [
  isDatabaseImportPending,
  getDatabaseImportError
] = createAPIStatusSelectors(getState, 'databaseImport');
export { isDatabaseImportPending, getDatabaseImportError };

const [isDatabaseDiffPending, getDatabaseDiffError] = createAPIStatusSelectors(
  getState,
  'databaseDiffGetStatus'
);
export { isDatabaseDiffPending, getDatabaseDiffError };
