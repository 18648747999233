import React, { FC, ChangeEvent } from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@material-ui/core';
import * as R from 'ramda';
import { C_SHARP_MAX_INT } from '../../constants';

export interface NumberInputProps {
  unit?: React.ReactNode;
  min?: number;
  max?: number;
  onNumberChange?: (value: number) => void;
}

const NumberInput: FC<NumberInputProps & TextFieldProps> = ({
  value,
  unit,
  onNumberChange,
  min = -C_SHARP_MAX_INT,
  max = C_SHARP_MAX_INT,
  InputProps,
  ...rest
}) => {
  const validatedValue = Number.isNaN(value) ? '' : value;
  const unitAdornment = unit ? <InputAdornment position='end'>{unit}</InputAdornment> : null;
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (!event.target.validity.valid || R.isNil(onNumberChange)) {
      return;
    }

    try {
      const value = parseInt(event.target.value);
      const isWithinMin = R.isNil(min) || value >= min;
      const isWithinMax = R.isNil(max) || value <= max;

      if (isWithinMin && isWithinMax) {
        onNumberChange(value);
      } else {
        onNumberChange(NaN);
      }
    } catch (error) {
      console.error('Failed to parse new value:', error);
    }
  };
  
  return (
    <TextField
      {...rest}
      value={validatedValue}
      type='number'
      InputProps={{ endAdornment: unitAdornment,  ...InputProps }}
      onChange={handleChange}
    />
  );
};

export default NumberInput;
