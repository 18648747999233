import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import R from 'ramda';

const styles = {
  suggestionsContainer: {
    maxHeight: 200,
    overflowY: 'scroll'
  },
  popper: {},
  textField: {}
};

class AutoComplete extends Component {
  static propTypes = {
    suggestions: PropTypes.array,

    // Optional
    suggestionLabels: PropTypes.array,

    matchWith: PropTypes.func
  };

  static defaultProps = {
    matchWith: (s, value) => s.startsWith(value)
  };

  constructor(props) {
    super(props);
    console.log(this.props.value);
    this.state = {
      anchorEl: null,
      suggestionMatches: [],
      suggestionMatcheLabels: []
    };
    this.inputRef = React.createRef();
  }

  handleChange = e => {
    this.setState({
      anchorEl: e.target
    });
    this.handleValueChange(e.target.value);
  };

  handleSuggestionChosen = suggestion => () => {
    this.setState({ anchorEl: null });
    this.handleValueChange(suggestion);
  };

  handleValueChange = value => {
    this.recalculateMatches(value, this.props);
    this.props.onChange({
      target: { value }
    });
  };

  recalculateMatches = (value, props) => {
    const suggestionMatches = props.suggestions.filter(s =>
      props.matchWith(s, value)
    );

    this.setState({
      suggestionMatches,
      suggestionMatcheLabels: !R.isNil(props.suggestionLabels)
        ? props.suggestionLabels.filter((_, i) =>
            props.matchWith(props.suggestions[i], value)
          )
        : suggestionMatches
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.suggestions !== nextProps.suggestions ||
      this.props.suggestionLabels !== nextProps.suggestionLabels
    ) {
      this.recalculateMatches(nextProps.value, nextProps);
    }
  }

  handleClose = e => {
    if (e.target !== this.state.anchorEl) {
      this.setState({ anchorEl: null });
    }
  };

  handleOnFocus = e => {
    if (this.state.anchorEl == null) {
      this.setState({
        anchorEl: this.props.suggestions.length > 0 ? e.target : null,
        suggestionMatches: this.props.suggestions,
        suggestionMatcheLabels:
          this.props.suggestionLabels || this.props.suggestions
      });
    }
  };

  render() {
    const {
      suggestions,
      onChange,
      classes,
      suggestionLabels,
      matchWith,
      ...rest
    } = this.props;
    const { anchorEl, suggestionMatches, suggestionMatcheLabels } = this.state;

    return [
      <TextField
        key='textField'
        onFocus={this.handleOnFocus}
        {...rest}
        onChange={this.handleChange}
        classes={{ root: classes.textField }}
      />,
      <Popper
        key='popper'
        open={anchorEl !== null && suggestionMatches.length > 0}
        anchorEl={anchorEl}
        className={classes.popper}
      >
        <Paper
          style={{ width: anchorEl ? anchorEl.clientWidth : null }}
          className={classes.suggestionsContainer}
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <MenuList ref={this.menuListRef}>
              {suggestionMatches.map((suggestion, i) => (
                <MenuItem
                  key={i}
                  onClick={this.handleSuggestionChosen(suggestion)}
                >
                  {suggestionMatcheLabels[i]}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    ];
  }
}

export default withStyles(styles)(AutoComplete);
