import { NAME } from './constants';
import { NAME as parentName} from '../constants';

export const GET_CONFIG = `${parentName}/${NAME}/GET_CONFIG`;

export const GET_PREVIEW = `${parentName}/${NAME}/GET_PREVIEW`; 

export const SAVE_AD = `${parentName}/${NAME}/SAVE_AD`;
export const DELETE_AD = `${parentName}/${NAME}/DELETE_AD`;
export const SAVE_CAMPAIGN = `${parentName}/${NAME}/SAVE_CAMPAIGN`;
export const DELETE_CAMPAIGN = `${parentName}/${NAME}/DELETE_CAMPAIGN`;
export const GET_CAMPAIGN_IMPRESSIONS = `${parentName}/${NAME}/GET_CAMPAIGN_IMPRESSIONS`;

export const GET_ALL_ADS = `${parentName}/${NAME}/GET_ALL_ADS`;
export const GET_ALL_CAMPAIGNS = `${parentName}/${NAME}/GET_ALL_CAMPAIGNS`;
export const UPDATE = `${parentName}/${NAME}/UPDATE`;

export const GET_FILTER_PROPERTIES = `${parentName}/${NAME}/GET_FILTER_PROPERTIES`;