import React, { FC, useState, useCallback, useEffect } from 'react';
import {
  withStyles,
  WithStyles,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListProps,
  Divider,
  Collapse,
} from '@material-ui/core';
import { useLocation, Link } from 'react-router-dom';
import { Location } from 'history';
import classNames from 'classnames';
import VerticalLayout from './VerticalLayout';
import Title from './Title';
import {ExpandLess, ExpandMore} from '@material-ui/icons';

export interface NestedSidebarPath {
  name: string;
  path: string;
}

export interface BaseSidebarItem {
  icon: React.ReactNode;
  name: string;
  title?: string;
}

export interface SidebarItem extends BaseSidebarItem {
  path: string;
}

export interface NestedSidebarItem extends BaseSidebarItem {
  nestedItems: NestedSidebarPath[]
}

const styles = createStyles({
  root: {
    maxWidth: '300px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    minHeight: '85vh',
  },
  list: {
    paddingTop: 0
  },
  link: {
    color: 'black',
    textDecoration: 'none'
  },
  titleItem: {
    height: 50
  },
  titleTextContainer: {
    width: '100%'
  },
  nestedItem: {
    paddingLeft: '3rem',
  }
});

export interface SidebarProps extends WithStyles<typeof styles> {
  items: (SidebarItem | NestedSidebarItem)[];
  sidebarDisabled?: boolean;
}

interface SidebarItemProps extends WithStyles<typeof styles> {
  item: SidebarItem;
  location: Location;
  disabled?: boolean;
}

interface SidebarItemWithChildrenProps extends Omit<SidebarItemProps, 'item'> {
  item: NestedSidebarItem;
}

const SidebarItemWithChildren: FC<SidebarItemWithChildrenProps> = ({
  classes,
  item,
  location,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleIsOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen]);

  let isAnyNestedItemSelected = false;
  const nestedElements = item.nestedItems.map((nestedItem, index) => {
    const isSelected = location.pathname === nestedItem.path;
    if (isSelected) {
      isAnyNestedItemSelected = true; 
    }

    let element = (
      <ListItem key={!disabled ? undefined : index} button className={classes.nestedItem} selected={isSelected}>
        <ListItemText>{nestedItem.name}</ListItemText>
      </ListItem>
    )

    if (!disabled) {
      element = (
        <Link key={index} className={classes.link} to={nestedItem.path}>
          {element}
        </Link>
      )
    }

    return element;
  })

  useEffect(() => setIsOpen(isAnyNestedItemSelected), []);

  return (
    <>
      <ListItem button onClick={handleToggleIsOpen} disabled={disabled}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.name} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {nestedElements}
        </List>
      </Collapse>
    </>
  )
}

const SidebarItem: FC<SidebarItemProps> = ({ classes, item, location, disabled }) => {
  const { icon, name, path } = item;
  const isSelected = location.pathname === path;
  const itemElement = (
    <ListItem selected={isSelected} button disabled={disabled}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={name} />
    </ListItem>
  );

  if (!disabled) {
    return (
      <Link className={classes.link} to={path}>
        {itemElement}   
      </Link>
    )
  }

  return itemElement;
}

const Sidebar: FC<SidebarProps & ListProps> = ({
  className,
  classes,
  items,
  title,
  sidebarDisabled = false,
  ...rest
}) => {
  const location = useLocation();
  const itemElements = items.map((i, k) => {
    const isWithNestedItems = 'nestedItems' in i;
    if (isWithNestedItems) {
      return (
        <SidebarItemWithChildren
          key={k}
          classes={classes}
          item={i as NestedSidebarItem}
          location={location}
          disabled={sidebarDisabled}
        />
      )
    } else {
      return (
        <SidebarItem
          key={k}
          classes={classes}
          item={i as SidebarItem}
          location={location}
          disabled={sidebarDisabled}
        />
      )
    }});
  
  const titleEl = title && (
    <>
      <List className={classes.list} disablePadding>
        <ListItem className={classes.titleItem}>
          <VerticalLayout className={classes.titleTextContainer} alignItems='center' justifyContent='center'>
            <Title>{title}</Title>
          </VerticalLayout>
        </ListItem>
      </List>
      <Divider />
    </>
  )
  return (
    <VerticalLayout className={classNames(className, classes.root)}>
      {titleEl}
      <List {...rest} className={classes.list}>
        {itemElements}
      </List>
    </VerticalLayout>
  );
}

export default withStyles(styles)(Sidebar);