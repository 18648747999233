// @ts-nocheck
import * as actionTypes from './actionTypes';
import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';

const initialState = {
  fetchConfigurationResult: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONFIGURATION:
      return fetchConfiguration(state, action);
    case actionTypes.UPDATE_CONFIGURATION:
      return updateConfiguration(state, action);
    case actionTypes.RE_EVALUATE_INVENTORY_INFRACTIONS:
      return reEvaluateInventoryInfractions(state, action);
    default:
      return state;
  }
};

const fetchConfiguration = compose(
  createAPIStatusReducer(
    'fetchConfigurationStatus',
    'Failed to fetch the configuration.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchConfigurationResult: action.payload
  }))
);

const updateConfiguration = compose(
  createAPIStatusReducer(
    'updateConfigurationStatus',
    'Failed to update the configuration.'
  )
);

const reEvaluateInventoryInfractions = compose(
  createAPIStatusReducer(
    'reEvaluateInventoryInfractionsStatus',
    'Failed to re-evaluate inventory infractions.'
  )
);
