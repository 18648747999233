import { permissions } from '@seriously/common';
import companyLogo from './assets/seriouslyLogo.png';
import starsLogo from './assets/starsLogo.png';

export const api_path = '/api';

export const sidebarItems = [
  {
    name: 'Company',
    icon: companyLogo,
    requiredPermissions: [permissions.COMPANY],
    children: [
      {
        name: 'Analytics KPI',
        path: '/company/analytics-kpi',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Access & Erasure',
        path: '/company/access-and-erasure',
        requiredPermissions: [permissions.ALLOWED_TO_USE_ACCESS_AND_ERASURE]
      },
      {
        name: 'Ads',
        path: '/company/ads/ads',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_ADS]
      }
    ]
  },
  {
    name: 'Best Fiends: Stars',
    requiredPermissions: [permissions.PHOENIX],
    icon: starsLogo,
    children: [
      {
        name: 'Players',
        path: '/phoenix/player',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_PLAYERS]
      },
      {
        name: 'Teams',
        path: '/phoenix/teams',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_TEAMS]
      },
      {
        name: 'Leaderboards',
        path: '/phoenix/leaderboards',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_LEADERBOARDS]
      },
      {
        name: 'Tournaments',
        path: '/phoenix/tournaments',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_TOURNAMENT_GROUPS]
      },
      {
        name: 'Event management',
        path: '/phoenix/eventManagement',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_TOURNAMENT_GROUPS]
      },
      {
        name: 'Content Delivery Framework',
        path: '/phoenix/contentdeliveryframework',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_CDF]
      },
      {
        name: 'Inventory Validator',
        path: '/phoenix/inventoryvalidator',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_INVENTORY_VALIDATOR]
      },
      {
        name: 'Inventory Editor',
        path: '/phoenix/inventories',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_INVENTORY_VALIDATOR]
      },
      {
        name: 'SUSan',
        path: '/phoenix/susan',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Analytics overrides',
        path: '/phoenix/useroverrides',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Server Settings',
        path: '/phoenix/serversettings',
        requiredPermissions: [permissions.ALLOWED_TO_EDIT_PHOENIX_SERVER_SETTINGS]
      },
      {
        name: 'Analytics',
        path: '/phoenix/analytics',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_ANALYTICS]
      },
      {
        name: 'Build Archiver',
        path: '/phoenix/builds',
        requiredPermissions: [permissions.ALLOWED_TO_SEE_BUILD_ARCHIVER]
      }
    ]
  }
];
