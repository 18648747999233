import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import TextFieldDebounce from '../TextFieldDebounce';
import {
  basePropTypes,
  getValue,
  componentMounts,
  setValue,
  getTitle,
  validate,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import { C_SHARP_MAX_INT } from '../../../constants';

class NumberField extends PureComponent {
  static propTypes = {
    // Optional manual id for the field
    id: PropTypes.string,

    // Min value of the field
    min: PropTypes.number,

    // Max value of the field
    max: PropTypes.number,

    // Optional offset for the displayed value. This is used for example in world progress, where value 0 is level 1 in the game.
    offset: PropTypes.number,

    // if set, then thi value can only be set by button, the textfield itself is disabled
    setByButton: PropTypes.bool,
    buttonIcon: PropTypes.element,
    buttonTooltip: PropTypes.string,

    ...basePropTypes
  };

  static defaultProps = {
    offset: 0,
    defaultValue: 0,
    buttonIcon: <Clear/>,
    buttonTooltip: 'Reset to default value'
  };

  constructor(props) {
    assertProps(props, NumberField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnChangeByButton = () => {
    this.handleOnChange('-1'); //just pass -1, it's required that the setter is present if it's set by button
  };

  handleOnChange = value => {
    const { disabled } = this.props;
    if (disabled) return;
    var offset = this.props.offset || 0;
    value = value || '';

    if (value.length !== 0 && !isNaN(value) && !value.endsWith('.')) {
      //don't parse if it currently ends with . this makes it easier to enter decimal numbers since otherwise the '.' gets parsed away within 0.2 seconds (after debounce)
      value = parseFloat(value) - offset;
      if (value > Number.MAX_SAFE_INTEGER) value = getValue(this.props);
    }

    setValue(this.props, value, !R.isNil(this.getError(value)));
  };

  getError = overrideValue => {
    const { offset, min, max } = this.props;
    const value = overrideValue || getValue(this.props);
    var error;
    if (value.length === 0 || isNaN(value)) {
      error = 'Invalid number.';
    } else {
      const isValueNil = R.isNil(min)
      if (!isValueNil && value < min)
        error = `Minimum value is ${min + offset}`;
      if (!isValueNil && value > max)
        error = `Maximum value is ${max + offset}`;
      if (!isValueNil && value > C_SHARP_MAX_INT)
        error = `The value needs to be under ${C_SHARP_MAX_INT}`;
    }

    if (R.isNil(error)) error = validate(this.props, value);

    return error;
  };

  render() {
    if (this.props.visible === false) return null;
    const {
      offset,
      disabled,
      className,
      field,
      id,
      setByButton,
      buttonIcon,
      buttonTooltip
    } = this.props;
    let { InputProps = {} } = this.props;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    let value = getValue(this.props);

    if (!R.isNil(offset) && R.is(Number, value)) value += offset;

    const title = getTitle(this.props);

    let name = field || title || name || id;
    if (Array.isArray(name)) {
      name = name.join('');
    }

    if (setByButton)
      InputProps = {
        ...InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <Tooltip title={buttonTooltip}>
              <IconButton
                disabled={disabled}
                onClick={this.handleOnChangeByButton}
              >
                {buttonIcon}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      };

    const error = !R.isNil(this.getError());

    return (
      <div className={className || 'col-xs'}>
        <TextFieldDebounce
          fullWidth={true}
          type='text'
          disabled={disabled || setByButton}
          label={title}
          name={name}
          value={value}
          onChange={this.handleOnChange}
          error={error}
          helperText={error ? this.getError() : ''}
          tooltip={this.props.tooltip}
          tooltipDelay={this.props.tooltipDelay}
          style={null}
          InputProps={InputProps}
        />
      </div>
    );
  }
}

export default wrap(NumberField);
