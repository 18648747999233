import React from 'react';
import PropTypes from 'prop-types';
import { Typography, createStyles, withStyles } from '@material-ui/core';

const Subtitle = ({ classes, children }) => {
  return (
    <Typography className={classes.root} variant='caption'>
      {children}
    </Typography>
  );
};

const styles = createStyles({
  root: {
    fontSize: '0.7rem',
    margin: '0.5rem 0 0.5rem'
  }
});

Subtitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

export default withStyles(styles)(Subtitle);
