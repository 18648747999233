import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authSelectors from '../../auth/selectors';
import Dialog from 'material-ui/Dialog';

class ServerClientVersionValidator extends Component {
  render() {
    return (
      <div>
        <Dialog
          title='Backend-frontend version mismatch detected!'
          modal={true}
          open={this.props.serverClientVersionMismatch}
          onRequestClose={this.handleClose}
        >
          The server version is "{this.props.serverVersion}" while the frontend version is "{DASHBOARD_VERSION}".
          <br />
          Please refresh the page to continue.
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    serverClientVersionMismatch: authSelectors.hasServerClientVersionMismatchBeenDetected(state),
    serverVersion: authSelectors.getServerVersion(state)
  };
}
export default connect(mapStateToProps)(ServerClientVersionValidator);
