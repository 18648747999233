import React, { Component } from 'react';

class NotFoundPage extends Component {
  render() {
    return (
      <div className='row center-xs pad-vert'>
        <div className='col-xs-4'>
          404
          <h4 className='pad-vert'>PAGE NOT FOUND</h4>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
