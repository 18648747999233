import React, { FC, HTMLProps } from 'react';
import { makeStyles } from '@material-ui/core';
import * as R from 'ramda';
import classNames from 'classnames';

export interface GridLayoutProps {
  columns?: number;
}

const GridLayout: FC<GridLayoutProps & HTMLProps<HTMLDivElement>> = ({ className, children, columns, ...rest }) => {
  const classes = makeStyles({
    layout: {
      display: 'grid',
      gap: '26px'
    },
    withColumns: {
      gridTemplateColumns: `repeat(${columns}, 1fr)`
    },
    withoutColumns: {
      gridAutoColumns: 'auto'
    }
  })();

  return (
    <div
      {...rest}
      className={classNames(classes.layout, R.isNil(columns) ? classes.withoutColumns : classes.withColumns, className)}
    >
      {children}
    </div>
  );
};

export default GridLayout;
