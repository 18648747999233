import React, { FC, HTMLProps, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

export interface HorizontalLayoutProps {
  justifyContent?:
    | 'default'
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'normal';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit' | 'start';
  gap?: number;
  fullWidth?: boolean;
  children: ReactNode | ReactNode[];
}

const HorizontalLayout: FC<HorizontalLayoutProps & HTMLProps<HTMLDivElement>> = ({
  className,
  justifyContent = 'default',
  alignItems = 'stretch',
  gap = 0,
  fullWidth = false,
  children,
  ...rest
}) => {
  const classes = makeStyles({
    layout: {
      display: 'flex',
      flexDirection: 'row',
      alignItems,
      justifyContent,
      gap,
      width: fullWidth ? '100%' : undefined,
    }
  })();

  return (
    <div {...rest} className={classNames(classes.layout, className)}>
      {children}
    </div>
  );
};

export default HorizontalLayout;
