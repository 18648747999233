import * as R from 'ramda';
import { sortByCreated, sortByReversedCreated } from './utils';
import { onSuccess } from '~/reducerUtils';

// Composes a bunch of reducers into one, so that the reducers are called in the order that they appear in the function call.
export const compose = function(/* 1 or more reducer functions */) {
  const reducers = arguments;
  return (state, action) => {
    for (const reducer of reducers) {
      state = reducer(state, action);
    }
    return state;
  };
};

export const incrementNonPublishedEntitiesCount = state => ({
  ...state,
  nonPublishedEntitiesCount: state.nonPublishedEntitiesCount + 1
});

export const createUIBlockingActionReducer = errorMessage => (state, action) => {
  const { transactionId } = action;
  if (action.pending) {
    return {
      ...state,
      pendingRequests: [...state.pendingRequests, transactionId]
    };
  } else if (action.error) {
    const responseError = R.pathOr(null, ['payload', 'error'], action);
    const message = errorMessage + (responseError ? `: ${responseError}` : '');
    return {
      ...state,
      pendingRequests: R.without([transactionId], state.pendingRequests),
      snackbars: [...state.snackbars, { type: 'error', message, createdAt: new Date(), id: transactionId }]
    };
  } else {
    return {
      ...state,
      pendingRequests: R.without([transactionId], state.pendingRequests)
    };
  }
};

/**
    Some of CDF's endpoints return a full state object (same as is returned by FETCH_FULL_STATE).
    To make the reducing of these responses cleaner, we use this helper function.
*/
export const createFullStateResponseReducer = () => (state, action) => {
  if (!action.pending && !action.error) {
    const { latestEntities, newestBuildInformationForDatabaseLinks, nonPublishedEntitiesCount } = action.payload;
    const maxVersionsMap = {};
    const assetBundleDefinitionsByVariant = {};
    for (let i = 0; i < latestEntities.assetBundleDefinitions.length; i++) {
      const def = latestEntities.assetBundleDefinitions[i];
      const { bundleName, platform, versionNumber, variant } = def;
      if (!maxVersionsMap[bundleName]) {
        maxVersionsMap[bundleName] = {};
      }

      if (!maxVersionsMap[bundleName][platform]) {
        maxVersionsMap[bundleName][platform] = {};
      }

      if (!maxVersionsMap[bundleName][platform][variant]) {
        maxVersionsMap[bundleName][platform][variant] = versionNumber;
      } else {
        maxVersionsMap[bundleName][platform][variant] = Math.max(
          versionNumber,
          maxVersionsMap[bundleName][platform][variant]
        );
      }

      if (!assetBundleDefinitionsByVariant[bundleName]) {
        assetBundleDefinitionsByVariant[bundleName] = {
          mostRecent: def,
          platforms: {}
        };
      }
      if (!assetBundleDefinitionsByVariant[bundleName].platforms[platform]) {
        assetBundleDefinitionsByVariant[bundleName].platforms[platform] = {};
      }
      if (!assetBundleDefinitionsByVariant[bundleName].platforms[platform][variant]) {
        assetBundleDefinitionsByVariant[bundleName].platforms[platform][variant] = {};
      }
      assetBundleDefinitionsByVariant[bundleName].platforms[platform][variant][versionNumber] = def;
      if (def.created > assetBundleDefinitionsByVariant[bundleName].mostRecent.created) {
        assetBundleDefinitionsByVariant[bundleName].mostRecent = def;
      }
    }

    // Map bundleInformation objects by linkId for quick access
    const bundleInformationByLinkIdMap = {};
    for (let bundleInformation of latestEntities.buildInformation) {
      bundleInformationByLinkIdMap[bundleInformation.linkedId] = bundleInformation;
    }

    // Map assetBundleConfigurations by linkId for quick access
    const assetBundleConfigurationsByLinkIdMap = {};
    for (let assetBundleConfiguration of latestEntities.assetBundleConfigurations) {
      assetBundleConfigurationsByLinkIdMap[assetBundleConfiguration.linkedId] = assetBundleConfiguration;
    }

    // Map assetBundleRules by bundleName for quick access
    const assetBundleRulesByBundleNameMap = {};
    for (let assetBundleRule of latestEntities.assetBundleRules) {
      if (!assetBundleRulesByBundleNameMap[assetBundleRule.bundleName]) {
        assetBundleRulesByBundleNameMap[assetBundleRule.bundleName] = [assetBundleRule];
      } else {
        assetBundleRulesByBundleNameMap[assetBundleRule.bundleName].push(assetBundleRule);
      }
    }

    const sortedEntities = sortEntitySetByCreated(latestEntities);
    return {
      ...state,
      ...sortedEntities,
      latest: {
        ...sortedEntities,
        assetBundleRules: R.sortBy(R.prop('priority'), latestEntities.assetBundleRules)
      },
      initialStateLoaded: true,
      newestBuildInformationForDatabaseLinks: R.fromPairs(
        newestBuildInformationForDatabaseLinks.map(({ databaseLinkId, newestBuildInformation }) => [
          databaseLinkId,
          newestBuildInformation
        ])
      ),
      nonPublishedEntitiesCount,
      assetBundleConfigurationsByLinkIdMap,
      assetBundleDefinitionsByVariant,
      assetBundleRulesByBundleNameMap,
      bundleInformationByLinkIdMap,
      maxVersionsMap
    };
  } else {
    return state;
  }
};

const sortEntitySetBy = sortFn => entitySet =>
  R.reduce(
    (acc, key) => {
      acc[key] = sortFn(entitySet[key]);
      return acc;
    },
    {},
    R.keys(entitySet)
  );

const sortEntitySetByCreated = sortEntitySetBy(sortByCreated);
export const sortDiffEntitySet = sortEntitySetBy(
  R.compose(
    R.reverse,
    R.sortBy(R.path(['newEntity', 'edited']))
  )
);
export const sortPublishHistoryEntries = R.compose(
  R.reverse,
  R.sortBy(R.path(['publishHistoryEntry', 'edited']))
);
