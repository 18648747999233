import * as R from 'ramda';

export const sortByCreated = R.sortBy(R.prop('created'));
export const sortByReversedCreated = R.compose(
  R.reverse,
  sortByCreated
);

// Client Version
// based on: https://github.com/substack/semver-compare/blob/master/index.js
const compareClientVersions = prop => (left, right) => {
  const a = left[prop];
  const b = right[prop];
  var pa = a.split('.');
  var pb = b.split('.');
  for (var i = 0; i < 3; i++) {
    var na = Number(pa[i]);
    var nb = Number(pb[i]);
    if (na > nb) return 1;
    if (nb > na) return -1;
    if (!isNaN(na) && isNaN(nb)) return 1;
    if (isNaN(na) && !isNaN(nb)) return -1;
  }
  return 0;
};

export function sortSemverDescending(semverList = [], prop) {
  return semverList.sort(compareClientVersions(prop)).reverse();
}
