import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import R from 'ramda';

let styles = {
  container: {
    border: '1px solid grey',
    borderRadius: 5,
    padding: 5,
    boxSizing: 'border-box',
    position: 'relative'
  },
  input: { display: 'none', width: '100%', height: '100%' },
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0
  },
  browseButton: {
    position: 'absolute',
    right: 20,
    bottom: 5
  },
  dropText: {
    paddingTop: 30,
    pointerEvents: 'none'
  },
  children: {
    height: '100%',
    width: '100%',
    overflowY: 'scroll'
  }
};

styles = {
  ...styles,
  overlayHover: R.merge(styles.overlay, {
    textAlign: 'center',
    backgroundColor: '#ccc'
  })
};

class FileSelectArea extends Component {
  static propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      fileHover: false,
      hiddenInput: null
    };
  }

  selectFiles = files => {
    const loadedFiles = [];
    var self = this;
    for (var i = 0; i < files.length; i++) {
      var reader = new FileReader();
      reader.onload = (file =>
        function(e) {
          loadedFiles.push({
            data: e.target.result,
            name: file.name
          });
          if (loadedFiles.length === files.length)
            self.filesLoaded(loadedFiles);
        })(files[i]);
      reader.readAsText(files[i]);
    }
  };

  filesLoaded = files => {
    this.props.onFileSelect(files);

    // We need to clear the input element selection, otherwise the same file cannot be selected twice.
    if (this.inputElement) this.inputElement.value = '';
  };

  handleBrowse = () => {
    this.state.hiddenInput.click();
  };

  hiddenInputRef = element => {
    if (element != null) {
      this.setState({
        hiddenInput: element
      });
      var self = this;
      element.onchange = function() {
        if (self.props.disabled) return;
        self.selectFiles(this.files);
      };
      this.inputElement = element;
    }
  };

  handleDragOver = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (this.props.disabled) return;

    if (!this.state.fileHover) this.setState({ fileHover: true });

    evt.dataTransfer.dropEffect = 'copy';
  };

  handleDragLeave = () => {
    this.setState({ fileHover: false });
  };

  handleDrop = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    if (this.props.disabled) return;
    this.setState({ fileHover: false });
    this.selectFiles(evt.dataTransfer.files);
  };

  render() {
    const { disabled, width, height } = this.props;

    const containerStyle = R.merge(styles.container, { width, height });
    return (
      <div
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        style={containerStyle}
        className={this.props.className}
      >
        <input
          ref={this.hiddenInputRef}
          type='file'
          id='file'
          style={styles.input}
          multiple
        />
        {this.state.fileHover && (
          <div style={styles.overlayHover}>
            <div style={styles.dropText}>Drop files...</div>
          </div>
        )}
        {!this.state.fileHover && (
          <div style={styles.children}>{this.props.children}</div>
        )}
        {!this.state.fileHover && disabled !== true && (
          <RaisedButton
            label='Browse'
            style={styles.browseButton}
            secondary={true}
            onClick={::this.handleBrowse}
          />
        )}
      </div>
    );
  }
}

export default FileSelectArea;
