import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import {
  basePropTypes,
  getValue,
  setValue,
  getTitle,
  componentMounts,
  validate,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import TextFieldDebounce from '../TextFieldDebounce';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Clear from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

class StringField extends PureComponent {
  static propTypes = {
    multiLine: PropTypes.bool, // Should the TextField be multiline
    rowsMax: PropTypes.number, // Max number of rows if multiLine is enabled

    maxLength: PropTypes.number, // Max length of string,

    // if set, then thi value can only be set by button, the textfield itself is disabled
    setByButton: PropTypes.bool,

    ...basePropTypes
  };

  static defaultProps = {
    defaultValue: ''
  };

  constructor(props) {
    assertProps(props, StringField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnChangeByButton = () => {
    this.handleOnChange('');
  };

  handleOnChange = value => {
    const { disabled } = this.props;
    if (disabled) return;
    setValue(this.props, value, !R.isNil(this.getError(value)));
  };

  getError = (value = '') => {
    let error = validate(this.props, value);
    if (R.isNil(error) && !R.isNil(this.props.maxLength)) {
      if (value.length > this.props.maxLength) {
        error = `Cannot be longer than ${this.props.maxLength} characters`;
      }
    }
    return error;
  };

  render() {
    if (this.props.visible === false) return null;
    const {
      disabled,
      className,
      multiLine,
      rowsMax,
      setByButton
    } = this.props;
    let { InputProps = {} } = this.props;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    let value = getValue(this.props);
    if (R.isNil(value)) value = '';
    const error = !R.isNil(this.getError(value));

    if (setByButton)
      InputProps = {
        ...InputProps,
        endAdornment: (
          <InputAdornment position='end'>
            <Tooltip title='Reset to default value'>
              <IconButton
                disabled={disabled}
                onClick={this.handleOnChangeByButton}
              >
                {<Clear />}
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      };

    return (
      <div className={className || 'col-xs'}>
        <TextFieldDebounce
          fullWidth={true}
          type='text'
          disabled={disabled || setByButton}
          label={getTitle(this.props)}
          value={value}
          onChange={this.handleOnChange}
          error={error}
          helperText={error ? this.getError(value) : ''}
          multiline={multiLine}
          tooltip={this.props.tooltip}
          tooltipDelay={this.props.tooltipDelay}
          rowsMax={rowsMax}
          InputProps={InputProps}
        />
      </div>
    );
  }
}

export default wrap(StringField);
