import React, { FC, useState } from 'react';
import {
  Button,
  withStyles,
  WithStyles,
  createStyles,
  LinearProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@material-ui/core';
import { Add, Delete, GetApp, Visibility } from '@material-ui/icons';
import  * as R from 'ramda';
import FileInput from './FileInput';
import Image from './Image';
import GridLayout from './GridLayout';
import VerticalLayout from './VerticalLayout';
import HorizontalLayout from './HorizontalLayout';

export type image = {
  name: string;
  url: string;
};

const styles = createStyles({
  container: {
    width: 128,
    height: 128,
    borderRadius: '6px',
    border: '1px solid #ccc',
    padding: '12px'
  },
  deleteIcon: {
    color: '#fb0000'
  },
  deleteButton: {
    color: 'white',
    backgroundColor: '#ff2929',
    '&:hover': {
      backgroundColor: '#ff5959'
    },
    '&:active': {
      backgroundColor: '#ff7a7a'
    }
  }
});

interface ImageManagerProps extends WithStyles<typeof styles> {
  columns?: number;
  images?: image[];
  isPending?: boolean;
  onUploadImage: (image: File) => void;
  onDeleteImage: (image: image) => void;
}

interface ImageItemProps extends WithStyles<typeof styles> {
  height: number;
  width: number;
  image: image;
  onDelete: (image: image) => void;
  onPreview: (image: image) => void;
}

const ImageItem: FC<ImageItemProps> = ({ classes, height, width, image, onDelete, onPreview }) => {
  const { name, url } = image;
  
  return (
    <VerticalLayout className={classes.container} alignItems='center' justifyContent='space-between' gap={8}>
      <Image src={url} alt={name} height={height} width={width} />
      <HorizontalLayout gap={8}>
        <IconButton color='primary' onClick={() => onPreview(image)}>
          <Visibility />
        </IconButton>
        <a href={url} download={name} target='_blank'>
          <IconButton color='primary'>
            <GetApp />
          </IconButton>
        </a>
        <IconButton className={classes.deleteIcon} onClick={() => onDelete(image)}>
          <Delete />
        </IconButton>
      </HorizontalLayout>
    </VerticalLayout>
  );
};

const ImageManager: FC<ImageManagerProps> = ({
  classes,
  images = [],
  columns = 5,
  isPending = false,
  onUploadImage,
  onDeleteImage
}) => {
  const [imageToDelete, setImageToDelete] = useState<image | null>(null);
  const [previewImage, setPreviewImage] = useState<image | null>(null);

  const handleDeleteImage = () => {
    if (!R.isNil(imageToDelete)) {
      onDeleteImage(imageToDelete);
    }

    setImageToDelete(null);
  }

  const handleUploadImage = (files: FileList | null) => {
    if (!R.isNil(files) && files.length > 0) {
      const file = files[0];
      
      onUploadImage(file);
    }
  }
  
  const handleOpenDeleteConfirmation = (image: image) => {
    setImageToDelete(image);
  }

  const handleCloseDeleteConfirmation = () => {
    setImageToDelete(null);
  }

  const handleOpenPreview = (image: image) => {
    setPreviewImage(image);
  }

  const handleClosePreview = () => {
    setPreviewImage(null);
  }

  const imageItems = images.map((img, i) => (
    <ImageItem
      key={i}
      image={img}
      classes={classes}
      height={64}
      width={64}
      onPreview={handleOpenPreview}
      onDelete={handleOpenDeleteConfirmation}
    />)
  );

  return (
    <VerticalLayout gap={8} alignItems='center'>
      {isPending && <LinearProgress />}
      <GridLayout columns={columns}>
        <FileInput label='Upload image' onFiles={handleUploadImage} height={154} width={154}>
          <VerticalLayout alignItems='center' justifyContent='center'>
            <Add />
            <Typography variant='caption'>Add image</Typography>
          </VerticalLayout>
        </FileInput>
        {imageItems}
      </GridLayout>
      <Dialog open={!R.isNil(imageToDelete)} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle>Delete image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you wish to delete {imageToDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.deleteButton} color='primary' variant='outlined' onClick={handleDeleteImage}>
            Delete
          </Button>
          <Button variant='outlined' onClick={handleCloseDeleteConfirmation}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={!R.isNil(previewImage)} onClose={handleClosePreview} maxWidth='xl'>
        <DialogTitle>{previewImage?.name}</DialogTitle>
        <DialogContent>
          <VerticalLayout justifyContent='center'>
            <Image src={previewImage?.url || ''} alt={previewImage?.name || 'Preview image'} />
          </VerticalLayout>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClosePreview}>Close</Button>
        </DialogActions>
      </Dialog>
    </VerticalLayout>
  );
};

export default withStyles(styles)(ImageManager);
