import * as actionTypes from './actionTypes';
import { compose, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  createFullStateResponseReducer,
  incrementNonPublishedEntitiesCount
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.CREATE_NEW_ASSET_BUNDLE_RULE:
      return createNewAssetBundleRule(state, action);
    case actionTypes.DELETE_LATEST_ASSET_BUNDLE_RULE:
      return deleteAssetBundleRule(state, action);
    case actionTypes.UPDATE_ASSET_BUNDLE_RULE:
      return updateAssetBundleRule(state, action);
    case actionTypes.UPDATE_ASSET_BUNDLE_RULE_PRIORITY:
      return updateAssetBundleRulePriority(state, action);
    case actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_RULE:
      return purgeLatestUnpublishedAssetBundleRule(state, action);
    case actionTypes.CLONE_ASSET_BUNDLE_RULE:
      return cloneAssetBundleRule(state, action);
    default:
      return state;
  }
};

const updateAssetBundleRule = compose(
  createUIBlockingActionReducer('Failed to update asset bundle rule'),
  onSuccess(incrementNonPublishedEntitiesCount),
  onSuccess(assetBundleRuleMergerReducer)
);

const updateAssetBundleRulePriority = compose(
  createUIBlockingActionReducer('Failed to update asset bundle rule priority'),
  onSuccess(incrementNonPublishedEntitiesCount),
  createFullStateResponseReducer()
);

const createNewAssetBundleRule = compose(
  createUIBlockingActionReducer('Failed to create new asset bundle rule'),
  onSuccess(incrementNonPublishedEntitiesCount),
  onSuccess(assetBundleRuleMergerReducer)
);

const cloneAssetBundleRule = compose(
  createUIBlockingActionReducer('Failed to clone asset bundle rule'),
  onSuccess(incrementNonPublishedEntitiesCount),
  onSuccess(assetBundleRuleMergerReducer)
);

const deleteAssetBundleRule = compose(
  createUIBlockingActionReducer('Failed to delete new asset bundle rule'),
  createFullStateResponseReducer()
);

const purgeLatestUnpublishedAssetBundleRule = compose(
  createUIBlockingActionReducer('Failed to delete unpublished asset bundle rule changes'),
  createFullStateResponseReducer()
);

/*** Utils  ***/

function assetBundleRuleMergerReducer(state, action) {
  const assetBundleRulesByBundleNameMap = state.assetBundleRulesByBundleNameMap[action.payload.bundleName];
  let nextAssetBundleRulesByBundleNameMap;
  if (assetBundleRulesByBundleNameMap) {
    const index = assetBundleRulesByBundleNameMap.findIndex(rule => rule.linkedId === action.payload.linkedId);
    nextAssetBundleRulesByBundleNameMap = {
      ...state.assetBundleRulesByBundleNameMap,
      [action.payload.bundleName]: [
        ...assetBundleRulesByBundleNameMap.slice(0, index),
        action.payload,
        ...assetBundleRulesByBundleNameMap.slice(index + 1)
      ]
    };
  } else {
    nextAssetBundleRulesByBundleNameMap = {
      ...state.assetBundleRulesByBundleNameMap,
      [action.payload.bundleName]: [action.payload]
    };
  }
  const nextState = {
    ...state,
    assetBundleRulesByBundleNameMap: nextAssetBundleRulesByBundleNameMap,
    latest: {
      ...state.latest,
      assetBundleRules: R.sortBy(
        R.prop('priority'),
        R.unionWith(R.eqBy(R.prop('linkedId')), [action.payload], state.latest.assetBundleRules)
      )
    }
  };
  return nextState;
}
