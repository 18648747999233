import * as actionTypes from './actionTypes';

const initialState = {
  loading: false,
  segmentationScripts: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SEGMENTATIONS:
      return loadSegmentationScripts(state, action);
    default:
      return state;
  }
};

function loadSegmentationScripts(state, action) {
  if (action.pending) {
    return {
      ...state,
      loading: true
    };
  } else if (!action.error) {
    return {
      ...state,
      loading: false,
      segmentationScripts: action.payload.items
    };
  } else {
    return {
      ...state,
      loading: false
    };
  }
}
