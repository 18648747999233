import * as actionTypes from './actionTypes';
import {
  compose,
  createUIBlockingActionReducer,
  createFullStateResponseReducer
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.DELETE_LATEST_ASSET_BUNDLE_DEFINITION:
      return deleteLatestAssetBundleDefinition(state, action);
    case actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_DEFINITION:
      return purgeLatestUnpublishedAssetBundleDefinition(state, action);
    default:
      return state;
  }
};

const deleteLatestAssetBundleDefinition = compose(
  createUIBlockingActionReducer('Failed to delete asset bundle definition'),
  createFullStateResponseReducer()
);

const purgeLatestUnpublishedAssetBundleDefinition = compose(
  createUIBlockingActionReducer(
    'Failed to delete unpublished asset bundle definition changes'
  ),
  createFullStateResponseReducer()
);
