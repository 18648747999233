import R from 'ramda';


/**
 * 
 * @param {Function} getState       Function which given the global Redux state, returns the modules pieces of state which the selectors should concern itself with.
 * @param {String} property         The property name of the status variable in the state. Same as which is passed to reducerUtils.createAPIStatusReducer.
 */
export const createAPIStatusSelectors = (getState, property) => {
    const isPending = state => R.path(['apiStatus', property], getState(state)) === 'pending';
    const getError = state => (R.path(['apiStatus', property], getState(state)) !== null && !isPending(state)) ? 
                                        R.path(['apiStatus', property], getState(state))
                                        : undefined;

    return [ isPending, getError ];
}


