
export const NAME = 'ads';

export const YOUTUBE_CONTAINER = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/YouTubeVideo/index.html';
export const BANNER_CONTAINER = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/Banner/index.html';
export const INTERSTITIAL_CONTAINER = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/Interstitial/index.html';
export const SURVEY_CONTAINER = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/Survey/index.html';
export const WEB_APP_CONTAINER = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/WebApp/index.html';

export const SIMPLE_ENDCARD = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/SimpleEndcard/index.html';
export const SURVEY_ENDCARD = 'https://storage.googleapis.com/ads-content/ads/Web~/1.0.0/AdContainers/SurveyEndcard/index.html';

export const DEFAULT_POSTBACK_ID = 'ef5beb25-dde7-4b8f-8103-138a591117dd';
