import React, { FC } from 'react';     
import Authenticator from './Authenticator';
import TitledInfo, {TitledInfoProps} from './TitledInfo';

interface AuthenticatedTitledInfoProps extends TitledInfoProps {
  permissions: string[];
}

const AuthenticatedTitledInfo: FC<AuthenticatedTitledInfoProps> = ({ permissions, ...rest }) => {
  const replacement = <TitledInfo {...rest} secondary='You are not permitted to see this' />;

  return (
    <Authenticator permissions={permissions} replacement={replacement}>
      <TitledInfo {...rest} />
    </Authenticator>
  );
};

export default AuthenticatedTitledInfo;
