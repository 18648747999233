import * as R from 'ramda';
import { getState } from '../selectorUtils';
import { sortByCreated } from '../utils';

export const getLatestAssetBundleOptions = state =>
  sortByCreated(getState(state).latest.assetBundleOptions);
export const getAssetBundleOptionsByIds = (state, ids) =>
  getLatestAssetBundleOptions(state).filter(({ id }) => ids.indexOf(id) >= 0);
export const getAssetBundleOptionsById = (state, id) =>
  R.find(R.propEq('id', id), getLatestAssetBundleOptions(state));

export const getLatestAssetBundleOptionsByAssetBundleName = (
  state,
  assetBundleName
) =>
  getLatestAssetBundleOptions(state).filter(
    R.propEq('bundleName', assetBundleName)
  );

export const getAllBundleNames = state =>
  R.uniq(getLatestAssetBundleOptions(state).map(o => o.bundleName)).sort();
