import * as actionTypes from './actionTypes';
import { API } from '~/api';

export const purgeLatestUnpublishedAssetBundleOptions = (
  gameId,
  assetBundleOption
) => {
  const { linkedId } = assetBundleOption;
  return {
    type: actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_OPTION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/purge_latest_unpublished/assetbundleoptions/${linkedId}`,
      method: 'post'
    },
    meta: { assetBundleOption }
  };
};

export function updateLatestAssetBundleOption(gameId, assetBundleOptions) {
  return {
    type: actionTypes.UPDATE_LATEST_ASSET_BUNDLE_OPTION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/update_latest/assetbundleoptions/${assetBundleOptions.linkedId}`,
      method: 'post',
      body: assetBundleOptions
    },
    meta: { assetBundleOptions }
  };
}
