import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { debounce } from '~/utils';
import R from 'ramda';
/**
 *  Material UI TextField component, which debounces updates to the value. This means that the "onChange" callback is triggered
 *  only when the user has not typed for 200ms
 *  This is good where changing the value causes heavy re-rendering in parent component.
 */
const debounceLength = 200;
class TextFieldDebounce extends Component {
  static propTypes = {
    tooltip: PropTypes.string,
    tooltipDelay: PropTypes.number,
    delay: PropTypes.number
  };
  static defaultProps = {
    tooltip: '',
    tooltipDelay: 1000
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      valueOnLastOnChange: props.value
    };
  }

  static propChangeExpections = ['onChange'];
  shouldComponentUpdate(nextProps, nextState) {
    // There is a possibility that the onChange listener decided to set the value back to what it was before
    // onChange. If this happens, we need to set the state to this or else the state and props are out of sync even without
    // user input. Example of this is the config page defaulting to '0' if the input is ''.
    if (nextProps.value !== nextState.valueOnLastOnChange) {
      this.setState({
        value: nextProps.value,
        valueOnLastOnChange: nextProps.value
      });
      return false;
    }

    // Check if values have changed.
    for (var i in nextProps) {
      if (
        nextProps[i] != this.props[i] &&
        TextFieldDebounce.propChangeExpections.indexOf(i) === -1
      ) {
        return true;
      }
    }

    return (
      this.state.value !== nextState.value ||
      nextProps.value !== nextState.valueOnLastOnChange
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.state.valueOnLastOnChange) {
      this.setState({
        value: nextProps.value,
        valueOnLastOnChange: nextProps.value
      });
    }
  }

  handleChangeDebounced = debounce((event, value) => {
    this.props.onChange(value);
    this.setState({
      valueOnLastOnChange: value
    });
  }, R.isNil(this.props.delay) ? debounceLength : this.props.delay);

  handleChange = event => {
    event.stopPropagation();
    this.setState({ value: event.target.value });
    this.handleChangeDebounced(event, event.target.value);
  };

  // We listen for Enter presses to avoid problems with Enter triggering onSubmit
  // for a form before the debounced change event has triggered causing the submited data to be out of sync.
  handleKeyPress = event => {
    if (event.key == 'Enter') {
      event.stopPropagation();
      this.props.onChange(event.target.value);
      this.setState({
        valueOnLastOnChange: event.target.value
      });
    }
  };

  render() {
    const { tooltip, tooltipDelay, ...rest } = this.props;
    return (
      <Tooltip title={tooltip} enterDelay={tooltipDelay}>
        <TextField
          {...rest}
          value={!R.isNil(this.state.value) ? this.state.value : ''}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
      </Tooltip>
    );
  }
}

export default TextFieldDebounce;
