import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  createStyles,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button
} from '@material-ui/core';
import { ACL } from '../auth/components'
import * as R from 'ramda';
import classNames from 'classnames'

const Section = ({ classes, className, title, subheader, children, actions, ...rest }) => {
  const subheaderTextToRender = R.isNil(subheader) ? null : subheader;
  const renderHeader = () => R.isNil(title) ? null : (
    <CardHeader
      className={classes.header}
      title={title}
      titleTypographyProps={{ variant: 'subtitle1' }}
      subheader={subheaderTextToRender}
    />
  )
  
  const mappedActions = R.isNil(actions)
    ? []
    : actions.map((action, index) => (
      <ACL key={index} require={R.pathOr([], ['neededPermissions'], action)}>
        <Button
          {...action.buttonProps}
          onClick={action.func}
          color={R.isNil(action.color) ? 'default' : action.color}
          disabled={action.disabled}
        >
          {action.text}
        </Button>
      </ACL>
    )
  );

  const renderActions = () =>
    mappedActions.length <= 0 ? null : (
      <CardActions>{mappedActions}</CardActions>
    );

  const rootClassName = classNames(className, classes.root)

  return (
    <Card className={rootClassName} {...rest}>
      {renderHeader()}
      <CardContent className={classes.content}>{children}</CardContent>
      {renderActions()}
    </Card>
  );
};

const styles = createStyles({
  root: {
    margin: '0.5rem 0.5rem 0 0.5rem',
    padding: 0
  },
  header: {
    background: '#fafafa',
    borderBottom: '1px solid #ddd'
  },
  content: {
    margin: '0.5rem',
    fontSize: '14px'
  }
});

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subheader: (props, propName, componentName) => {
    if (R.isNil(props['title'])) {
      return new Error(`You need to provide a title prop to ${componentName} if you want to display the ${propName}.`)
    }
  },
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      func: PropTypes.func.isRequired,
      color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
      neededPermissions: PropTypes.arrayOf(PropTypes.string),
      buttonProps: PropTypes.object,
      disabled: PropTypes.bool
    })),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default withStyles(styles)(Section);
