import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
    config: null,
    ads: [],
    campaigns: [],
    campaignImpressions: {},
    getConfigStatus: null,
    getAllAdsStatus: null,
    getAllCampaignsStatus: null,
    deleteAdStatus: null,
    saveCamapignStatus: null,
    deleteCamapignStatus: null,
    saveAdStatus: null,
    getPreviewStatus: null,
    getFilterPropertiesStatus: null
}

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_CONFIG: return getConfig(state, action);
        case actionTypes.GET_ALL_ADS: return getAllAds(state, action);
        case actionTypes.GET_ALL_CAMPAIGNS: return getAllCampaigns(state, action);
        case actionTypes.SAVE_AD: return saveAd(state, action)
        case actionTypes.DELETE_AD: return deleteAd(state, action);
        case actionTypes.SAVE_CAMPAIGN: return saveCampaign(state, action);
        case actionTypes.DELETE_CAMPAIGN: return deleteCampaign(state, action);
        case actionTypes.GET_PREVIEW: return getPreview(state, action);
        case actionTypes.GET_FILTER_PROPERTIES: return getFilterProperties(state, action);
        case actionTypes.GET_CAMPAIGN_IMPRESSIONS: return getCampaignImpressions(state, action);
        default: return state;
    }
}

const getConfig = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            getConfigStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            getConfigStatus: 'Failed to get configuration'
        }
    }
    else {
        const bundleIdToName = R.reduce((curr, { bundleId, name }) => {
            curr[bundleId] = name;
            return curr;
        }, {}, action.payload.bundleIds)
        return {
            ...state,
            getConfigStatus: null,
            config: {
                ...action.payload,
                bundleIdToName,
                clientApiKeys: action.payload.clients.map(({ apiKey, bundleId, platform}) => {
                    switch (platform) {
                        case 'ios': 
                            platform = 'iOS'
                            break;
                        case 'webgl': 
                            platform = 'WebGL'
                            break;
                        default:
                            platform = platform.charAt(0).toUpperCase() + platform.substr(1);
                            break;
                    }
                    return {
                        apiKey,
                        name: `${bundleIdToName[bundleId]} - ${platform}`
                    }
                })
            }
        }
    }
}

const getPreview = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            getPreviewStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            getPreviewStatus: 'Failed to get preview'
        }
    }
    else {
        return {
            ...state,
            getPreviewStatus: null,
            preview: action.payload
        }
    }
}

const getAllAds = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            getAllAdsStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            getAllAdsStatus: 'Failed to get all ads'
        }
    }
    else {
        return {
            ...state,
            getAllAdsStatus: null,
            ads: addItems(state.ads, action.payload)
        }
    }
}

const getAllCampaigns = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            getAllCampaignsStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            getAllCampaignsStatus: 'Failed to get all campaigns'
        }
    }
    else {
        return {
            ...state,
            getAllCampaignsStatus: null,
            campaigns: addItems(state.campaigns, action.payload)
        }
    }
}

const saveAd = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            saveAdStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            saveAdStatus: R.path(['payload', 'error'], action) || 'Failed to save ad'
        }
    }
    else {
        return {
            ...state,
            saveAdStatus: null,
            ads: addItem(state.ads, action.payload)
        }
    }
}

const deleteAd = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            deleteAdStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            deleteAdStatus: R.path(['payload', 'error'], action) || 'Failed to delete ad'
        }
    }
    else {
        return {
            ...state,
            deleteAdStatus: null,
            ads: R.reject(R.propEq('id', action.meta.adId), state.ads)
        }
    }
}

const deleteCampaign = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            deleteCamapignStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            deleteCamapignStatus: R.path(['payload', 'error'], action) || 'Failed to delete campaign'
        }
    }
    else {
        return {
            ...state,
            deleteCamapignStatus: null,
            campaigns: R.reject(R.propEq('id', action.meta.campaignId), state.campaigns)
        }
    }
}

const saveCampaign = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            saveCamapignStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            saveCamapignStatus: R.path(['payload', 'error'], action) || 'Failed to save campaign'
        }
    }
    else {
        return {
            ...state,
            saveCamapignStatus: null,
            campaigns: addItem(state.campaigns, action.payload)
        }
    }
}


const getFilterProperties = (state, action) => {
    if (action.pending) {
        return {
            ...state,
            getFilterPropertiesStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            getFilterPropertiesStatus: 'Failed to get filter properties'
        }
    }
    else {
        const userProperties = R.reduce(R.mergeDeepLeft, {}, R.keys(action.payload).map(i => action.payload[i].mappings.game.properties.data.properties.saUserProperties.properties));
        
        // Converts Elasticsearch mapping structure to a list of saUserProperties by bundle id.
        var bundleIds = R.keys(userProperties);
        var filterPropertiesByBundleId = R.reduce((curr, bundleId) => {
            var properties = R.keys(userProperties[bundleId].properties);
            return R.reduce((curr2, property) => {
                if (R.isNil(curr2[property])) {
                    curr2[property] = {
                        field: property,
                        games: []
                    }
                }
                curr2[property].games.push(toBundleId(bundleId));
                return curr2;
            }, curr, properties);
        }, {}, bundleIds);
        
        return {
            ...state,
            getFilterPropertiesStatus: null,
            filterProperties: R.sortBy(R.prop('field'), R.values(filterPropertiesByBundleId))
        }
    }
}

const getCampaignImpressions = (state, action) => {
    if (!action.pending && !action.error) {
        return {
            ...state,
            campaignImpressions: {
                ...state.campaignImpressions,
                [action.meta.campaignId]: action.payload.impressions
            }
        }
    }
    else {
        // We don't care about the status of impressions.
        return state;
    }
}

// Utils functions

const toBundleId = (bundleId) => bundleId.replace(new RegExp('-', 'g'), '.');

const addItems = (items, otherItems) => R.unionWith(R.eqBy(R.prop('id')), otherItems, items)
const addItem = (items, item) => addItems(items, [ item ])