import * as R from 'ramda';

import { NAME as parentName } from '../constants';

export const NAME = 'contentdeliveryframework_new';
export const FULL_PATH = `${parentName}/${NAME}`;

export const formattedPlatforms = [
  'iOS',
  'Android',
  'WebGL',
  'Amazon',
  'Server Only'
];
export const platforms = R.map(
  x => R.toLower(x).replace(/ /g, ''),
  formattedPlatforms
);
const platformToFormated = R.zipObj(platforms, formattedPlatforms);
export const getFormatedPlatform = platform => platformToFormated[platform];

export const SpecialBundleVersions = {
  ALWAYS_LATEST: -1,
  DO_NOT_SERVE: -2
};

export const formattedSpecialBundleVersions = {
  ALWAYS_LATEST: 'Always Latest',
  DO_NOT_SERVE: "Don't Serve"
};

export const SpecialBundleVersionIndexes = Object.keys(
  SpecialBundleVersions
).map(key => SpecialBundleVersions[key]);

export function getFormattedBundleVersion(versionNumber) {
  let formattedVersionKey = R.invertObj(SpecialBundleVersions)[versionNumber];
  if (formattedVersionKey) {
    return formattedSpecialBundleVersions[formattedVersionKey];
  }
  return versionNumber;
}

export const isSpecialBundleVersion = bundleVersion =>
  R.values(SpecialBundleVersions).indexOf(bundleVersion) >= 0;
export const isAlwaysLatest = bundleVersion =>
  bundleVersion === SpecialBundleVersions.ALWAYS_LATEST;
export const isDoNotServe = bundleVersion =>
  bundleVersion === SpecialBundleVersions.DO_NOT_SERVE;
