import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import minMax from "dayjs/plugin/minMax";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import duration from "dayjs/plugin/duration";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import isBetween from "dayjs/plugin/isBetween";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(tz);
dayjs.extend(utc)
dayjs.extend(minMax);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(objectSupport);

// It would seem that there is currently no way to persist the timezone in Day.js if it's already provided in the date
// string. This is a custom plugin I found in one GitHub thread here: https://github.com/iamkun/dayjs/issues/651
const ISO8601_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;
dayjs.preserveZone = function(date?: dayjs.ConfigType) {
	if (typeof date === 'string') {
		const match = date.match(ISO8601_OFFSET_FORMAT);
		if (match !== null) {
			if (match[0] === 'Z') {
				return dayjs(date, {
					utc: true,
					// eslint-disable-next-line prefer-rest-params
					args: arguments,
				} as dayjs.OptionType);
			}
			const [, dateTime, sign, tzHour, tzMinute] = match;

			const h = parseInt(tzHour, 10);
			const m = parseInt(tzMinute, 10);
			const uOffset = h * 60 + m;
			const offset = sign === '+' ? uOffset : -uOffset;

			return dayjs(dateTime, {
				$offset: offset,
				// eslint-disable-next-line prefer-rest-params
				args: arguments,
			} as dayjs.OptionType);
		}
	}

	return dayjs(date, {
		// eslint-disable-next-line prefer-rest-params
		args: arguments,
	} as dayjs.OptionType);
};

export default dayjs;