import React, { Component } from 'react';
import {
  withStyles,
  Paper,
  CircularProgress,
  LinearProgress
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const styles = {
  circularContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    borderRadius: 50
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};

class LoadingContainer extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    isLoading: PropTypes.bool,
    // Use a linear loading indicator instead
    useLinear: PropTypes.bool,
    indicatorSize: (props, propName, componentName) => {
      const prop = R.path([propName], props);

      if (!R.isNil(prop) && props.useLinear) {
        return new Error('Cannot set size for a linear loading indicator.');
      } else if (!R.isNil(prop) && typeof prop !== 'number') {
        return new Error(
          `Invalid type of ${propName} provided to ${componentName}. Expected a number.`
        );
      }
    }
  };

  render() {
    const {
      children,
      className,
      indicatorSize,
      classes,
      isLoading,
      useLinear,
      ...rest
    } = this.props;
    if (isLoading && useLinear) return <LinearProgress />;
    else if (isLoading)
      return (
        <div {...rest} className={classNames(className, classes.loading)}>
          <Paper className={classes.circularContainer}>
            <CircularProgress size={indicatorSize} />
          </Paper>
        </div>
      );

    return children;
  }
}

export default withStyles(styles)(LoadingContainer);
