import { useMsal } from '@azure/msal-react';
import { extractUserName } from '../utils';

function useUserName(): string {
  const { accounts } = useMsal();
  const name = extractUserName(accounts);
  return name;
}

export default useUserName;
