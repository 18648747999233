import * as actionTypes from './actionTypes';

export const addNotification = (notification, variant = 'info') => ({
  type: actionTypes.ADD_NOTIFICATION,
  payload: {
    notification,
    variant
  }
});

export const closeNotifications = () => ({
  type: actionTypes.CLOSE_NOTIFICATIONS
});
