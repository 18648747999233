// @ts-nocheck
import * as actionTypes from './actionTypes';
import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';

const initialState = {
  fetchTournamentEventGroupStatus: null,
  kickPlayerFromEventGroupStatus: {},
  fetchStarTournamentGroupStatus: null,
  fetchStarTournamentGroupResult: {},
  kickPlayerFromStarTournamentGroupStatus: null,
  fetchEndGameTournamentGroupStatus: null,
  fetchEndGameTournamentGroupResult: {},
  kickPlayerFromEndGameTournamentGroupStatus: null,
  tournamentEvents: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TOURNAMENT_EVENT_GROUP:
      return fetchTournamentEventGroup(state, action);
    case actionTypes.KICK_PLAYER_FROM_TOURNAMENT_EVENT_GROUP:
      return kickPlayerFromEventGroup(state, action);
    case actionTypes.FETCH_STAR_TOURNAMENT_GROUP:
      return fetchStarTournamentGroup(state, action);
    case actionTypes.KICK_PLAYER_FROM_STAR_TOURNAMENT_GROUP:
      return kickPlayerFromStarTournamentGroup(state, action);
    case actionTypes.FETCH_END_GAME_TOURNAMENT_GROUP:
      return fetchEndGameTournamentGroup(state, action);
    case actionTypes.KICK_PLAYER_FROM_END_GAME_TOURNAMENT_GROUP:
      return kickPlayerFromEndGameTournamentGroup(state, action);
    default:
      return state;
  }
};

const fetchTournamentEventGroup = compose(
  createAPIStatusReducer(
    'fetchTournamentEventGroupStatus',
    'Failed to fetch the tournament event group.'
  ),
  onSuccess((state, action) => ({
    ...state,
    tournamentEvents: {
      ...state.tournamentEvents,
      [action.payload.state.groupId]: action.payload
    }
  }))
);

const kickPlayerFromEventGroup = compose(
  createAPIStatusReducer(
    'kickPlayerFromEventGroupStatus',
    'Failed to kick player from event group'
  ),
  onSuccess((state, action) => ({
    ...state,
    tournamentEvents: {
      ...state.tournamentEvents,
      [action.payload.state.groupId]: action.payload
    }
  }))
);

const fetchStarTournamentGroup = compose(
  createAPIStatusReducer(
    'fetchStarTournamentGroupStatus',
    'Failed to fetch the tournament group.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchStarTournamentGroupResult: action.payload
  }))
);

const kickPlayerFromStarTournamentGroup = compose(
  createAPIStatusReducer(
    'kickPlayerFromStarTournamentGroupStatus',
    'Failed to kick the player from the Star Tournament group.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchStarTournamentGroupResult: action.payload
  }))
);

const fetchEndGameTournamentGroup = compose(
  createAPIStatusReducer(
    'fetchEndGameTournamentGroupStatus',
    'Failed to fetch the tournament group.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchEndGameTournamentGroupResult: action.payload
  }))
);

const kickPlayerFromEndGameTournamentGroup = compose(
  createAPIStatusReducer(
    'kickPlayerFromEndGameTournamentGroupStatus',
    'Failed to kick the player from the Star Tournament group.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchEndGameTournamentGroupResult: action.payload
  }))
);
