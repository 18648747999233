

/*
  Composes a bunch of reducers into one, so that the reducers are called in the order that they appear in the function call.
  Example usage:
    const reducer = compose(
        createAPIStatusReducer('getStatus', 'Failed to fetch thing'),
        onSuccess((state, action) => (
            {
                ...state,
                entity: action.payload
            }
        ))
    )
*/
export const compose = function(/* 1 or more reducer functions */) {
    const reducers = arguments;
    return (state, action) => {
        for (const reducer of reducers) {
            state = reducer(state, action);
        }
        return state;
    }
}



export const onSuccess = (fn) => (state, action) => {
    if (!action.pending && !action.error) {
        return fn(state, action);
    }
    else {
        return state;
    }
}

export const onError = (fn) => (state, action) => {
    if (action.error) {
        return fn(state, action);
    }
    else {
        return state;
    }
}

export const onPending = (fn) => (state, action) => {
    if (action.pending) {
        return fn(state, action);
    }
    else {
        return state;
    }
}


/**
 * 
 * @param {String} propertyName                 Name of the property that should contain the status of the API request
 * @param {String|Function} errorFnOrString     Either a String which is the error message of the API, or a function which maps (state, action) to a string.
 */
export const createAPIStatusReducer = (propertyName, errorFnOrString) => 
    compose(
        onPending((state) => (
            {
                ...state,
                apiStatus: {
                    ...(state.apiStatus || {}),
                    [propertyName]: 'pending'
                }
            }
        )),
        onError((state, action) => (
            {
                ...state,
                apiStatus: {
                    ...(state.apiStatus || {}),
                    [propertyName]: (typeof errorFnOrString === 'function' ? errorFnOrString(state, action) : errorFnOrString)
                }
            }
        )),
        onSuccess((state) => (
            {
                ...state,
                apiStatus: {
                    ...(state.apiStatus || {}),
                    [propertyName]: null
                }
            }
        ))
    );