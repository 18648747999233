import React from 'react';
import { Paper, withStyles, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined, WarningOutlined } from '@material-ui/icons';
import classNames from 'classnames';

const AlertStyles = theme => {
  return {
    root: {
      padding: '6px 16px',
      display: 'flex',
      '&> *': {
        margin: '4px'
      },
    },
    alert: {
      backgroundColor: 'rgb(253, 236, 234)',
      color: theme.palette.error.dark
    },
    info: {
      backgroundColor: '#d9edf7',
      color: '#2196f3'
    },
    warning: {
      backgroundColor: "#ffe88c",
      color: '#9f8001'
    }
  };
};

const Alert = ({ className, classes, children, type = 'alert', ...rest }) => (
  <Paper {...rest} elevation={0} className={classNames(className, classes.root, classes[type])}>
    {type === 'alert' && <ErrorOutline />}
    {type === 'info' && <InfoOutlined />}
    {type === 'warning' && <WarningOutlined />}
    <Typography variant='body2'>{children}</Typography>
  </Paper>
);

export default withStyles(AlertStyles)(Alert);

export const AlertMessage = withStyles({
  container: {
    display: 'block'
  }
})(({ classes, message }) => (
  <span className={classes.container}>{message}</span>
));
