import * as actionTypes from './actionTypes';

import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';

const initialState = {
  fetchTeamLeaderboardStatus: null,
  fetchTeamLeaderboardResult: {
    entries: [],
    totalEntryAmount: null
  },
  fetchPlayerLeaderboardStatus: null,
  fetchPlayerLeaderboardResult: {
    entries: [],
    totalEntryAmount: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TEAM_LEADERBOARD:
      return fetchTeamLeaderboard(state, action);
    case actionTypes.FETCH_PLAYER_LEADERBOARD:
      return fetchPlayerLeaderboard(state, action);
    default:
      return state;
  }
};

const fetchTeamLeaderboard = compose(
  createAPIStatusReducer(
    'fetchTeamLeaderboardStatus',
    'Failed to fetch team leaderboard.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchTeamLeaderboardResult: action.payload
  }))
);

const fetchPlayerLeaderboard = compose(
  createAPIStatusReducer(
    'fetchPlayerLeaderboardStatus',
    'Failed to fetch player leaderboard.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchPlayerLeaderboardResult: action.payload
  }))
);
