import { NAME } from './constants';
import { NAME as PARENT_NAME } from '../constants';

const getRelativeType = TYPE => `${PARENT_NAME}/${NAME}/${TYPE}`;

export const FETCH_SETTINGS = getRelativeType('FETCH_SETTINGS');

export const SET_REQUIRED_CLIENT_VERSION = getRelativeType(
  'SET_REQUIRED_CLIENT_VERSION'
);

export const SET_MAINTENANCE_MODE = getRelativeType('SET_MAINTENANCE_MODE');

export const SET_TRACING = getRelativeType('SET_TRACING');

export const SET_REDIS_MONITORING = getRelativeType('SET_REDIS_MONITORING');

export const SET_TOURNAMENT_EVENTS_UNLOCK_LEVEL = getRelativeType(
  'SET_TOURNAMENT_EVENTS_UNLOCK_LEVEL'
);

export const FLUSH_GAME_SERVER_CACHE = getRelativeType(
  'FLUSH_GAME_SERVER_CACHE'
);

export const INVALIDATE_SESSIONS = getRelativeType('INVALIDATE_SESSIONS');

export const RESET_ALL_ENDGAME_LEADERBOARD_LEVELS = getRelativeType(
  'RESET_ALL_ENDGAME_LEADERBOARD_LEVELS'
);

export const SET_PREVENT_CHEATERS_FROM_JOINING_SOCIAL_FEATURES = getRelativeType(
  'SET_PREVENT_CHEATERS_FROM_JOINING_SOCIAL_FEATURES'
);

export const FLUSH_CHAT_SERVER_CACHE = getRelativeType(
  'FLUSH_CHAT_SERVER_CACHE'
);

export const CLOSE_ALL_CLIENT_CONNECTIONS = getRelativeType(
  'CLOSE_ALL_CLIENT_CONNECTIONS'
);

export const FETCH_CHAT_SERVER_SETTINGS = getRelativeType(
  'FETCH_CHAT_SERVER_SETTINGS'
);

export const ENABLE_CHAT_SERVER_MAINTENANCE_MODE = getRelativeType(
  'ENABLE_CHAT_SERVER_MAINTENANCE_MODE'
);

export const DISABLE_CHAT_SERVER_MAINTENANCE_MODE = getRelativeType(
  'DISABLE_CHAT_SERVER_MAINTENANCE_MODE'
);

export const GET_CONFIGS = getRelativeType('GET_CONFIGS');
export const GET_CONFIG = getRelativeType('GET_CONFIG');
export const POST_CONFIG = getRelativeType('POST_CONFIG');
export const PUT_CONFIG = getRelativeType('PUT_CONFIG');
export const DELETE_CONFIG = getRelativeType('DELETE_CONFIG');
