import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  entities: [],
  getStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.UPDATE:
      return update(state, action);
    default:
      return state;
  }
};

const update = (state, action) => {
  return {
    ...state,
    entities: addItem(state.entities, action.payload.item)
  };
};

const get = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: 'Failed to get example item'
    };
  } else {
    return {
      ...state,
      getStatus: null,
      entities: addItem(state.entities, action.payload)
    };
  }
};

// Utils functions
const addItem = (items, item) =>
  R.unionWith(R.eqBy(R.prop('id')), [item], items);
