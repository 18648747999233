import * as R from 'ramda';
import { NAME } from './constants';
import { NAME as parentName } from '../constants';
import { entitySetToListWithDefinitions, flatMapEntitySet } from './entitySet';

import * as userGroupTagSelectors from './userGroupTags/selectors';
import * as assetBundleConfigurationSelectors from './assetBundleConfigurations/selectors';
import * as assetBundleOptionsSelectors from './assetBundleOptions/selectors';
import * as databaseLinksSelectors from './databaseLinks/selectors';
import * as assetBundleDefinitionSelectors from './assetBundleDefinitions/selectors';
import * as assetBundleRuleSelectors from './assetBundleRules/selectors';
import * as buildInformationsSelectors from './buildInformations/selectors';
import * as testUserSelectors from './testUsers/selectors';

let getState = state => state[parentName][NAME];

// Submodule selectors
export const userGroupTags = userGroupTagSelectors;
export const assetBundleConfigurations = assetBundleConfigurationSelectors;
export const databaseLinks = databaseLinksSelectors;
export const assetBundleDefinitions = assetBundleDefinitionSelectors;
export const assetBundleOptions = assetBundleOptionsSelectors;
export const assetBundleRules = assetBundleRuleSelectors;
export const buildInformations = buildInformationsSelectors;
export const testUsers = testUserSelectors;

export const getLatest = state => getState(state).latest;
export const getMaxVersionsMap = state => getState(state).maxVersionsMap;
export const getLatestAssetBundleDefinitionByBundleName = (state, bundleName) =>
  getState(state).assetBundleDefinitionsByVariant[bundleName].mostRecent;
export const getLatestByProperty = (state, property) => getState(state).latest[property] || [];

export const isStateLoaded = state => getState(state).initialStateLoaded === true;
export const isAnyPending = state => !R.isEmpty(getState(state).pendingRequests);

// Snackbar
export const getSnackbars = state => getState(state).snackbars;

// Simulated run
export const getSimulatedRun = state => getState(state).simulatedRun;
export const getSimulatedRunWithDefinitions = state => {
  const simulatedRun = getSimulatedRun(state);
  if (!simulatedRun) return null;

  const concatAll = R.reduce(R.concat, []);
  const definitionIds = simulatedRun.objectIds && R.uniq(R.pluck('assetBundleDefinitionId', simulatedRun.objectIds));
  const ruleIds = simulatedRun.objectIds && R.uniq(R.pluck('assetBundleRuleId', simulatedRun.objectIds));
  const rules = ruleIds && concatAll(ruleIds.map(id => assetBundleRules.getAssetBundleRulesByIds(state, id)));
  const ruleConfigurations = rules.map(({ assetBundleConfigurationLinkedIds = [] }) =>
    assetBundleConfigurationLinkedIds.map(linkedId =>
      assetBundleConfigurationSelectors.getLatestAssetBundleConfigurationsByLinkedId(state, linkedId)
    )
  );

  return {
    ...simulatedRun,
    assetBundleDefinitions:
      definitionIds && definitionIds.map(id => assetBundleDefinitions.getAssetBundleDefinitionsById(state, id)),
    rules,
    ruleConfigurations
  };
};

export const getUnpublishedEntitySet = state => getState(state).nonPublishedEntities;
export const getPublishHistory = state => getState(state).publishHistoryEntries;
export const getPublishHistoryPageCount = state => getState(state).publishHistoryPageCount;
export const getLatestPublishHistoryFetchPage = state => getState(state).latestPublishHistoryFetchPage;
export const getUnpublishedChangesCount = state => getState(state).nonPublishedEntitiesCount;

const sortByChangesByEdited = R.compose(
  R.reverse,
  R.sortBy(R.path(['entity', 'newEntity', 'edited']))
);

export const entitySetToList = (state, entitySet) =>
  sortByChangesByEdited(
    entitySetToListWithDefinitions(entitySet).map(value => {
      const hasDefinition = !R.isNil(value.definition);
      return {
        ...value,
        isLatest:
          hasDefinition &&
          !R.isNil(value.entity.newEntity) &&
          !R.isNil(
            R.find(
              R.propEq('id', value.entity.newEntity.id),
              getLatestByProperty(state, value.definition.entitySetProperty)
            )
          )
      };
    })
  );

export const getAllLinkedIds = state =>
  R.uniq([
    ...flatMapEntitySet(getUnpublishedEntitySet(state), R.path(['newEntity', 'linkedId'])),
    ...R.chain(({ diff }) => flatMapEntitySet(diff, R.path(['newEntity', 'linkedId'])), getPublishHistory(state))
  ]);

/*
    const latestConfigs = assetBundleConfigurations.getLatestAssetBundleConfigurations(state);
    const latestDefinitions = assetBundleDefinitions.getLatestAssetBundleDefinitions(state);
    const latestRules = assetBundleRules.getLatestAssetBundleRules(state);
    const latestUserGroupTags = userGroupTags.getLatestUserGroupTags(state);
    const currentDatabaseLinks = databaseLinks.getDatabaseLinks(state);
    const currentTestUsers = testUsers.getTestUsers(state)
    const currentBuildInformations = buildInformations.getBuildInformations(state)
    return sortByChangesByCreated([
            ...wrapChanges(RenderTypes.BundleConfiguration, entitySet.assetBundleConfigurations, latestConfigs),
            ...wrapChanges(RenderTypes.BundleDefinition, entitySet.assetBundleDefinitions, latestDefinitions),
            ...wrapChanges(RenderTypes.BundleRule, entitySet.assetBundleRules, latestRules),
            ...wrapChanges(RenderTypes.UserGroupTag, entitySet.userGroupTags, latestUserGroupTags),
            ...wrapChanges(RenderTypes.DatabaseLink, entitySet.databaseLinks, currentDatabaseLinks),
            ...wrapChanges(RenderTypes.TestUser, entitySet.testUsers, currentTestUsers),
            ...wrapChanges(RenderTypes.BuildInformation, entitySet.buildInformation, currentBuildInformations)
        ])*/
