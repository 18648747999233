import prodIcon from '../../assets/favicon.png';
import stagingIcon from '../../assets/favicon_purple.png';
import devIcon from '../../assets/favicon_blue.png';
import * as routePaths from './routePaths';

export const intlDateTimeFormat: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric'};
export const dateFormat = 'YYYY/MM/DD HH:mm';
export const C_SHARP_MAX_INT = 2147483647;

declare const DASHBOARD_ENV: string;
const env = DASHBOARD_ENV || '';

export const getSeriouslyLogoForEnvironment = () => {
    const icon = env === 'production'
        ? prodIcon
        : env === 'staging'
        ? stagingIcon
        : devIcon;

    return icon;
};

export {
    routePaths
};