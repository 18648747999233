import * as actionTypes from './actionTypes';
import { compose, onSuccess } from '~/reducerUtils';
import { createUIBlockingActionReducer } from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_TEST_USER:
      return addTestUser(state, action);
    case actionTypes.DELETE_TEST_USER:
      return deleteTestUser(state, action);
    case actionTypes.UPDATE_TEST_USER:
      return updateTestUsers(state, action);
    default:
      return state;
  }
};

const addTestUser = compose(
  createUIBlockingActionReducer('Failed to create test user'),
  onSuccess((state, action) => ({
    ...state,
    latest: {
      ...state.latest,
      testUsers: [...state.latest.testUsers, action.payload]
    }
  }))
);

const deleteTestUser = compose(
  createUIBlockingActionReducer('Failed to delete test user'),
  onSuccess((state, action) => ({
    ...state,
    latest: {
      ...state.latest,
      testUsers: R.reject(
        R.propEq('linkedId', action.payload.linkedId),
        state.latest.testUsers
      )
    }
  }))
);

const updateTestUsers = compose(
  createUIBlockingActionReducer('Failed to update test user'),
  onSuccess((state, action) => ({
    ...state,
    latest: {
      ...state.latest,
      testUsers: R.unionWith(
        R.eqBy(R.prop('linkedId')),
        [action.payload],
        state.latest.testUsers
      )
    }
  }))
);
