import * as R from 'ramda';
import { getState } from '../selectorUtils';
import { sortByCreated } from '../utils';
import { isAlwaysLatest, isDoNotServe, isSpecialBundleVersion } from '../constants';

export const getLatestAssetBundleDefinitions = state => sortByCreated(getState(state).latest.assetBundleDefinitions);
export const getAssetBundleDefinitionsByIds = (state, ids) =>
  getLatestAssetBundleDefinitions(state).filter(({ id }) => ids.indexOf(id) >= 0);
export const getAssetBundleDefinitionsById = (state, id) =>
  R.find(R.propEq('id', id), getLatestAssetBundleDefinitions(state));

export const getLatestAssetBundleDefinitionsByAssetBundleName = (state, assetBundleName) =>
  getLatestAssetBundleDefinitions(state).filter(R.propEq('bundleName', assetBundleName));
export const getLatestAssetBundleDefinitionsByAssetBundleNameAndPlatform = (state, assetBundleName, platform) =>
  getLatestAssetBundleDefinitionsByAssetBundleName(state, assetBundleName).filter(R.propEq('platform', platform));

export const getAssetBundleDefinitionVersions = (state, assetBundleName, platform) =>
  getLatestAssetBundleDefinitionsByAssetBundleNameAndPlatform(state, assetBundleName, platform).map(
    R.prop('versionNumber')
  );

export const getAssetBundleDefinitionByVariant = (state, assetBundleName, platform, variant) => {
  /*
  return getLatestAssetBundleDefinitionsByAssetBundleNameAndPlatform(
    state,
    assetBundleName,
    platform
  ).filter(R.propEq('variant', variant));
  */
  return getAssetBundleDefinitions(state, assetBundleName, platform, variant);
};

export const getMaxAssetBundleDefinitionVersionForVariant = (state, assetBundleName, platform, variant) => {
  const maxVersion = R.pathOr(-1, ['maxVersionsMap', assetBundleName, platform, variant], getState(state));
  return getAssetBundleDefinitions(state, assetBundleName, platform, variant, maxVersion);
};

export const getAssetBundleDefinitions = function(state, assetBundleName, platform, variant, versionNumber) {
  if (!assetBundleName || !platform) {
    return [];
  }

  const s = getState(state);
  // Overloaded
  if (versionNumber === undefined) {
    const definition = R.pathOr(
      {},
      ['assetBundleDefinitionsByVariant', assetBundleName, 'platforms', platform?.toString(), variant?.toString()],
      s
    );
    return Object.values(definition) || [];
  } else {
    return s.assetBundleDefinitionsByVariant[assetBundleName].platforms[platform][variant][versionNumber];
  }
};

export const getAssetBundleDefinitionByVersionNumber = (state, assetBundleName, platform, variant, versionNumber) => {
  if (isSpecialBundleVersion(versionNumber)) {
    if (isAlwaysLatest(versionNumber)) {
      return getMaxAssetBundleDefinitionVersionForVariant(state, assetBundleName, platform, variant);
    } else {
      return null;
    }
  } else {
    /*
    const value = R.find(
      v => v.versionNumber === versionNumber && v.variant === variant,
      getLatestAssetBundleDefinitions(state)
    );*/
    return getAssetBundleDefinitions(state, assetBundleName, platform, variant, versionNumber);
  }
};

export const getAllBundleNames = state => {
  return Object.keys(getState(state).assetBundleDefinitionsByVariant).sort();
  // return R.uniq(getLatestAssetBundleDefinitions(state).map(o => o.bundleName)).sort();
};

export const getVariants = (state, bundleName, platform) => {
  return Object.keys(getState(state).assetBundleDefinitionsByVariant[bundleName].platforms[platform])
    .filter(key => key !== 'undefined')
    .sort();
  /*
  return R.uniq(
    getLatestAssetBundleDefinitions(state)
      .filter(R.allPass([R.propEq('bundleName', bundleName), R.propEq('platform', platform)]))
      .map(R.prop('variant'))
      .sort()
  );
  */
};
