import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import AutoComplete from 'material-ui/AutoComplete';

const searchContainerStyle = {
  marginBottom: 30,
  padding: 5
};

class SearchBox extends Component {
  static propTypes = {
    onSearch: PropTypes.func.isRequired,
    onSearchTextChange: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    errors: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired
  };

  shouldComponentUpdate(nextProps) {
    for (var i in this.props) {
      if (nextProps[i] !== this.props[i]) {
        return true;
      }
    }
    return false;
  }

  triggerSearch = (event, text = this.props.text) => {
    if (event !== null) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.props.onSearch(text);
  };

  handleSearchChange = searchText => this.props.onSearchTextChange(searchText);

  handleSearchOptionSelected = selectedRow => {
    this.props.onSearchTextChange(selectedRow);
    this.triggerSearch(null, selectedRow);
  };

  render() {
    const { errors, text, suggestions, disabled, label } = this.props;
    return (
      <Paper zDepth={2} style={searchContainerStyle}>
        <form onSubmit={this.triggerSearch}>
          <div className='row middle-xs'>
            <div className='col-xs-9 col-sm-10'>
              <AutoComplete
                fullWidth={true}
                type='text'
                searchText={text}
                floatingLabelText={label}
                errorText={errors}
                onUpdateInput={this.handleSearchChange}
                onNewRequest={this.handleSearchOptionSelected}
                dataSource={suggestions}
                disabled={disabled}
                openOnFocus={true}
                maxSearchResults={10}
              />
            </div>
            <div className='col-xs-3 col-sm-2'>
              <RaisedButton
                fullWidth={true}
                disabled={disabled}
                label='Search'
                type='submit'
                secondary={true}
                style={{ float: 'right' }}
              />
            </div>
          </div>
        </form>
      </Paper>
    );
  }
}

export default SearchBox;
