// @ts-nocheck
import * as actionTypes from './actionTypes';
import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';

const initialState = {
  fetchChatRoomDataResult: {},
  fetchPlayerProfilesResult: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CHAT_ROOM_DATA:
      return fetchChatRoomData(state, action);
    case actionTypes.FETCH_PLAYER_PROFILES:
      return fetchPlayerProfiles(state, action);
    case actionTypes.POST_MESSAGE:
      return postChatMessage(state, action);
    case actionTypes.DELETE_MESSAGE:
      return deleteChatMessage(state, action);
    default:
      return state;
  }
};

const fetchChatRoomData = compose(
  createAPIStatusReducer(
    'fetchChatRoomDataStatus',
    'Failed to fetch chat room data.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchChatRoomDataResult: action.payload
  }))
);

const fetchPlayerProfiles = compose(
  createAPIStatusReducer(
    'fetchPlayerProfilesStatus',
    'Failed to fetch player profiles.'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchPlayerProfilesResult: action.payload.profiles
  }))
);

const postChatMessage = compose(
  createAPIStatusReducer('postChatMessageStatus', 'Failed to post the message'),
  onSuccess((state, action) => ({
    ...state,
    fetchChatRoomDataResult: action.payload
  }))
);

const deleteChatMessage = compose(
  createAPIStatusReducer('deleteMessageStatus', 'Failed to delete the message'),
  onSuccess((state, action) => ({
    ...state,
    fetchChatRoomDataResult: action.payload
  }))
);
