import R from 'ramda';
import { propertyToDefinition } from './entityDefinitions';
/**
 *
 *
 *  EntitySet utility functions.
 *
 *  An EntitySet is a class on the server side which contains x number of properties, one for each different entity type.
 *
 */

export const isEntitySetEmpty = entitySet =>
  R.all(R.isEmpty, R.values(entitySet));
export const flatMapEntitySet = (entitySet, fn) =>
  R.chain(arr => arr.map(fn), R.values(entitySet));
export const filterEntitySet = (entitySet, fn) =>
  R.mapObjIndexed(value => value.filter(fn), entitySet);

export const entitySetToListWithDefinitions = entitySet =>
  R.flatten(
    R.values(
      R.mapObjIndexed(
        (arr, key) =>
          arr.map(entity => ({
            entity,
            definition: propertyToDefinition(key)
          })),
        entitySet
      )
    )
  );
