import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import classNames from 'classnames';

/**
    TextField which has a checkbox adornment.
    Toggling the checkbox off sets the value of the TextField to a default value and disables the input.
    Toggling the checkbox on sets the value to something and enables the TextField.

    One use case for this component is when the user can edit a numeric property that is ignored if it is less than or equal to zero.
    In other words, the property is counted as disabled when it is zero or less. This component makes that clear.
*/

const styles = {
  inputAdornment: {
    marginLeft: -15
  },
  textField: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
};

class CheckboxTextField extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    uncheckedLabel: PropTypes.string,
    onCheckboxChange: PropTypes.func
  };

  static defaultProps = {
    uncheckedLabel: 'Disabled'
  };

  constructor(props) {
    super(props);
    this.checkboxRef = React.createRef();
    this.inputRef = React.createRef();
  }

  handleInputClick = e => {
    // Check checkbox if the TextField is clicked when the checkbox is not checked.
    if (!this.props.checked) {
      if (
        e.target.type === 'checkbox' &&
        typeof e.target.checked !== 'undefined'
      ) {
        this.handleCheckboxClicked(e);
      } else {
        this.checkboxRef.current.checked = true;
        this.handleCheckboxClicked({ target: this.checkboxRef.current });
        // Timeout needed to allow the input to be enabled before focusing.
        setTimeout(() => {
          this.inputRef.current.focus();
          this.inputRef.current.select();
        }, 50);
      }
    }
  };

  handleCheckboxClicked = e => {
    this.props.onCheckboxChange(e);
  };

  render() {
    const {
      classes,
      checked,
      value,
      uncheckedLabel,
      type,
      disabled,
      onCheckboxChange,
      ...other
    } = this.props;
    return (
      <TextField
        value={checked ? value : uncheckedLabel}
        type={checked ? type : 'text'}
        disabled={disabled || !checked}
        onClick={this.handleInputClick}
        inputRef={this.inputRef}
        inputProps={{
          className: !checked && classes.textField
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              classes={{ root: classes.inputAdornment }}
              position='end'
            >
              <Checkbox
                inputRef={this.checkboxRef}
                checked={checked}
                disabled={disabled}
                onChange={this.handleCheckboxClicked}
              />
            </InputAdornment>
          )
        }}
        {...other}
      />
    );
  }
}

export default withStyles(styles)(CheckboxTextField);
