import R from 'ramda';
import { purgeLatestUnpublishedAssetBundleConfiguration } from './assetBundleConfigurations/actions';
import { purgeLatestUnpublishedAssetBundleDefinition } from './assetBundleDefinitions/actions';
import { purgeLatestUnpublishedAssetBundleOptions } from './assetBundleOptions/actions';
import { purgeLatestUnpublishedAssetBundleRule } from './assetBundleRules/actions';
import { purgeLatestUnpublishedUserGroupTag } from './userGroupTags/actions';
import { purgeLatestBuildInformation } from './buildInformations/actions';

const entityDefinitions = [
  // Manual published
  {
    name: 'Configuration',
    entitySetProperty: 'assetBundleConfigurations',

    actions: {
      purge: purgeLatestUnpublishedAssetBundleConfiguration
    }
  },
  {
    name: 'Bundle',
    entitySetProperty: 'assetBundleDefinitions',

    actions: {
      purge: purgeLatestUnpublishedAssetBundleDefinition
    }
  },
  {
    name: 'Rule',
    entitySetProperty: 'assetBundleRules',

    actions: {
      purge: purgeLatestUnpublishedAssetBundleRule
    }
  },
  {
    name: 'Options',
    entitySetProperty: 'assetBundleOptions',

    actions: {
      purge: purgeLatestUnpublishedAssetBundleOptions
    }
  },
  {
    name: 'User Group Tag',
    entitySetProperty: 'userGroupTags',

    actions: {
      purge: purgeLatestUnpublishedUserGroupTag
    }
  },
  {
    name: 'Build Information',
    entitySetProperty: 'buildInformation',

    actions: {
      purge: purgeLatestBuildInformation
    }
  },

  // Auto published
  {
    name: 'Active Database Link',
    entitySetProperty: 'activeDatabaseLink'
  },
  {
    name: 'Database Link',
    entitySetProperty: 'databaseLinks'
  },
  {
    name: 'Test User',
    entitySetProperty: 'testUsers'
  }
];
export default entityDefinitions;

export const entitySetProperties = entityDefinitions.map(
  R.prop('entitySetProperty')
);

export const propertyToDefinition = property =>
  R.find(R.propEq('entitySetProperty', property), entityDefinitions);
