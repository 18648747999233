import * as actionTypes from './actionTypes';
import { API } from '~/api';
import Q from 'q';
import * as R from 'ramda';

export function purgeLatestBuildInformation(gameId, buildInformation) {
  return {
    type: actionTypes.PURGE_BUILD_INFORMATION,
    [API]: {
      url: `content_delivery_framework_v2/${gameId}/assets/purge_latest_unpublished/buildinformation/${buildInformation.linkedId}`,
      method: 'post'
    }
  };
}
