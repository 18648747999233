// @ts-nocheck
import * as actionTypes from './actionTypes';
import * as R from 'ramda';

import {
  compose,
  onSuccess,
  onPending,
  createAPIStatusReducer
} from '~/reducerUtils';

const initialState = {
  searchTerm: '',
  searchPlayerStatus: null,
  searchPlayerResults: [],
  fetchPlayerDataStatus: null,
  playerData: null,
  fetchPaymentHistoryStatus: null,
  paymentHistory: null,
  updatePlayerInventoryStatus: null,
  markForAccountResetStatus: null,
  modifyTesterStatus: null,
  auditLog: {},
  fetchAuditLogStatus: null,
  // TODO: Remove this hack and come up with another solution to prevent player
  // data view components from mounting twice.
  apiStatus: { fetchPlayerStatus: 'pending' },
  cancelPlayerMigrationStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_PLAYER:
      return search(state, action);
    case actionTypes.FETCH_PLAYER_DATA:
      return fetchPlayerData(state, action);
    case actionTypes.UPDATE_PLAYER_INVENTORY:
      return updatePlayerInventory(state, action);
    case actionTypes.UPDATE_LEADERBOARD_NICKNAME:
      return updateLeaderboardNickname(state, action);
    case actionTypes.MARK_FOR_ACCOUNT_RESET:
      return markForAccountReset(state, action);
    case actionTypes.MARK_PLAYER_TESTER:
      return markAsTester(state, action);
    case actionTypes.MARK_PLAYER_NOT_TESTER:
      return markAsPlayer(state, action);
    case actionTypes.DETACH_SOCIAL_PROVIDER:
      return detachSocialProvider(state, action);
    case actionTypes.REMOVE_PLAYER_TEAM_BAN:
      return removeTeamBan(state, action);
    case actionTypes.FETCH_PLAYER_AUDIT_LOG_PAGE:
      return fetchPlayerAuditLogPage(state, action);
    case actionTypes.CANCEL_PLAYER_MIGRATION:
      return cancelPlayerMigration(state, action);
    default:
      return state;
  }
};

const search = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      lastSearchId: action.transactionId,
      searchPlayerStatus: 'pending'
    };
  } else if (action.error) {
    // Empty search causes error but should clear the results
    if (
      (action.meta.searchTerm === '' || action.status === 404) &&
      action.transactionId === state.lastSearchId
    ) {
      return {
        ...state,
        searchPlayerStatus: null,
        searchPlayerResults: []
      };
    } else {
      return {
        ...state,
        searchPlayerStatus: action.payload || 'Failed to search for player'
      };
    }
  } else {
    let searchPlayerResults = state.searchPlayerResults;
    if (action.transactionId === state.lastSearchId)
      searchPlayerResults = action.payload;
    return {
      ...state,
      searchPlayerStatus: null,
      searchPlayerResults
    };
  }
};

const fetchPlayerData = compose(
  createAPIStatusReducer('fetchPlayerStatus', 'Failed to fetch player data'),
  onPending((state, action) => ({
    ...state,
    playerData: action.payload
  })),
  onSuccess((state, action) => ({
    ...state,
    playerData: action.payload
  }))
);

const updatePlayerInventory = compose(
  createAPIStatusReducer(
    'updatePlayerInventoryStatus',
    'Failed to update player inventory'
  ),
  onSuccess((state, action) => ({
    ...state,
    playerData: action.payload
  }))
);

const updateLeaderboardNickname = compose(
  createAPIStatusReducer(
    'updateLeaderboardNicknameStatus',
    'Failed to update leaderboard nickname'
  ),
  onSuccess((state, action) => ({
    ...state,
    playerData: action.payload
  }))
);

const markForAccountReset = compose(
  createAPIStatusReducer(
    'markForAccountResetStatus',
    'Failed to mark account for reset'
  ),
  onSuccess((state, action) => ({
    ...state,
    playerData: action.payload
  }))
);

const markAsTester = compose(
  createAPIStatusReducer(
    'modifyTesterStatus',
    'Failed to mark account as tester'
  ),
  onSuccess((state, action) => ({
    ...state,
    playerData: {
      ...state.playerData,
      userProfile: {
        ...state.playerData.userProfile,
        ...action.payload
      }
    }
  }))
);

const markAsPlayer = compose(
  createAPIStatusReducer(
    'modifyTesterStatus',
    'Failed to mark account as player'
  ),
  onSuccess((state, action) => ({
    ...state,
    playerData: {
      ...state.playerData,
      userProfile: {
        ...state.playerData.userProfile,
        ...action.payload
      }
    }
  }))
);

const fetchPlayerAuditLogPage = compose(
  createAPIStatusReducer('fetchAuditLogStatus', 'Failed to fetch audit log'),
  onSuccess((state, action) => ({
    ...state,
    auditLog: {
      ...state.auditLog,
      [action.meta.seriouslyId]: {
        hasNext: action.payload.hasNext,
        nextOffset: action.payload.nextOffset,
        items: [
          ...R.pathOr(
            [],
            ['auditLog', action.meta.seriouslyId, 'items'],
            state
          ),
          ...action.payload.items
        ]
      }
    }
  }))
);

const detachSocialProvider = (state, action) => {
  if (!action.pending && !action.error) {
    return {
      ...state,
      playerData: {
        ...state.playerData,
        userProfile: {
          ...state.playerData.userProfile,
          ...action.payload
        }
      }
    };
  } else {
    return state;
  }
};

const removeTeamBan = (state, action) => {
  if (!action.pending && !action.error) {
    return {
      ...state,
      playerData: {
        ...state.playerData,
        ...action.payload
      }
    };
  } else {
    return state;
  }
};

const cancelPlayerMigration = compose(
  createAPIStatusReducer(
    'cancelPlayerMigrationStatus',
    'Failed to cancel player migration'
  ),
  onPending(state => ({
    ...state
  })),
  onSuccess(state => ({
    ...state,
    playerData: {
      ...state.playerData,
      playerMigration: null
    }
  }))
);
