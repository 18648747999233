import { NAME } from './constants';
import { NAME as parentName} from '../constants';

export const FETCH_DASHBOARDS = `${parentName}/${NAME}/FETCH_DASHBOARDS`;
export const CLEAR_DASHBOARDS = `${parentName}/${NAME}/CLEAR_DASHBOARDS`;
export const UPDATE_DASHBOARD = `${parentName}/${NAME}/UPDATE_DASHBOARD`;
export const DELETE_DASHBOARD = `${parentName}/${NAME}/DELETE_DASHBOARD`;


export const BATCH_UPDATE_PENDING = `${parentName}/${NAME}/BATCH_UPDATE_PENDING`; 
export const BATCH_UPDATE_COMPLETE = `${parentName}/${NAME}/BATCH_UPDATE_COMPLETE`;

