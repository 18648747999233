import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const GET_QUERY_FILTERS = `${parentName}/${NAME}/GET_QUERY_FILTERS`;
export const QUERY = `${parentName}/${NAME}/QUERY`;
export const GET_NEXT_QUERY_PAGE = `${parentName}/${NAME}/GET_NEXT_QUERY_PAGE`;
export const SHOW_MORE_CSV_RESULTS = `${parentName}/${NAME}/SHOW_MORE_CSV_RESULTS`;
export const UPDATE = `${parentName}/${NAME}/UPDATE`;
export const BATCH_APPLY = `${parentName}/${NAME}/BATCH_APPLY`;

export const UPDATE_FILTERS = `${parentName}/${NAME}/UPDATE_FILTERS`;
export const UPDATE_ORDERING = `${parentName}/${NAME}/UPDATE_ORDERING`;
export const UPDATE_SELECTION = `${parentName}/${NAME}/UPDATE_SELECTION`;
export const CSV_UPLOAD = `${parentName}/${NAME}/CSV_UPLOAD`;
export const TOGGLE_MODE = `${parentName}/${NAME}/TOGGLE_MODE`;
