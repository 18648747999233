import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';

export default function PrettyJson({ value, sortKeys = true }) {
  if (R.isNil(value)) return <pre />;
  const json = orderedJsonSerialize(value, sortKeys);
  return (
    <span className='unformatedJson'>
      <pre
        style={{ overflowX: 'auto' }}
        dangerouslySetInnerHTML={{ __html: syntaxHighlight(json) }}
      ></pre>
    </span>
  );
}

PrettyJson.propTypes = {
  value: PropTypes.object
};

function syntaxHighlight(json) {
  json = json
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function(match) {
      var cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    }
  );
}

/**
 *  A bit of a hacky way to create Json where the first level of variables are ordered alphabetically.
 */
const JSON_INDENTION = 4;
const orderedJsonSerialize = (obj, sortKeys) => {
  let keys = R.keys(obj);
  if (sortKeys) keys.sort();
  let json = '{\n';

  for (let i in keys) {
    let key = keys[i];
    let value = obj[key];
    if (value !== undefined) {
      let valueJson = getValueJson(value);
      json += `    "${key}": ${valueJson}\n`;
    }
  }

  return json + '}';
};

const getValueJson = value => {
  let str;
  if (
    Object.prototype.toString.call(value) === '[object Date]' &&
    isNaN(value.getTime())
  ) {
    str = '"[Invalid Date]"';
  } else {
    str = JSON.stringify(value, null, JSON_INDENTION);
  }
  let split = str.split('\n');
  let head = R.head(split);
  return (
    head +
    (head === '{' || head === '[' ? '\n' : '') +
    R.tail(split)
      .map(s => ' '.repeat(JSON_INDENTION) + s)
      .join('\n')
  );
};
