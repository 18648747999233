import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import R from 'ramda';
import TextField from '@material-ui/core/TextField';
import PrettyJson from './PrettyJson';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ErrorBox from './ErrorBox';
import FastForm from './FastForm';

const styles = {
  editable: {
    margin: '1em'
  }
};

class EditableJson extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    editDisabled: PropTypes.bool,
    onEditToggle: PropTypes.func,
    fastForm: PropTypes.object,
    hideUndefinedInFastForm: PropTypes.bool,
    skipDefaultValuesInFastForm: PropTypes.bool
  };

  state = {
    editMode: false,
    editedText: null,
    editFromFastForm: false,
    error: null
  };

  componentDidUpdate(prevProps) {
    if (this.props.data != prevProps.data) {
      this.setState(() => ({
        editMode: false,
        editedText: null,
        error: null
      }));
      this.props.onEditToggle(!this.state.editMode);
    }
  }

  handleEdit = evt => {
    try {
      const editedText = JSON.parse(evt.target.value);
      this.setState(() => ({
        editedText,
        error: null,
        editFromFastForm: false
      }));
    } catch (error) {
      this.setState(() => ({ error, editFromFastForm: false }));
    }
  };

  handleSave = () => {
    this.props.onSave(this.state.editedText);
  };

  handleToggleEditMode = () => {
    this.setState(prevState => ({
      editMode: !prevState.editMode,
      editedText: prevState.editMode ? null : this.state.editedText,
      error: null
    }));
    this.props.onEditToggle(!this.state.editMode);
  };

  handleFastFormEdit = editedText => {
    this.setState(() => ({ editedText, error: null, editFromFastForm: true }));
  };

  render() {
    const { data, editDisabled, classes, fastForm, hideUndefinedInFastForm, skipDefaultValuesInFastForm } = this.props;
    const { editMode, error, editedText, editFromFastForm } = this.state;
    if (R.isNil(data)) return <pre />;
    const currentData = editedText ? editedText : data;
    const json = JSON.stringify(currentData, null, '\t');
    let opts = {};
    if (editFromFastForm) opts['value'] = json;

    return (
      <div>
        {editMode && error && <ErrorBox error={error} />}
        <div className='col-xs-4'>
          <FormControlLabel
            control={
              <Switch
                checked={editMode}
                disabled={editDisabled}
                onChange={this.handleToggleEditMode}
                color='primary'
              />
            }
            label='Edit Mode'
          />
          {editMode && (
            <Button
              variant='contained'
              color='primary'
              disabled={
                editDisabled || this.props.isSaving || !editedText || error
              }
              onClick={this.handleSave}
            >
              Save
            </Button>
          )}
        </div>
        {fastForm && (
          <FastForm
            value={currentData}
            onChange={this.handleFastFormEdit}
            hideUndefined={hideUndefinedInFastForm}
            skipDefaultValuesOnMount={skipDefaultValuesInFastForm}
            disabled={!editMode}
          >
            {fastForm}
          </FastForm>
        )}
        {!editMode && <PrettyJson value={data} sortKeys={false} />}
        {editMode && (
          <TextField
            className={classes.editable}
            fullWidth={true}
            variant='outlined'
            multiline={true}
            onChange={this.handleEdit}
            {...opts}
            defaultValue={json}
          />
        )}
      </div>
    );
  }
}

export default connect()(withStyles(styles)(EditableJson));
