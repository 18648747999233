import * as actionTypes from './actionTypes';
import { API } from '~/api';
import Q from 'q';
import * as R from 'ramda';

export function addTag(gameId, tagName) {
  return {
    type: actionTypes.ADD_USER_TAG,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/usergrouptag/create/${tagName}`,
      method: 'post'
    }
  };
}

export function deleteTag(gameId, userGroupTag) {
  const { linkedId } = userGroupTag;
  return {
    type: actionTypes.DELETE_USER_TAG,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/usergrouptag/delete/${linkedId}`,
      method: 'post'
    },
    meta: { userGroupTag }
  };
}

export const purgeLatestUnpublishedUserGroupTag = (gameId, userGroupTag) => {
  const { linkedId } = userGroupTag;
  return {
    type: actionTypes.PURGE_LATEST_UNPUBLISHED_USER_GROUP_TAG,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/usergrouptag/purge_unpublished/${linkedId}`,
      method: 'post'
    },
    meta: { userGroupTag }
  };
};
