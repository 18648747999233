import * as actionTypes from './actionTypes';
import R from 'ramda';
import dayjs from '~/dayjs';
import { gameIds } from '../constants';
import * as receiptParser from './components/receiptParser';



let initialEntities = R.reduce(
  (obj, key) => {
    obj[key] = [];
    return obj;
  },
  {},
  gameIds
);

const initialState = {
  entities: initialEntities,
  getStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    default:
      return state;
  }
};

const get = (state, action) => {
  const { seriouslyId, gameId } = action.meta;
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: action.payload || `Failed to get receipts for ${seriouslyId}`
    };
  } else {
    let {
      receipts,
      oldReceipts,
      relatedReceipts,
      oldRelatedReceipts
    } = action.payload;
    const allReceipts = [
      ...unifyReceiptFormat(receipts || [], false, false, seriouslyId),
      ...unifyReceiptFormat(oldReceipts || [], true, false, seriouslyId),
      ...unifyReceiptFormat(relatedReceipts || [], false, true, seriouslyId),
      ...unifyReceiptFormat(oldRelatedReceipts || [], true, true, seriouslyId)
    ];
    return {
      ...state,
      getStatus: null,
      entities: {
        ...state.entities,
        [gameId]: R.sort(
          (receipt1, receipt2) =>
            dayjs.utc(receipt1.timestamp).isBefore(receipt2.timestamp)
              ? 1
              : -1,
          allReceipts
        )
      }
    };
  }
};

function unifyReceiptFormat(receipts, isOld, isRelated, seriouslyId) {
  return [
    ...receipts.map(r => {
      r = R.assoc('oldFormat', isOld, r);
      r = R.assoc('relatedReceipt', isRelated, r);

      r = R.assoc('productId', receiptParser.getProductId(r, isOld), r);
      const status = receiptParser.getStatus(r, isOld);
      r = R.assoc('status', status.status, r);
      r = R.assoc('validity', status.validity, r);
      if (receiptParser.isGPReceipt(r, isOld))
        r = R.assoc('orderId', receiptParser.getOrderId(r, isOld), r);

      if (isOld) {
        r = R.assoc('timestamp', r.createdAt, r);
        r = R.assoc('platform', r.store.toLowerCase(), r);
      }
      if (isRelated) {
        r = R.assoc('platform', r.platform.toLowerCase(), r);
        r = R.assoc('relatedSeriouslyId', r.seriouslyId, r);
        r = R.assoc('seriouslyId', seriouslyId, r);
      }
      return r;
    })
  ];
}
