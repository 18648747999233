import * as actionTypes from './actionTypes';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  createFullStateResponseReducer,
  sortDiffEntitySet
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_DUMP:
      return reduceExportDump(state, action);
    case actionTypes.IMPORT_DUMP:
      return databaseImport(state, action);
    case actionTypes.CLEAR_EXPORT_URL:
      return { ...state, exportedDumpUrl: null };

    case actionTypes.ACTIVATE_DATABASE:
      return activateDatabase(state, action);
    case actionTypes.DIFF_DATABASE:
      return diffDatabase(state, action);
    case actionTypes.DELETE_DATABASE:
      return deleteDatabase(state, action);
    default:
      return state;
  }
};

const reduceExportDump = compose(
  createAPIStatusReducer('databaseExport', (state, action) => {
    const error = R.path(['payload', 'error'], action);
    return 'Failed to export database' + (error ? `: ${error}` : '');
  }),
  onSuccess((state, action) => ({
    ...state,
    exportedDumpUrl: action.payload.url
  }))
);

const databaseImport = compose(
  createAPIStatusReducer('databaseImport', (state, action) => {
    const error = R.path(['payload', 'error'], action);
    return 'Failed to import database' + (error ? `: ${error}` : '');
  }),
  createFullStateResponseReducer()
);

const activateDatabase = compose(
  createUIBlockingActionReducer('Failed to activate database'),
  createFullStateResponseReducer()
);

const diffDatabase = compose(
  createAPIStatusReducer('databaseDiffGetStatus', 'Failed to diff database'),
  onSuccess((state, action) => ({
    ...state,
    databaseDiff: sortDiffEntitySet(action.payload.diff)
  }))
);

const deleteDatabase = compose(
    createUIBlockingActionReducer('Failed to delete database'),
    createFullStateResponseReducer()
);