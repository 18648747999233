import React, { Component } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { TextField, Button } from '@material-ui/core';

class FilePicker extends Component {
  static propTypes = {
    onFileSelect: PropTypes.func.isRequired,
    hintText: PropTypes.string,
    disabled: PropTypes.bool,
    accept: PropTypes.string
  };

  static defaultProps = {
    accept: '*/*'
  };

  constructor(props) {
    super(props);

    this.state = {
      fileHover: false,
      selectedFiles: [],
      hiddenInput: null
    };
  }

  selectFiles = files => {
    const loadedFiles = [];
    var self = this;
    for (var i = 0; i < files.length; i++) {
      var reader = new FileReader();
      reader.onload = (file =>
        function(e) {
          loadedFiles.push({
            data: e.target.result,
            name: file.name
          });
          if (loadedFiles.length === files.length)
            self.filesLoaded(loadedFiles);
        })(files[i]);
      reader.readAsText(files[i]);
    }
  };

  filesLoaded = files => {
    this.props.onFileSelect(files);
    this.setState({
      selectedFiles: files
    });
  };

  handleBrowse = () => {
    this.state.hiddenInput.click();
  };

  hiddenInputRef = element => {
    if (element != null) {
      this.setState({
        hiddenInput: element
      });
      var self = this;
      element.onchange = function() {
        self.selectFiles(this.files);
      };
    }
  };

  handleDragOver = evt => {
    evt.stopPropagation();
    evt.preventDefault();

    if (!this.state.fileHover) this.setState({ fileHover: true });

    evt.dataTransfer.dropEffect = 'copy';
  };

  handleDragLeave = () => {
    this.setState({ fileHover: false });
  };

  handleDrop = evt => {
    evt.stopPropagation();
    evt.preventDefault();
    this.setState({ fileHover: false });
    this.selectFiles(evt.dataTransfer.files);
  };

  render() {
    const { fileHover, selectedFiles } = this.state;
    const { accept, label } = this.props;
    var hintText = this.props.hintText || 'Select file...';
    if (selectedFiles.length > 0)
      hintText = selectedFiles.map(R.prop('name')).join(', ');
    return (
      <div
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
      >
        <input
          ref={this.hiddenInputRef}
          type='file'
          id='file'
          accept={accept}
          style={{ display: 'none' }}
        />
        <TextField
          name='fileField'
          label={label}
          variant='outlined'
          value={fileHover ? 'Drop file to select!' : hintText}
          disabled={true}
          InputProps={{
            endAdornment: (
              <Button color='primary' onClick={this.handleBrowse}>
                Select
              </Button>
            )
          }}
        />
      </div>
    );
  }
}

export default FilePicker;
