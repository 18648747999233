import PropTypes from 'prop-types';
import React, { Component } from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';

class ConfirmationDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    okOnly: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    captchaText: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      captchaInput: ''
    };
  }

  handleSubmit = () => {
    this.props.onSubmit();
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { captchaText } = this.props;
    const { captchaInput } = this.state;

    var actions;
    if (this.props.okOnly) {
      actions = [
        <FlatButton
          label='OK'
          primary={true}
          keyboardFocused={true}
          onClick={this.handleCancel}
        />
      ];
    } else {
      actions = [
        <FlatButton
          label='Cancel'
          primary={true}
          onClick={this.handleCancel}
        />,
        <FlatButton
          label='OK'
          primary={true}
          disabled={captchaText && captchaText !== captchaInput}
          keyboardFocused={true}
          onClick={this.handleSubmit}
        />
      ];
    }

    return (
      <Dialog
        title={this.props.title}
        actions={actions}
        modal={false}
        open={this.props.open}
        onRequestClose={this.handleClose}
      >
        {this.props.text}
        {captchaText && (
          <div>
            <TextField
              fullWidth
              floatingLabelText={`Write '${captchaText}' to confirm`}
              onChange={(e, value) => this.setState({ captchaInput: value })}
            />
          </div>
        )}
      </Dialog>
    );
  }
}

export default ConfirmationDialog;
