import React, { FC } from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';

const SeriouslyIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 -50 175 430" {...props}>
      <path d="m 76.179369,118.54175 c 0,-28.424183 56.843081,-53.432599 90.188521,-62.909974 0,0 -9.31863,67.373504 44.04254,67.373504 -3.93964,-56.594379 35.15784,-53.191838 35.15784,-94.278983 0,-23.8707075 -21.21959,-34.4831445 -51.15719,-34.4831445 -73.89284,0 -201.9802728,63.6640405 -201.9802728,148.5476675 0,76.93025 128.4631428,45.85229 128.4631428,75.79254 0,12.88521 -32.588728,25.00842 -69.349935,25.00842 -35.999208,0 -75.789895,-11.7475 -91.70458,-46.98736 -17.81175,10.23144 -29.1809,30.69432 -29.1809,50.39784 0,49.64377 65.9368142,59.49685 100.800959,59.49685 82.232496,0 164.465006,-51.15719 164.465006,-98.14983 0.003,-62.14798 -119.745131,-49.64113 -119.745131,-89.80753 z"/>
    </SvgIcon>
  );
};

export default SeriouslyIcon;