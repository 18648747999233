import React, { FC, HTMLProps } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Title from './Title';

interface TitleWithDividerProps {
  icon?: React.ReactNode;
  dividerTopMargin?: number;
  dividerBottomMargin?: number;
  topMargin?: number;
  bottomMargin?: number;
}

const TitleWithDivider: FC<TitleWithDividerProps & HTMLProps<HTMLDivElement>> = ({
  className,
  icon,
  dividerTopMargin = 0.6,
  dividerBottomMargin = 0.6,
  topMargin = 0,
  bottomMargin = 0,
  children,
  ...rest
}) => {
  const classes = makeStyles({
    title: {
      margin: `${topMargin.toString()}rem 0rem ${bottomMargin.toString()}rem`
    },
    divider: {
      margin: `${dividerTopMargin.toString()}rem 0rem ${dividerBottomMargin.toString()}rem 0rem`
    }
  })()

  return (
    <div {...rest} className={classNames(classes.title, className)}>
      <Title icon={icon}>{children}</Title>
      <Divider className={classes.divider} />
    </div>
  );
}

export default TitleWithDivider;