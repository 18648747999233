export const NAME = 'susan';
export { NAME as GAME_ID } from '../constants';

const autocompleteType = {
  array: { type: 'array' },
  string: { type: 'string' },
  number: { type: 'number' },
  float: { type: 'float' },
  bool: { type: 'boolean' },
  object: { type: 'object' }
};

export const autocomplete = {
  craftingInventory: {
    ClaimedCategoryIds: autocompleteType.array,
    craftingVariantName: autocompleteType.string,
    ftueProgress: autocompleteType.number,
    hasClaimedFtueChest: autocompleteType.bool,
    hasCraftedFtueRecipe: autocompleteType.bool,
    hasSeenFtueChestDialogue: autocompleteType.bool,
    hasSeenFtueRecipeDialogue: autocompleteType.bool,
    hasSeenFtueRecipePopupDialogue: autocompleteType.bool,
    hasSeenFtueRecipeRewardDialogue: autocompleteType.bool,
    hasSeenInitialFtueInfo: autocompleteType.bool,
    ingredients: autocompleteType.array,
    lastSeenCraftingEventId: autocompleteType.string,
    lastTimedRewardClaimTime: autocompleteType.string,
    previousEventData: autocompleteType.object,
    results: autocompleteType.array
  },
  teamsInventory: {
    currentTeamId: autocompleteType.string,
    hasEverJoinedAnyTeam: autocompleteType.bool,
    hasSeenMainHubTutorial: autocompleteType.bool,
    lastSeenChatEventTimestamp: autocompleteType.string,
    lastSeenTeamChestPopupEndDate: autocompleteType.string,
    lastSeenTeamChestTier: autocompleteType.number,
    teamIdOfLastSeenChat: autocompleteType.string
  },
  userInventory: {
    coins: autocompleteType.number,
    crossPromotionId: autocompleteType.string,
    currentLevel: autocompleteType.number,
    currentStarChestGoal: autocompleteType.number,
    currentStarChestIndex: autocompleteType.number,
    currentStarCount: autocompleteType.number,
    endGameLevel: autocompleteType.number,
    fiends: autocompleteType.array,
    hardRemindersToGoOnlineSeen: autocompleteType.number,
    hasChangedToCustomName: autocompleteType.bool,
    hasClaimedInstagramBonus: autocompleteType.bool,
    hasClaimedShareWithFriendsBonus: autocompleteType.bool,
    hasClaimedTwitterBonus: autocompleteType.bool,
    hasFacebookName: autocompleteType.bool,
    hasSeenWinStreakStatusAfterLastReset: autocompleteType.bool,
    inGameBoosterCounts: autocompleteType.array,
    infiniteLivesEndTime: autocompleteType.string,
    isPlayingLevel: autocompleteType.bool,
    lastFiendUnlockedAtLevel: autocompleteType.number,
    lastLevelChestOpened: autocompleteType.number,
    lastLivesRefillStartTime: autocompleteType.string,
    lastRevealedLevel: autocompleteType.number,
    lastShownWinStreak: autocompleteType.number,
    lastSoftReminderToGoOnlineShownLevel: autocompleteType.number,
    lives: autocompleteType.number,
    preGameBoosterCounts: autocompleteType.array,
    softRemindersToGoOnlineSeen: autocompleteType.number,
    totalXp: autocompleteType.number,
    uaSurveyAnswer: autocompleteType.number,
    uaSurveyAnswerId: autocompleteType.string,
    whatsNewId: autocompleteType.string,
    winStreak: autocompleteType.number,
    winStreakEndTime: autocompleteType.string
  },
  inventory: {
    userInventory: autocompleteType.object,
    craftingInventory: autocompleteType.object,
    teamsInventory: autocompleteType.object
  },
  analytics: {
    bombs_collected: autocompleteType.number,
    sets_completed: autocompleteType.float,
    coins_sunk_per_active_day: autocompleteType.float,
    level_wins_per_day: autocompleteType.float,
    days_since_install: autocompleteType.number,
    story_progress: autocompleteType.number,
    end_game_progress: autocompleteType.number
  },
  abTestTags: autocompleteType.array
};
