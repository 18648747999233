import * as actionTypes from './actionTypes';
import R from 'ramda';
import { ELASTICSEARCH_DOC_TYPE } from './constants'

const initialState = {
    getStatus: null,
    processors: [],

    fetchDashboardsStatus: null,

    sectionDashboardsStatus: null,
    sectionDashboards: [],
}

export default (state = initialState, action) => {
    switch(action.type) {
        // DASHBOARDS
        case actionTypes.CLEAR_DASHBOARDS: return {...state, sectionDashboards: initialState.sectionDashboards};
        case actionTypes.FETCH_DASHBOARDS: return reduceFetchDashboards(state, action);
        case actionTypes.UPDATE_DASHBOARD: return reduceUpdateDashboard(state, action);
        case actionTypes.DELETE_DASHBOARD: return reduceDeleteDashboard(state, action);
        case actionTypes.BATCH_UPDATE_PENDING: return batchUpdatePending(state);
        case actionTypes.BATCH_UPDATE_COMPLETE: return batchUpdateCompleted(state, action);

        default: return state;
    }
}

const reduceFetchDashboards = (state, action) => {

    if (action.pending) {
        return {...state, fetchDashboardsStatus: 'pending'}
    }
    else if (action.error) {
        return {...state, fetchDashboardsStatus: action.payload || 'Failed to fetch dashboards. Refreshing the page may fix this.'}
    }
    else {
        return {...state, fetchDashboardsStatus: null,
            sectionDashboards: (action.payload || []).map(dashboard => R.assocPath(['_source', 'id'], dashboard._id, dashboard))
        }
    }
}

const reduceUpdateDashboard = (state, action) => {

    if (action.pending) {
        return {...state, sectionDashboardsStatus: 'pending'}
    }
    else if (action.error) {
        return {...state, sectionDashboardsStatus: 'failed: Creating Dashboard'}
    }
    else {
        const {dataIndex, dashboardData, id} = action.meta;

        let sectionDashboards = R.clone(state.sectionDashboards);
        const existingDashboardIndex = R.findIndex(dashboard => dashboard._id === id, sectionDashboards);
        const isNewDashboard = existingDashboardIndex === -1;

        if (isNewDashboard) {
            const newDashboardData = {
                _id: id,
                _index: dataIndex,
                _type: ELASTICSEARCH_DOC_TYPE,
                _source: dashboardData
            }
            sectionDashboards = R.append(newDashboardData, sectionDashboards);
        }
        else {
            sectionDashboards = R.assocPath([existingDashboardIndex, '_source'], dashboardData, sectionDashboards);
        }

        return {...state, sectionDashboardsStatus: null,
            sectionDashboards
        }
    }
}

const reduceDeleteDashboard = (state, action) => {
    const {id} = action.meta;

    if (action.pending) {
        return {...state, sectionDashboardsStatus: 'pending'}
    }
    else if (action.error) {
        return {...state, sectionDashboardsStatus: 'failed: Deleting Dashboard'}
    }
    else {
        return {...state, sectionDashboardsStatus: null,
            sectionDashboards: R.reject(dashboard => dashboard._id === id, state.sectionDashboards)
        }
    }
}

const batchUpdatePending = (state) => ({
    ...state,
    batchUpdatePending: true
})

const batchUpdateCompleted = (state, action) => ({
    ...state,
    batchUpdatePending: false,
    batchUpdateError: action.payload.error
})