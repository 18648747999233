import React, { Component } from 'react';
import IconButton from 'material-ui/IconButton';
import ActionHelpOutline from 'material-ui/svg-icons/action/help-outline';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

/**
 *
 *  A info button, which when hovered shows the user a message tooltip.
 *
 */

import PropTypes from 'prop-types';

import R from 'ramda';

class QuestionMark extends Component {
  static propTypes = {
    message: PropTypes.node.isRequired,

    // If popup = true, then this is used as the dialog title.
    title: PropTypes.string,
    // If true, a dialog is used to show the message instead of a tooltip
    popup: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false
    };
  }

  toggleOpen = () =>
    this.setState(({ dialogOpen }) => ({ dialogOpen: !dialogOpen }));

  render() {
    const { popup, title } = this.props;
    const others = R.omit(['popup', 'message', 'title'], this.props);
    let message = this.props.message;
    if (typeof message === 'string') {
      message = message.split('\n').map((item, key) => (
        <span key={key}>
          {item}
          <br />
        </span>
      ));
    }
    if (!popup) {
      return (
        <IconButton {...others} tooltip={message}>
          <ActionHelpOutline />
        </IconButton>
      );
    } else {
      return (
        <span>
          <IconButton {...others} onClick={this.toggleOpen}>
            <ActionHelpOutline />
          </IconButton>
          <Dialog
            title={title}
            actions={[]}
            modal={false}
            open={this.state.dialogOpen}
            onRequestClose={this.toggleOpen}
          >
            {message}
          </Dialog>
        </span>
      );
    }
  }
}

export default QuestionMark;
