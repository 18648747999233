import * as Colors from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';
import Spacing from 'material-ui/styles/spacing';
import zIndex from 'material-ui/styles/zIndex';

// Theme used by Material V0.*
let theme = {
  spacing: Spacing,
  zIndex: zIndex,
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary1Color: '#00AEFF', // Base (Logo color)
    primary2Color: '#0CDCE8', // Lighter base
    primary3Color: fade(Colors.darkBlack, 0.25),
    accent1Color: '#04CAE8',
    accent2Color: Colors.grey100,
    accent3Color: Colors.grey500,
    textColor: Colors.darkBlack,
    alternateTextColor: Colors.white,
    canvasColor: Colors.white,
    borderColor: Colors.grey300,
    disabledColor: fade(Colors.darkBlack, 0.6),
    pickerHeaderColor: '#0CDCE8'
  },
  slider: {
    trackColor: Colors.grey400
  }
};

// Theme used by Material V1
let theme1 = {
  fontFamily: 'Roboto, sans-serif',
  palette: {
    primary: {
      main: '#00AEFF',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#0CDCE8',
      contrastText: '#ffffff'
    }
  },
  typography: {
    fontSize: 12,
    title: {
      fontWeight: 400
    }
  }
};

if (DASHBOARD_ENV === 'staging') {
  theme = {
    spacing: Spacing,
    zIndex: zIndex,
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary1Color: '#7924c9', // Base (Logo color)
      primary2Color: '#E8950C', // Lighter base
      primary3Color: fade(Colors.darkBlack, 0.25),
      accent1Color: '#FFBC0D',
      accent2Color: Colors.grey100,
      accent3Color: Colors.grey500,
      textColor: Colors.darkBlack,
      alternateTextColor: Colors.white,
      canvasColor: Colors.white,
      borderColor: Colors.grey300,
      disabledColor: fade(Colors.darkBlack, 0.6),
      pickerHeaderColor: '#00C6FF'
    },
    slider: {
      trackColor: Colors.grey400
    }
  };
  theme1 = {
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary: {
        main: '#7924c9',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#E8950C',
        contrastText: '#ffffff'
      }
    },
    typography: {
      fontSize: 12,
      title: {
        fontWeight: 400
      }
    }
  };
}

if (DASHBOARD_ENV === 'qa') {
  theme = {
    spacing: Spacing,
    zIndex: zIndex,
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary1Color: '#87e6a0', // Base (Logo color)
      primary2Color: '#E8950C', // Lighter base
      primary3Color: fade(Colors.darkBlack, 0.25),
      accent1Color: '#FFBC0D',
      accent2Color: Colors.grey100,
      accent3Color: Colors.grey500,
      textColor: Colors.darkBlack,
      alternateTextColor: Colors.white,
      canvasColor: Colors.white,
      borderColor: Colors.grey300,
      disabledColor: fade(Colors.darkBlack, 0.6),
      pickerHeaderColor: '#00C6FF'
    },
    slider: {
      trackColor: Colors.grey400
    }
  };
  theme1 = {
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary: {
        main: '#87e6a0',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#E8950C',
        contrastText: '#ffffff'
      }
    },
    typography: {
      fontSize: 12,
      title: {
        fontWeight: 400
      }
    }
  };
}

if (DASHBOARD_ENV === 'production') {
  theme = {
    spacing: Spacing,
    zIndex: zIndex,
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary1Color: '#ff7e00', // Base (Logo color)
      primary2Color: '#E8950C', // Lighter base
      primary3Color: fade(Colors.darkBlack, 0.25),
      accent1Color: '#FFBC0D',
      accent2Color: Colors.grey100,
      accent3Color: Colors.grey500,
      textColor: Colors.darkBlack,
      alternateTextColor: Colors.white,
      canvasColor: Colors.white,
      borderColor: Colors.grey300,
      disabledColor: fade(Colors.darkBlack, 0.6),
      pickerHeaderColor: '#00C6FF'
    },
    slider: {
      trackColor: Colors.grey400
    }
  };
  theme1 = {
    fontFamily: 'Roboto, sans-serif',
    palette: {
      primary: {
        main: '#ff7e00',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#E8950C',
        contrastText: '#ffffff'
      }
    },
    typography: {
      fontSize: 12,
      title: {
        fontWeight: 400
      }
    }
  };
}

export const themeV1 = theme1;

export default theme;
