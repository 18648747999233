import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ExpandMore from 'material-ui/svg-icons/navigation/expand-more';
import IconButton from 'material-ui/IconButton';

class Collapsible extends Component {
  static propTypes = {
    expanded: PropTypes.bool.isRequired,
    onToggle: PropTypes.func,
    title: PropTypes.string.isRequired
  };

  handleExpand() {
    if (this.props.onToggle) this.props.onToggle();
  }

  render() {
    const { expanded, title, children } = this.props;
    return (
      <div style={containerStyle}>
        <div style={headerStyle} onClick={::this.handleExpand}>
          <h5 style={titleStyle}> {title} </h5>
          <IconButton
            tooltip=''
            className='pullright'
            iconStyle={expandIconSyle}
            style={expanded ? expandButtonStyleOpen : expandButtonStyle}
          >
            <ExpandMore />
          </IconButton>
        </div>
        {expanded && <div style={contentStyle}>{children}</div>}
      </div>
    );
  }
}

export default Collapsible;

const containerStyle = {
  width: '100%',
  border: 'solid 1px #CCCCCC',
  borderRadius: '15px'
};

const headerStyle = {
  width: '100%',
  height: '64px',
  cursor: 'pointer'
};

const titleStyle = {
  padding: '20px 10px',
  float: 'left',
  lineHeight: '100%',
  marginBottom: 0
};

const contentStyle = {};

const expandIconSyle = {
  width: 48,
  height: 48
};
const expandButtonStyle = {
  width: 64,
  height: 64,
  padding: 0
};

const expandButtonStyleOpen = {
  width: 64,
  height: 64,
  padding: 0,
  transform: 'rotate(-180deg)'
};
