import { combineReducers } from 'redux';
import * as abTestingConstants from './abTesting/constants';
import abTestingReducer from './abTesting/reducer';
import * as exampleModuleConstants from './exampleModule/constants';
import exampleModuleReducer from './exampleModule/reducer';
import * as eventStreamsConstants from './eventStreams/constants';
import eventStreamsReducer from './eventStreams/reducer';
import * as playerQueryConstants from './playerQuery/constants';
import playerQueryReducer from './playerQuery/reducer';
import * as buildArchiverConstants from './buildArchiver/constants';
import buildArchiverReducer from './buildArchiver/reducer';

export default combineReducers({
  [abTestingConstants.NAME]: abTestingReducer,
  [exampleModuleConstants.NAME]: exampleModuleReducer,
  [eventStreamsConstants.NAME]: eventStreamsReducer,
  [playerQueryConstants.NAME]: playerQueryReducer,
  [buildArchiverConstants.NAME]: buildArchiverReducer
});
