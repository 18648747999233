import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JSONEditor from 'jsoneditor';
import { withStyles, createStyles } from '@material-ui/core';

const styles = createStyles({
  root: {
    height: '100%',
    minHeight: 'calc(90vh - 250px)',
    position: 'relative'
  },
  editor: {
    position: 'absolute',
    top: '0',
    left: 0,
    right: 0,
    bottom: 0
  }
});

class JsonEditor extends Component {
  static propTypes = {
    data: PropTypes.object,
    onMounted: PropTypes.func,
    onChangeJson: PropTypes.func,
    onValidationError: PropTypes.func,
    options: PropTypes.object,
    style: PropTypes.object
  };

  componentDidMount() {
    const { onValidationError, onChange, options, data } = this.props;

    const jsonEditorOptions = {
      mode: 'code',
      mainMenuBar: false,
      onValidationError,
      onChange: onChange,
      ...(options || {})
    };

    this.jsonEditor = new JSONEditor(this.container, jsonEditorOptions, data);

    // Bit iffy stuff but done to pass heavy re-renders on the jsonEditor
    this.props.onMounted(this.jsonEditor);
  }

  componentWillUnmount() {
    if (this.jsonEditor) {
      this.jsonEditor.destroy();
    }
  }

  render() {
    const { classes, style } = this.props;
    return (
      <div className={classes.root} style={style}>
        <div className={classes.editor} ref={elem => (this.container = elem)} />
      </div>
    );
  }
}

export default withStyles(styles)(JsonEditor);
