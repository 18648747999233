import React, { Component } from 'react';
import {
  basePropTypes,
  getValue,
  setValue,
  getTitle,
  componentMounts,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import R from 'ramda';

const styles = {
  container: {
    width: '100%',
    paddingTop: '1.5em',
    paddingBottom: '1.5em'
  },
  labelStyle: { width: 'auto' },
  iconStyle: { marginRight: 0 },
  title: {
    marginLeft: '1em'
  }
};

class BitToggleField extends Component {
  static propTypes = {
    // How many bits can be toggled.
    bitCount: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),

    // Offset for bits, if in code 0 means that user has first item, set the offset to 0. If first is at 1, set offset to 1. Defaults to 0.
    indexStartsFrom: PropTypes.number,

    // Offest for visualisation, if in code 0 means that user has first item, set the offset to 0. If first is at 1, set offset to 1. Defaults to 1.
    labelStartsFrom: PropTypes.number,

    tabable: PropTypes.bool, // If false, the checkboxes tabIndex=-1

    disableBitsUntil: PropTypes.number, //disable some of the bits, used when we want the index to start from 0 but

    // If present, this function will be called to get  values for the drop down menu instead of their true values (optional)
    itemAliases: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),

    ...basePropTypes
  };

  static defaultProps = {
    disabled: false,
    bitCount: 5,
    indexStartsFrom: 0,
    labelStartsFrom: 1,
    defaultValue: 0,
    disableBitsUntil: -1
  };

  constructor(props) {
    assertProps(props, BitToggleField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnCheck = event => {
    const index = parseInt(event.target.name);
    let value = getValue(this.props);
    // Toggle bit at index
    setValue(this.props, value ^ (1 << index));
  };

  getBitCount() {
    const { bitCount } = this.props;
    if (R.isNil(bitCount) || typeof bitCount === 'number') {
      return bitCount;
    } else {
      return bitCount(this.props);
    }
  }

  getItemAliases() {
    const { itemAliases } = this.props;
    if (R.isNil(itemAliases) || Array.isArray(itemAliases)) {
      return itemAliases;
    } else {
      return itemAliases(this.props);
    }
  }

  render() {
    if (this.props.visible === false) return null;
    const {
      classes,
      disabled,
      indexStartsFrom,
      tabable,
      disableBitsUntil,
      labelStartsFrom
    } = this.props;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    let value = getValue(this.props);
    if (value < 0) {
      //if game users -1 as a default value, that'll completely mess up the bitwise operations. Just ignore the whole field and let the game initiate it to zero.
      console.error(
        'Value ' +
          value +
          ' given to BitToggleField "' +
          getTitle(this.props) +
          '" is not valid for binary operations. Hiding the field.'
      );
      return null;
    }

    value = R.range(0, this.getBitCount() + indexStartsFrom).map(
      (_, i) => (value & (1 << i)) > 0
    );

    const aliases = this.getItemAliases();

    return (
      <div style={styles.container}>
        <Typography className={classes.title} variant='caption'>
          {getTitle(this.props)}
        </Typography>
        <div className='col-xs'>
          {value.map((isOn, index) => {
            if (index < indexStartsFrom) return null;
            let labelIndex = index;
            if (indexStartsFrom == 0) labelIndex = index + labelStartsFrom;

            return (
              <label key={index}>
                <input
                  type='checkbox'
                  name={index}
                  onChange={this.handleOnCheck}
                  checked={isOn}
                  tabIndex={tabable === false ? -1 : 0}
                  disabled={
                    disabled ||
                    (!R.isNil(disableBitsUntil) && index < disableBitsUntil)
                  }
                />
                {aliases != null ? aliases[index] : `#${labelIndex}`}
              </label>
            );
          })}
        </div>
      </div>
    );
  }
}

export default wrap(withStyles(styles)(BitToggleField));
