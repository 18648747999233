import R from 'ramda';
import React from 'react';

export const NAME = 'accessAndErasure';

// Keep in sync with GDPR service server
export const DAYS_BEFORE_ANONYMIZATION = 60;

export const IOS_AD_ID = 'iosAdId';
export const GOOGLE_AD_ID = 'googleAdId';
export const AMAZON_AD_ID = 'amazonAdId';
export const ANDROID_ID = 'androidId';
export const TRACKING_ID = 'trackingId';
export const SERIOUSLY_ID = 'seriouslyId';
export const SUPPORT_ID = 'supportId';
export const PLAYER_ID = 'playerId';
export const SOCIAL_ID = 'socialId';
export const EMAIL = 'email';
export const FULL_NAME = 'full_name';

export const TYPE_TO_NAME = {
    [IOS_AD_ID]: 'iOS IDFA',
    [GOOGLE_AD_ID]: 'Google ID',
    [AMAZON_AD_ID]: 'Amazon ID',
    [ANDROID_ID]: 'Android ID',
    [TRACKING_ID]: 'Tracking ID',
    [SERIOUSLY_ID]: 'Seriously ID',
    [SUPPORT_ID]: 'Support ID',
    [PLAYER_ID]: 'Player ID',
    [SOCIAL_ID]: 'Social ID',
    [EMAIL]: 'Email',
    [FULL_NAME]: 'Full Name'
}


export const ALL_TYPES = R.keys(TYPE_TO_NAME);

export const SEARCH_RESULT_SCHEMA = [
    {
        selector: (_, i) => (i + 1) + '.',
        title: '#',
        width: '25px'
    },
    {
        key: 'target',
        title: 'Game/Service',
        width: '75px',
        multiline: true
    },
    {
        key: ['commonFields', 'seriouslyId'],
        title: TYPE_TO_NAME[SERIOUSLY_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'playerId'],
        title: TYPE_TO_NAME[PLAYER_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'trackingId'],
        title: TYPE_TO_NAME[TRACKING_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'iosAdId'],
        title: TYPE_TO_NAME[IOS_AD_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'googleAdId'],
        title: TYPE_TO_NAME[GOOGLE_AD_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'androidId'],
        title: TYPE_TO_NAME[ANDROID_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'amazonAdId'],
        title: TYPE_TO_NAME[AMAZON_AD_ID],
        multiline: true
    },
    {
        key: ['commonFields', 'full_name'],
        title: TYPE_TO_NAME[FULL_NAME],
        multiline: true
    },
    {
        key: ['commonFields', 'email'],
        title: TYPE_TO_NAME[EMAIL],
        multiline: true
    },
    {
        key: 'url',
        title: 'Url',
        format: (url) => !R.isNil(url) ? (<a href={url} target='_blank'>Link</a>) : null,
        multiline: true,
        ignoreClick: true
    }
];