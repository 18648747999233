import React from 'react';
import {
  withStyles,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const NO_DATA = 'Value unknown!'

const DiffItem = ({
  classes,
  diff,
  mapToPrettyNameOrDefault
}) => {
  const path = R.pathOr([NO_DATA], ['path'], diff);
  const prevValue = R.pathOr(NO_DATA, ['prevValue'], diff).toString();
  const newValue = R.pathOr(NO_DATA, ['newValue'], diff).toString();
  const fieldLabel = !R.isNil(mapToPrettyNameOrDefault) ? mapToPrettyNameOrDefault(path) : path.join('.');

  return (
    <TableRow>
      <TableCell>{fieldLabel}</TableCell>
      <TableCell><span className={classes.oldValue}>{prevValue}</span></TableCell>
      <TableCell><span className={classes.newValue}>{newValue}</span></TableCell>
    </TableRow>
  );
};

const DiffConfirmation = ({
  classes,
  diffs,
  mapToPrettyNameOrDefault,
  open,
  onSubmit,
  onCancel,
  ...rest
}) => {
  const mappedDiffs = diffs.map((diff, index) => (
    <DiffItem
      key={index}
      classes={classes}
      diff={diff}
      mapToPrettyNameOrDefault={mapToPrettyNameOrDefault}
    />
  ));

  return (
    <Dialog {...rest} maxWidth='lg' open={open} onClose={onCancel}>
      <DialogTitle>Review changes</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Old value</TableCell>
              <TableCell>New value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedDiffs}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='primary' onClick={onSubmit}>Submit</Button>
        <Button variant='outlined' color='secondary' onClick={onCancel}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = createStyles({
  oldValue: {
    color: 'blue'
  },
  newValue: {
    color: 'green'
  }
});

DiffConfirmation.propTypes = {
  diffs: PropTypes.array,
  open: PropTypes.bool,
  mapToPrettyNameOrDefault: PropTypes.func
};

export default withStyles(styles)(DiffConfirmation);
