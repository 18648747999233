import useHasPermissions from '../hooks/useHasPermissions';

/**
 *  Renders child components only if the user has the required permissions.
 */
const ACL = ({ require, children }) => {
  const hasPermission = useHasPermissions(require);
  if (hasPermission) {
    return children;
  } else {
    return null;
  }
};

export default ACL;
