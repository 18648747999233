import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RefreshIndicator from 'material-ui/RefreshIndicator';

class Loader extends Component {
  static propTypes = {
    loaded: PropTypes.bool.isRequired
  };
  render() {
    return (
      <div>
        {this.props.loaded && this.props.children}
        {!this.props.loaded && (
          <div className='row center-xs'>
            <div className='col-xs-1'>
              <RefreshIndicator
                size={35}
                left={0}
                top={-2}
                status='loading'
                style={{ display: 'inline-block', position: 'relative' }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Loader;
