import * as actionTypes from './actionTypes';
import { compose, createAPIStatusReducer, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  createFullStateResponseReducer
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PURGE_BUILD_INFORMATION:
      return purgeBuildInformation(state, action);
    default:
      return state;
  }
};

const purgeBuildInformation = compose(
  createUIBlockingActionReducer('Failed to purge build information'),
  createFullStateResponseReducer()
);
