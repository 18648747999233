import * as actionTypes from './actionTypes';
import { API } from '~/api';
import Q from 'q';
import * as R from 'ramda';

export function addTestUser(gameId, testUser) {
  return {
    type: actionTypes.ADD_TEST_USER,
    [API]: {
      url: `content_delivery_framework_v2/${gameId}/testuser/create`,
      method: 'post',
      body: testUser
    }
  };
}

export function updateTestUser(gameId, testUser) {
  return {
    type: actionTypes.UPDATE_TEST_USER,
    [API]: {
      url: `content_delivery_framework_v2/${gameId}/testuser/update_latest/${testUser.linkedId}`,
      method: 'post',
      body: testUser
    }
  };
}

export function deleteTestUser(gameId, testUser) {
  return {
    type: actionTypes.DELETE_TEST_USER,
    [API]: {
      url: `content_delivery_framework_v2/${gameId}/testuser/delete_latest/${testUser.linkedId}`,
      method: 'post'
    }
  };
}
