import { combineReducers } from 'redux';
import * as accessAndErasureConstants from './accessAndErasure/constants';
import accessAndErasureReducer from './accessAndErasure/reducer';
import * as adsConstants from './ads/constants';
import adsReducer from './ads/reducer';

export default combineReducers({
  [accessAndErasureConstants.NAME]: accessAndErasureReducer,
  [adsConstants.NAME]: adsReducer
});
