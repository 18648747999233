import * as actionTypes from './actionTypes';

const initialState = {
  isOpen: false,
  message: '',
  variant: 'info'
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION:
      return {
        ...state,
        message: action.payload.notification,
        variant: action.payload.variant,
        isOpen: true
      };
    case actionTypes.CLOSE_NOTIFICATIONS:
      return {
        ...state,
        message: '',
        isOpen: false
      };
    default:
      return state;
  }
};
