import * as actionTypes from './actionTypes';
import R from 'ramda';
import { isFilterConjunction } from './model';

const initialState = {
  mode: 'query',
  query: null,
  result: null,
  filterFields: [],
  filters: [],
  ordering: {},
  pageToken: null,
  jobId: null,
  queryStatus: null,
  nextPageStatus: null,
  batchApplyStatus: null,
  getQueryFiltersStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return update(state, action);
    case actionTypes.GET_QUERY_FILTERS:
      return getQueryFilters(state, action);
    case actionTypes.QUERY:
      return query(state, action);
    case actionTypes.GET_NEXT_QUERY_PAGE:
      return getNextQueryPage(state, action);
    case actionTypes.UPDATE_ORDERING:
      return updateOrdering(state, action);
    case actionTypes.UPDATE_FILTERS:
      return updateFilters(state, action);
    case actionTypes.UPDATE_SELECTION:
      return updateSelection(state, action);
    case actionTypes.CSV_UPLOAD:
      return uploadCsv(state, action);
    case actionTypes.BATCH_APPLY:
      return batchApply(state, action);
    case actionTypes.TOGGLE_MODE:
      return toggleMode(state);
    case actionTypes.SHOW_MORE_CSV_RESULTS:
      return showMoreCsvResults(state);
    default:
      return state;
  }
};

const showMoreCsvResults = state => ({
  ...state,
  result: {
    ...state.result,
    showing: Math.min(state.result.totalCount, state.result.showing + 100)
  }
});

const toggleMode = state => ({
  ...state,
  mode: state.mode === 'query' ? 'csv' : 'query'
});

const update = (state, { payload: { query } }) => ({
  ...state,
  query
});

const updateSelection = (state, { payload: { selection } }) => ({
  ...state,
  selection
});

const uploadCsv = (state, { payload: { entities } }) => ({
  ...state,
  selection: {
    isAllSelected: false,
    selected: {},
    notSelected: {}
  },
  result: {
    showing: R.min(100, entities.length),
    entities,
    totalCount: entities.length
  }
});

const updateOrdering = (state, { payload: { ordering } }) => ({
  ...state,
  ordering: R.pick(getFilterKeys(state.filters), ordering)
});

const updateFilters = (state, { payload: { filters } }) => {
  // Ensuring there are conjections (AND/OR) in the proper spots.
  const ensureConjunctions = f => {
    f = R.reduce(
      (acc, elem) => {
        if (isFilterConjunction(elem)) {
          if (acc.length > 0 && !isFilterConjunction(R.last(acc))) {
            acc.push(elem);
          }
        } else {
          if (acc.length > 0 && !isFilterConjunction(R.last(acc))) {
            acc.push('AND');
          }

          if (Array.isArray(elem)) {
            acc.push(ensureConjunctions(elem));
          } else {
            acc.push(elem);
          }
        }
        return acc;
      },
      [],
      f
    );

    if (isFilterConjunction(R.last(f))) f = R.init(f);
    return f;
  };
  filters = ensureConjunctions(filters);
  return {
    ...state,
    filters,
    ordering: R.pick(getFilterKeys(filters), state.ordering)
  };
};

const getQueryFilters = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getQueryFiltersStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getQueryFiltersStatus: 'Failed to get query filters'
    };
  } else {
    return {
      ...state,
      getQueryFiltersStatus: null,
      filterFields: action.payload
    };
  }
};

const getNextQueryPage = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      query: {
        ...state.query,
        nextPageStatus: 'pending'
      }
    };
  } else if (action.error) {
    return {
      ...state,
      query: {
        ...state.query,
        nextPageStatus: action.payload || 'Failed to query.'
      }
    };
  } else {
    return {
      ...state,
      nextPageStatus: null,
      result: {
        ...state.result,
        showing: state.result.entities.length + action.payload.rows.length,
        entities: [...state.result.entities, ...action.payload.rows]
      }
    };
  }
};

const query = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      query: {
        filters: action.meta.filters,
        localId: action.transactionId,
        status: 'pending'
      }
    };
  } else if (action.error) {
    return {
      ...state,
      query: {
        ...state.query,
        status: action.payload || 'Failed to query.'
      }
    };
  } else {
    return {
      ...state,

      selection: {
        isAllSelected: false,
        selected: {},
        notSelected: {}
      },
      query: {
        ...state.query,
        status: null,
        statementId: action.payload.statementId
      },
      result: {
        showing: action.payload.rows.length,
        entities: action.payload.rows,
        totalCount: action.payload.totalCount
      }
    };
  }
};

const batchApply = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      batchApplyStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      batchApplyStatus: action.payload || 'Failed to apply batch update.'
    };
  } else {
    return {
      ...state,
      batchApplyStatus: null
    };
  }
};

// Utils functions
const getFilterKeys = filters => {
  let keys = [];
  filters.forEach(function(elem) {
    if (Array.isArray(elem)) {
      keys = R.union(keys, getFilterKeys(elem));
    } else if (typeof elem === 'object') {
      if (keys.indexOf(elem.field)) {
        keys.push(elem.field);
      }
    }
  });
  return keys;
};
