import React, { PureComponent } from 'react';
import {
  basePropTypes,
  getValue,
  setValue,
  getTitle,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import R from 'ramda';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
});

class EnumField extends PureComponent {
  static propTypes = {
    // Values in the drop down menu.
    items: PropTypes.array.isRequired,

    // If present, these values will be shown in the drop down menu insted of their true valeus (optional)
    itemAliases: PropTypes.array,

    // If true, the value for this component will be the index of the value not the value itself.
    valueIsIndex: PropTypes.bool,

    // whether we should allow empty value on this dropdown
    canBeNull: PropTypes.bool,

    useNative: PropTypes.bool,

    ...basePropTypes,
    // Internal
    disabledValues: PropTypes.array
  };

  constructor(props) {
    assertProps(props, EnumField);
    super(props);
    this.state = {
      errors: {}
    };
  }

  getOptionText(index) {
    const { itemAliases, items } = this.props;
    if (!R.isNil(itemAliases)) {
      if (itemAliases.length > index && !R.isNil(itemAliases[index]))
        return itemAliases[index];
    }
    return items[index];
  }

  componentDidMount() {
    this.setDefaultValue();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setDefaultValue(nextProps);
  }

  getDisabledValues = (props = this.props) => {
    const values = props.disabledValues || [];
    if (props.valueIsIndex) {
      return values.map(index => props.items[index]);
    } else {
      return values;
    }
  };

  setDefaultValue(props = this.props) {
    const value = getValue(props);
    const { items, valueIsIndex } = props;
    if (R.isNil(value)) {
      const disabled = this.getDisabledValues(props);
      const allowed = items.filter(
        v => disabled.indexOf(v) === -1 && v.length > 0
      );
      if (
        allowed.length > 0 &&
        !R.equals(true, this.props.skipDefaultValuesOnMount)
      ) {
        setValue(
          props,
          valueIsIndex === true ? items.indexOf(allowed[0]) : allowed[0]
        );
      } else if (
        value !== null &&
        !R.equals(true, this.props.skipDefaultValuesOnMount)
      ) {
        setValue(props, null, true);
      }
    }
  }

  handleChange = (e, child) => {
    const { valueIsIndex } = this.props;
    setValue(this.props, valueIsIndex ? Number(child.props.index) : e.target.value);
  };

  getItem = (value, index, disabledValues) => {
    const { useNative } = this.props;
    if (useNative)
      return (
        <option
          disabled={disabledValues.indexOf(value) !== -1}
          key={index}
          value={value}
        >
          {this.getOptionText(index)}
        </option>
      );
    else
      return (
        <MenuItem
          disabled={disabledValues.indexOf(value) !== -1}
          key={index}
          index={index}
          value={value}
        >
          {this.getOptionText(index)}
        </MenuItem>
      );
  };

  render() {
    if (this.props.visible === false) return null;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    let value = getValue(this.props);
    const {
      items,
      style,
      disabled,
      className,
      valueIsIndex,
      classes,
      canBeNull,
      useNative
    } = this.props;
    let disabledValues = this.getDisabledValues(this.props);
    let errorText = R.isNil(value) ? 'No value' : null;
    const error = !R.isNil(errorText);

    if (valueIsIndex === true) {
      if (typeof value === 'number' && items.length >= value) {
        value = items[value];
      } else {
        value = null;
      }
    }

    if (R.isNil(value))
      //material-ui select input doesn't accept null or undefined values for input
      value = '';

    return (
      <div className={className || 'col-xs'}>
        <FormControl
          className={classes.formControl}
          fullWidth={true}
          disabled={disabled}
          error={error}
        >
          <InputLabel htmlFor='input-simple'>{getTitle(this.props)}</InputLabel>
          <Select
            value={value}
            native={useNative}
            onChange={this.handleChange}
            input={<Input name='age' id='input-simple' />}
            style={style}
          >
            {canBeNull && useNative && <option value=''></option>}
            {items.map((v, i) => this.getItem(v, i, disabledValues))}
          </Select>
          <FormHelperText>{error ? errorText : ''}</FormHelperText>
        </FormControl>
      </div>
    );
  }
}

EnumField.defaultProps = {
  useNative: true
};

export default wrap(withStyles(styles)(EnumField));
