import { combineReducers } from 'redux';
import * as authConstants from './auth/constants';
import authReducer from './auth/reducer';
import * as configsConstants from './configs/constants';
import configsReducer from './configs/reducer';
import * as notificationsConstants from './notifications/constants';
import notificationsReducer from './notifications/reducer';

export default combineReducers({
  [authConstants.NAME]: authReducer,
  [configsConstants.NAME]: configsReducer,
  [notificationsConstants.NAME]: notificationsReducer,
  [configsConstants.NAME]: configsReducer
});
