import React, {FC} from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import {VerticalLayout} from '../../components';
import {getSeriouslyLogoForEnvironment} from '../../../constants';

const useStyles = makeStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    },
    authIndicator: {
      border: '1px solid rgba(134, 142, 149, 0.25)',
      borderRadius: '50%',
      minWidth: 200,
      minHeight: 200,
      animation: '$pulse 3s infinite'
    },
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
      },
      '50%': {
        boxShadow: '0px 8px 24px 0px rgba(149, 157, 165, 0.5)'
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
      }
    }
  });

const AuthProgressIndicator: FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <VerticalLayout
                className={classes.authIndicator}
                alignItems='center'
                justifyContent='center'
                gap={8}
            >
                <img
                    src={getSeriouslyLogoForEnvironment()}
                    alt='Seriously icon'
                    width={64}
                    height={64}
                />
                <Typography>Authenticating</Typography>
            </VerticalLayout>
        </div>
    );
};

export default AuthProgressIndicator;
