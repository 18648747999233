import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import isoFetch from 'isomorphic-fetch';
import URI from 'urijs';
import VerticalLayout from './VerticalLayout';

class KibanaIFrame extends Component {
  static kibanaRootUrl = 'https://kibana.cloud.seriously.com';
  static prevLoginStatus = 'unknown';

  static propTypes = {
    url: PropTypes.string.isRequired,
    editable: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      loginStatus: KibanaIFrame.prevLoginStatus,
      forceRefresh: 0
    };
  }

  setLoginStatus(loginStatus) {
    this.setState(() => ({ loginStatus }));
    KibanaIFrame.prevLoginStatus = loginStatus;
  }

  componentDidMount() {
    this.checkIfLoggedInToKibanaIfNeeded();
  }

  componentWillUnmount() {
    if (this.fetchAbortController) {
      this.fetchAbortController.abort();
      this.fetchAbortController = null;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Kibana can't hande switching between embeded and normal mode without a forced refresh.
    if (nextProps.editable !== this.props.editable) {
      this.setState({ forceRefresh: this.state.forceRefresh + 1 });
    }
  }

  checkIfLoggedInToKibanaIfNeeded() {
    if (this.state.loginStatus === 'unknown') {
      this.fetchAbortController = new AbortController();
      isoFetch(KibanaIFrame.kibanaRootUrl, {
        credentials: 'include',
        signal: this.fetchAbortController.signal
      })
        .then(response => {
          if (response.status == 403) {
            this.setLoginStatus('notLoggedIn');
          } else if (response.status == 200) {
            this.setLoginStatus('loggedIn');
          }
        })
        .catch(err => {
          if (err.name !== 'AbortError') {
            this.setLoginStatus('loggedIn');
          }
        });
    }
  }

  renderIFrame() {
    var split = this.props.url.split('?');

    var uri = new URI(split.length > 1 ? '?' + split[1] : '');
    if (this.props.editable) {
      uri.removeQuery('embed');
    } else {
      uri.addQuery('embed', 'true');
    }

    const url = `${split[0]}${uri.toString()}`;
    return (
      <iframe
        key={this.state.forceRefresh + ''}
        src={url}
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    );
  }

  render() {
    const { style, className } = this.props;
    var content = null;
    switch (this.state.loginStatus) {
      case 'unknown':
        content = this.renderLoadingScreen();
        break;

      case 'notLoggedIn':
        content = this.renderLoginScreen();
        break;

      case 'loggedIn':
      default:
        content = this.renderIFrame();
        break;
    }

    return (
      <VerticalLayout style={style} className={className}>
        <a style={{
          position: 'absolute',
          top: '10rem',
          right: '1rem'
        }} href={this.props.url} target='_blank'>
          <Button variant='contained'>Show in Kibana</Button>
        </a>
        {content}
      </VerticalLayout>
    );
  }

  renderLoginScreen() {
    return (
      <VerticalLayout gap={16} alignItems='center' justifyContent='center'>
        <VerticalLayout gap={2}>
          <Typography variant='body1'>You need to log in to Kibana once</Typography>
          <Typography variant='body2'>Remember to close the tab after to view the dashboard on this page.</Typography>
        </VerticalLayout>
        <HorizontalLayout>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleLogin}
          >
            LOG IN TO KIBANA
          </Button>
        </HorizontalLayout>
      </VerticalLayout>
    );
  }

  renderLoadingScreen() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <CircularProgress size={80} />
      </div>
    );
  }

  handleVisibilityChange = () => {
    if (!document.hidden) {
      window.location.reload();
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      );
    }
  };

  handleLogin = () => {
    window.open('http://kibana.cloud.seriously.com', '_blank');
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  };
}

export default KibanaIFrame;
