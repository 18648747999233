import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

/**
    A container which height is set so that it's bottom is touching the browser windows bottom.
*/

const styles = {
  container: {
    width: '100%',
    display: 'flex'
  }
};

const HEIGHT_TWEAK = 5;

class FullHeightContainer extends Component {
  static propTypes = {
    bottomOffset: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      height: -1
    };
  }

  componentDidMount() {
    this.updateSize();
    window.addEventListener('resize', this.updateSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
  }

  componentDidUpdate() {
    this.updateSize();
  }

  updateSize = () => {
    var bb = this.ref.current.getBoundingClientRect();
    var height = window.innerHeight - bb.y - HEIGHT_TWEAK;
    if (height != this.state.height) {
      this.setState({ height });
    }
  };

  render() {
    const { classes, className, children, bottomOffset } = this.props;
    let style = this.props.style || {};

    if (this.state.height > 0) {
      style.height = this.state.height + (bottomOffset || 0);
    }

    return (
      <div
        className={classNames(classes.container, className)}
        ref={this.ref}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(FullHeightContainer);
