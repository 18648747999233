import * as R from 'ramda';
import { getState } from '../selectorUtils';

export const getBuildInformations = state => getState(state).latest.buildInformation;

export const getBuildInformationById = function(state, id) {
  return getState(state).bundleInformationByLinkIdMap[id];
};
/*
export const getBuildInformationById = (state, id) =>
  R.find(R.propEq('linkedId', id), getBuildInformations(state));
  */

export const getAllClientVersions = state => R.uniq(getBuildInformations(state).map(R.prop('clientVersion')));
