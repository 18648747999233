import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  entities: [],
  serverEntities: [],
  getStatus: null,
  createStatus: null,
  saveStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET:
      return get(state, action);
    case actionTypes.CREATE:
      return create(state, action);
    case actionTypes.SAVE:
      return save(state, action);
    case actionTypes.UPDATE:
      return update(state, action);
    case actionTypes.RESET:
      return reset(state, action);
    default:
      return state;
  }
};

const get = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: action.payload.message || 'Failed to get core configuration'
    };
  } else {
    return {
      ...state,
      getStatus: null,
      entities: addConfig(state.entities, action.payload),
      serverEntities: addConfig(state.serverEntities, R.clone(action.payload))
    };
  }
};

export const create = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      createStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      createStatus:
        action.payload.message || 'Failed to create core configuration'
    };
  } else {
    return {
      ...state,
      getStatus: null,
      entities: addConfig(state.entities, action.payload),
      serverEntities: addConfig(state.serverEntities, R.clone(action.payload))
    };
  }
};

export const save = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      saveStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      saveStatus:
        action.payload.message || 'Failed to create core configuration'
    };
  } else {
    const { name } = action.meta;
    return {
      ...state,
      saveStatus: null,
      entities: mapByName(state.entities, name, config =>
        R.merge(config, action.payload)
      ),
      serverEntities: mapByName(state.serverEntities, name, config =>
        R.merge(config, action.payload)
      )
    };
  }
};

export const update = (state, action) => ({
  ...state,
  entities: addConfig(state.entities, action.payload.config)
});

export const reset = (state, action) => {
  const { name } = action.payload;
  return {
    ...state,
    entities: mapByName(action.entities, name, () =>
      R.find(R.propEq('name', name), state.serverEntities)
    )
  };
};

/******** Utils ****************/

const addConfigs = (configs, newConfigs) =>
  R.unionWith(R.eqBy(R.prop('name')), newConfigs, configs);
const addConfig = (configs, config) => addConfigs(configs, [config]);
const mapByName = (configs, name, fn) => {
  const index = R.findIndex(R.propEq('name', name), configs);
  if (index === -1) return configs;
  return R.adjust(fn, index, configs);
};
