import * as actionTypes from './actionTypes';
import { API } from '~/api';
import Q from 'q';
import * as R from 'ramda';

export const clearExportUrl = () => ({ type: actionTypes.CLEAR_EXPORT_URL });

export function exportDump(gameId, exportMessage) {
  return {
    type: actionTypes.EXPORT_DUMP,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/db/service/DB/dump`,
      method: 'post',
      body: { exportMessage }
    }
  };
}

export function importDump(gameId, dumpUrl) {
  return {
    type: actionTypes.IMPORT_DUMP,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/db/service/DB/import`,
      method: 'post',
      body: { url: dumpUrl }
    }
  };
}

export function activateDatabase(gameId, database) {
  return {
    type: actionTypes.ACTIVATE_DATABASE,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/db/service/DB/setactive/${database.id}`,
      method: 'post'
    },
    meta: { database }
  };
}

export const diffDatabase = (gameId, databaseName, flattenDiff = true) => ({
  type: actionTypes.DIFF_DATABASE,
  [API]: {
    url: `/content_delivery_framework_v2/${gameId}/assets/fetch/databasediff`,
    method: 'get',
    query: { databaseName, flattenDiff }
  }
});

export const deleteDatabase = (gameId, database) => {
  return {
    type: actionTypes.DELETE_DATABASE,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/db/service/DB/delete/${database.id}`,
      method: 'post'
    },
    meta: { database }
  };
}
