import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API } from '../../api';
import * as R from 'ramda';
import { addNotification } from '../notifications/actions';

const createAction = (endpoint, method, body, query) => ({
  type: 'STATELESS_REQUEST_HANDLER',
  [API]: {
    url: endpoint,
    method: method,
    query,
    body
  }
});

const isValidNotification = message => {
  const isString = typeof message === 'string';
  const hasValue = !R.isNil(message) && !R.isEmpty(message);

  if (hasValue && !isString) {
    console.warn('Invalid notification type. Ensure a string is passed.');
  }

  return isString && hasValue;
};

const withRequests = WrappedComponent => {
  const withRequestsWrapper = ({ dispatch }) => {
    const [isRequestPending, setIsRequestPending] = useState(false);
    const doRequest = async (req, successMessage, errorMessage) => {
      setIsRequestPending(true);

      try {
        const res = await dispatch(
          createAction(
            R.path(['endpoint'], req),
            R.path(['method'], req),
            R.path(['body'], req),
            R.path(['query'], req)
          )
        );

        if (isValidNotification(successMessage)) {
          dispatch(addNotification(successMessage, 'info'));
        }

        setIsRequestPending(false);

        return res;
      } catch (error) {
        if (isValidNotification(errorMessage)) {
          dispatch(addNotification(errorMessage, 'error'));
        }

        console.error(error);
      }

      setIsRequestPending(false);
    };

    return <WrappedComponent doRequest={doRequest} isRequestPending={isRequestPending} />;
  };

  withRequestsWrapper.propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  return connect()(withRequestsWrapper);
};

export default withRequests;
