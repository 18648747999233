import { dispatchNotification } from '../../store';

const useSnackbar = () => {
  const handleMessage = (message: string, variant: 'success' | 'warning' | 'error' | 'info') => {
    dispatchNotification(message, variant);
  };

  return handleMessage;
};

export default useSnackbar;
