import React, { Component } from 'react';
import { ListItem } from 'material-ui/List';
import style from './ExpandableListItem.scss';
import R from 'ramda';
import Paper from 'material-ui/Paper';

class ExpandableListItem extends Component {
  state = {
    open: false,
    id: Math.random() + 'id'
  };

  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    const content = [
      <ListItem key={0} className={style.contentContainer} disabled={true}>
        {this.props.children}
      </ListItem>
    ];
    const otherProps = R.omit(['children'], this.props);
    const list = (
      <ListItem
        {...otherProps}
        initiallyOpen={this.state.open}
        primaryTogglesNestedList={true}
        nestedItems={content}
        onNestedListToggle={::this.toggleOpen}
        focusRippleOpacity={1}
        key={this.state.id}
      />
    );
    const selectedStyle = { zIndex: 100, marginBottom: '1em' };
    return (
      <Paper
        style={this.state.open ? selectedStyle : null}
        zDepth={this.state.open ? 2 : 0}
      >
        {list}
      </Paper>
    );
  }
}

export default ExpandableListItem;
