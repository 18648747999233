import * as actionTypes from './actionTypes';

export const logout = () => ({ type: actionTypes.LOGOUT });
export const serverClientVersionMistmatch = serverVersion => ({
  type: actionTypes.SERVER_CLIENT_VERSION_MISMATCH,
  payload: {
    serverVersion
  }
});

export const toggleAutoLogout = () => ({
  type: actionTypes.TOGGLE_AUTO_LOGOUT
});
