import PropTypes from 'prop-types';
import R from 'ramda';

export const basePropTypes = {
  field: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ]),

  // Title is either a string or a function in the form (trueValue, fieldProps) -> ReactNode
  // trueValue may be a computed field, the 'field' property of props.value or just the props.value.
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,

  //                  Computed fields
  // (value) -> any
  getter: PropTypes.func,
  // (value, newValue) -> newParentValue
  setter: PropTypes.func,

  value: PropTypes.any,

  // (newValue, isErrored) -> void
  onChange: PropTypes.func,

  // If false, the field should not be rendered.
  // Note: The field will still do error checking and set defautl values when it is invisible
  visible: PropTypes.bool,

  // Called when a fields value is changed.
  // Should return a string if there is an error and null/undefined if there is no error.
  // Note that not all Fields are able to display errors.
  // (value) -> String|Nil
  onValidate: PropTypes.func,

  defaultValue: PropTypes.any,

  tooltip: PropTypes.string,

  tooltipDelay: PropTypes.number
};

export function isValidField(Component) {
  return (
    Component && Component.type && Component.type.__isFastFormField === true
  );
}

/**
 * Only wrapped components will have field props (onChange, value, visible, etc) injected by parent FastForm components like EntityField.
 * This is to allow non-field components to be added in-between FastForm field components.
 * The problem being that React will throw an error if a native component like 'div' is injected with an unknown prop.
 */
export function wrap(FieldClass) {
  FieldClass.__isFastFormField = true;
  return FieldClass;
}

export function assertProps(props, FieldClass) {
  const { field, getter, setter, onChange } = props;
  if (!R.isNil(field) && !R.isNil(getter)) {
    console.error(
      `[${FieldClass.name}] Prop error: Both 'field' and 'getter' are defined.`
    );
  }
  if (R.isNil(onChange)) {
    throw new Error(
      `[${FieldClass.name}] Prop error: 'onChange' is undefined. Make sure all components in the field component tree has been wrapped with FieldBase.wrap. Otherwise 'onChange' is not injected.`
    );
  }
}

export function componentMounts(props) {
  if (
    !R.equals(true, props.skipDefaultValuesOnMount) &&
    !R.isNil(props.defaultValue) &&
    R.isNil(getRawValue(props))
  ) {
    setValue(props, props.defaultValue);
  }
}

export function fieldExists(props) {
  if (R.isNil(props.field))
    //if we have not defined a field, don't check whether it exists
    return true;
  return R.has(props.field, props.value);
}

function getRawValue(props) {
  let value = undefined;
  if (props.getter) {
    value = props.getter(props.value);
  } else if (!R.isNil(props.field)) {
    if (!R.isNil(props.value)) {
      if (Array.isArray(props.field)) {
        value = R.path(props.field, props.value);
      } else {
        value = props.value[props.field];
      }
    }
  } else {
    value = props.value;
  }
  return value;
}

export function getValue(props) {
  let value = getRawValue(props);
  if (typeof value === 'undefined') {
    value = props.defaultValue;
  }
  return value;
}

export function setValue(props, newValue, isErrored = false) {
  let value;
  if (props.setter) {
    value = props.setter(props.value, newValue);
  } else if (!R.isNil(props.field)) {
    if (Array.isArray(props.field)) {
      value = R.assocPath(props.field, newValue, props.value);
    } else {
      value = R.assocPath([props.field], newValue, props.value);
    }
  } else {
    value = newValue;
  }
  props.onChange(value, isErrored);
}

export function setValueByButton(val, val2) {
  console.log('setValueByButton', val, val2);
}

export function getTitle(props) {
  if (R.isNil(props.title) || typeof props.title === 'string') {
    return props.title;
  } else {
    return props.title(getValue(props), props);
  }
}

export function getTooltip(props) {
  if (!R.isNil(props.tooltip) && typeof props.tooltip === 'string') {
    return props.tooltip;
  } else {
    return '';
  }
}

export function getTooltipDelay(props) {
  if (!R.isNil(props.tooltipDelay) && typeof props.tooltipDelay === 'number') {
    return props.tooltipDelay;
  } else {
    return 1000;
  }
}

export function validate(props, value) {
  if (props.onValidate) {
    return props.onValidate(value);
  } else {
    return null;
  }
}
