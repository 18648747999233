import React, { FC, HTMLAttributes } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
export interface VerticalLayoutProps {
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline' | 'initial' | 'inherit';
  justifyContent?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
    | 'normal';
  gap?: number;
}

const VerticalLayout: FC<VerticalLayoutProps & HTMLAttributes<HTMLDivElement>> = ({
  className,
  alignItems = 'stretch',
  justifyContent = 'normal',
  gap = 0,
  children,
  ...rest
}) => {
  const classes = makeStyles({
    layout: {
      display: 'flex',
      flexDirection: 'column',
      alignItems,
      justifyContent,
      gap
    }
  })()

  return (
    <div {...rest} className={classNames(classes.layout, className)}>
      {children}
    </div>
  );
};

export default VerticalLayout;
