import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import apiMiddleware from '~/apiMiddleware';
import { combineReducers } from 'redux';
import { reducers } from './reducers';
import { addNotification } from './core/notifications/actions';

export function configureStore(history) {
  const routerMid = routerMiddleware(history);
  const middleware = compose(
    // Redux middleware
    applyMiddleware(thunk, apiMiddleware, routerMid),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  );

  const store = createStore(combineReducers(reducers(history)), middleware);

  //reducerRegistry.setChangeListener(reducer => store.replaceReducer(reducer))

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(combineReducers(nextRootReducer));
    });
  }

  // Only to allow for easy debugging in developer console, NOT for production use
  window.store = store;

  return store;
}

export const dispatchNotification = (message, variant) => {
  store.dispatch(addNotification(message, variant));
};
