import R from 'ramda';

/**

    In the bf-common module, it is common that the state of a submodule contains data from different games which should be kept separate.
    It is cumbersome to handle this in the reducers, so instead this method can be used to split the state into gameId specific chunks.

*/
export default (actionPrefix, reducer) => (state = {}, action) => {
  if (action.type.startsWith(actionPrefix)) {
    if (R.isNil(action.meta) || R.isNil(action.meta.gameId)) {
      console.error(
        `[${actionPrefix}] All actions should specify "gameId" in the meta data. Action: ${action.type}`
      );
      return state;
    } else {
      return {
        ...state,
        [action.meta.gameId]: reducer(state[action.meta.gameId], action)
      };
    }
  } else {
    return state;
  }
};
