import React, { FC } from 'react';
import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography, makeStyles } from '@material-ui/core';
import * as R from 'ramda';

interface ConfirmationDialogNewProps {
  open: boolean;
  title: string;
  text: string;
  okText?: string;
  okOnly?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  overrideContent?: React.ReactNode;
}

const ConfirmationDialogNew: FC<ConfirmationDialogNewProps> = ({
  open,
  title,
  text,
  okText = 'Ok',
  okOnly,
  onSubmit,
  onCancel,
  overrideContent,
  ...rest
}) => {
  const classes = makeStyles({
    button: {
      margin: '1em'
    }
  })();

  const content = R.isNil(overrideContent) ? <Typography>{text}</Typography> : overrideContent;

  return (
    <Dialog {...rest} onClose={onCancel} open={open}>
      <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {!okOnly && (
          <Button className={classes.button} variant='outlined' color='default' onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button className={classes.button} variant='outlined' color='primary' onClick={onSubmit}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogNew;