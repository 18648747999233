import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
    currentSearchFields: [],
    searchResults: [],
    searchesPending: [],
    searchErrors: [],
    triggerCaseErasureStatus: null,
    createCaseStatus: null,
    getCaseStatus: null,
    anonymizeCaseStatus: null,
    generateAccessStatus: null,
    latestAccessLink: null,
    updateCaseStatus: null,
    cases: []
}

export default (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SEARCH: return search(state, action);
        case actionTypes.SEARCH_FIELDS_CHANGED: return searchFieldsChanged(state, action);
        case actionTypes.CLEAR_SEARC_RESULTS: return clearSearchResults(state);
        case actionTypes.GET_CASE: return getCase(state, action);
        case actionTypes.GENERATE_ACCESS_LINK: return generateAccessLink(state, action);
        case actionTypes.CLEAR_ACCESS_LINK: return clearAccessLink(state, action);
        case actionTypes.CREATE_CASE: return createCase(state, action);
        case actionTypes.TRIGGER_ERASURE: return caseUpdateReducer(state, action, 'Error while triggering erasure.')
        case actionTypes.ANONYMIZE_CASE: return caseUpdateReducer(state, action, 'Error while anonymizing case.')
        case actionTypes.SET_ERASURE_REQUEST_STATUS: return caseUpdateReducer(state, action, 'Error while setting erasure request status.')
        case actionTypes.TRIGGER_ERASURE_FOR_SPECIFIC_REQUEST: return caseUpdateReducer(state, action, 'Error while triggering erasure for request.')
        default: return state;
    }
}

function search(state, action) {
    const { searchField } = action.meta;

    if (action.pending) {
        return {
            ...state,
            searchesPending: [...state.searchesPending, action.transactionId]
        }
    }
    else if (action.error) {
        return {
            ...state,
            searchesPending: R.reject(v => v === action.transactionId, state.searchesPending),
            searchErrors: [
                ...state.searchErrors,
                action.payload || 'Search failed.'
            ]
        }
    }
    else {
        return {
            ...state,
            searchesPending: R.reject(v => v === action.transactionId, state.searchesPending),
            searchResults: addSearchResults(state.searchResults, action.payload.map(e => ({...e, searchField })))
        }
    }
}

function searchFieldsChanged(state, action) {
    const currentSearchFields = action.payload.searchFields;
    
    return {
        ...state,
        currentSearchFields,
        searchResults: state.searchResults.filter(({ searchField }) => containsFilter(currentSearchFields, searchField))
    }
}

function clearSearchResults(state) {
    return {
        ...state,
        searchResults: [],
        currentSearchFields: []
    }
}

function createCase(state, action) {
    if (action.pending) {
        return {
            ...state,
            createCaseStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            createCaseStatus: action.payload || 'Error while creating case.'
        }
    }
    else {
        return {
            ...state,
            createCaseStatus: null,
            cases: [ action.payload ]
        }
    }
}

function getCase(state, action) {
    if (action.pending) {
        return {
            ...state,
            getCaseStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            getCaseStatus: action.status === 404 ? 'Case could not be found.' : 'Error while fetching case.'
        }
    }
    else {
        return {
            ...state,
            getCaseStatus: null,
            cases: [ action.payload ]
        }
    }
}

function caseUpdateReducer(state, action, errorMessage) {
    if (action.pending) {
        return {
            ...state,
            updateCaseStatus: 'pending'
        }
    }
    else if (action.error) {
        return {
            ...state,
            updateCaseStatus: errorMessage
        }
    }
    else {
        return {
            ...state,
            updateCaseStatus: null,
            cases: [ action.payload ]
        }
    }
}

function generateAccessLink(state, action) {
    if (action.pending) {
        return {
            ...state,
            generateAccessStatus: 'pending',
            latestAccessLink: null
        }
    }
    else if (action.error) {
        return {
            ...state,
            generateAccessStatus: 'Error while generating access link.'
        }
    }
    else {
        return {
            ...state,
            generateAccessStatus: null,
            latestAccessLink: action.payload.url
        }
    }
}

function clearAccessLink(state) {
    return {
        ...state,
        generateAccessStatus: null,
        latestAccessLink: null
    }
}



// Utils functions
const addSearchResults = (items, results) => R.unionWith((a,b) => a.id === b.id && a.target === b.target, items, results)
const containsFilter = (filters, filter) => R.find((a) => a.value === filter.value, filters) != null;