import { useState, useEffect } from 'react';
import * as R from 'ramda';

/*
* Takes in an initial state of an any instance and keeps track of what's changed.
* Returns the current diffs, a function to add a change and clear all changes.
* When calling the add function you need to provide the current state of the any
* instance, inventory path such as can be used in the ramda R.path() function and
* the new value at that path.
* 
* A diff has the following signature:
* {
*   path: ['part1', 'part2'],
*   prevValue: initialValue,
*   newValue: newValue
* }
*/
const useDiffs = (state) => {
  const [initialState, setInitialState] = useState(null);
  const [diffs, setDiffs] = useState([]);

  useEffect(() => setInitialState(state), [state]);

  const isValidDiff = (diff) => {
    const initialValue = R.path(diff.path, initialState);
    const newValue = diff.newValue;
    const hasChanged = !R.equals(initialValue, newValue);

    return hasChanged
  }

  const addDiffsIfValid = (newDiffs) => {
    const diffsClone = R.clone(diffs);
    let wasUpdated = false;
    
    for (let newDiff of newDiffs) {
      const isValid = isValidDiff(newDiff);

      for (let i = 0; i < diffsClone.length; i++) {
        const diff = diffsClone[i];
        const isMatchingDiff = R.equals(diff.path, newDiff.path);
  
        if (isMatchingDiff && isValid) {
          diff.newValue = newDiff.newValue;
          wasUpdated = true;
  
          break;
        }
        else if (isMatchingDiff && !isValid) {
          diffsClone.splice(i, 1);
          wasUpdated = true;
  
          break;
        }
      }
  
      if (!wasUpdated && isValid) {
        diffsClone.push(newDiff)
      }
    }

    setDiffs(diffsClone);
  }

  const clearDiffs = () => setDiffs([]);

  return [ diffs, addDiffsIfValid, clearDiffs ];
}

export default useDiffs;