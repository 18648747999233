import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

const styles = theme => ({
  container: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  loadingIndicator: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
});

class EnhancedButton extends Component {
  static propTypes = {
    // If true, a loading indicator is shown on top of the button and the button is disabled.
    loading: PropTypes.bool
  };

  render() {
    const {
      children,
      classes,
      loading,
      disabled,
      className,
      ...rest
    } = this.props;
    return (
      <div className={classNames(classes.container, className)}>
        <Button disabled={disabled || loading} {...rest}>
          {children}
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.loadingIndicator} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(EnhancedButton);
