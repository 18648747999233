import * as actionTypes from './actionTypes';
import { API } from '~/api';
import Q from 'q';
import * as R from 'ramda';

export function deleteLatestAssetBundleDefinition(
  gameId,
  assetBundleDefinition
) {
  const { linkedId } = assetBundleDefinition;
  return {
    type: actionTypes.DELETE_LATEST_ASSET_BUNDLE_DEFINITION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/delete_latest/assetbundledefinitions/bybundlename/${assetBundleDefinition.bundleName}`,
      method: 'post'
    },
    meta: { assetBundleDefinition }
  };
}

export const purgeLatestUnpublishedAssetBundleDefinition = (
  gameId,
  assetBundleDefinition
) => {
  const { linkedId } = assetBundleDefinition;
  return {
    type: actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_DEFINITION,
    [API]: {
      url: `/content_delivery_framework_v2/${gameId}/assets/purge_latest_unpublished/assetbundledefinition/${linkedId}`,
      method: 'post'
    },
    meta: { assetBundleDefinition }
  };
};
