import React from 'react';
import { Redirect } from 'react-router-dom';
import { api_path } from '~/config';

/**
 * LoginPage redirects user to call login API. This will trigger flow
 * to authenticate via active directory which will on successful login
 * redirect user to home (/).
 * @returns Redirect to API login
 */
const LoginPage = () => <Redirect to={`${api_path}/api/login`} />;

export default LoginPage;
