import * as actionTypes from './actionTypes';
import { compose, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  createFullStateResponseReducer,
  incrementNonPublishedEntitiesCount
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_CONFIGURATION:
      return purgeLatestUnpublishedAssetBundleConfiguration(state, action);
    case actionTypes.CREATE_ASSET_BUNDEL_CONFIGURATION:
      return createAssetBundleConfiguration(state, action);
    case actionTypes.CLONE_LATEST_ASSET_BUNDLE_CONFIGURATION:
      return cloneAssetBundleConfiguration(state, action);
    case actionTypes.UPDATE_ASSET_BUNDLE_CONFIGURATION:
      return updateAssetBundleConfiguration(state, action);
    case actionTypes.DELETE_LATEST_ASSET_BUNDLE_CONFIGURATION:
      return deleteLatestAssetBundleConfiguration(state, action);
    default:
      return state;
  }
};

const purgeLatestUnpublishedAssetBundleConfiguration = compose(
  createUIBlockingActionReducer(
    'Failed to delete unpublished asset bundle configuration changes'
  ),
  createFullStateResponseReducer()
);

const cloneAssetBundleConfiguration = compose(
  createUIBlockingActionReducer(
    'Failed to create or clone asset bundle configuration'
  ),
  onSuccess(incrementNonPublishedEntitiesCount),
  onSuccess((state, action) => ({
    ...state,
    latest: {
      ...state.latest,
      assetBundleConfigurations: [
        ...state.latest.assetBundleConfigurations,
        action.payload
      ]
    }
  }))
);

const createAssetBundleConfiguration = compose(
  createUIBlockingActionReducer(
    'Failed to create or clone asset bundle configuration'
  ),
  createFullStateResponseReducer()
);

const updateAssetBundleConfiguration = compose(
  createUIBlockingActionReducer('Failed to update asset bundle configuration'),
  createFullStateResponseReducer()
);

const deleteLatestAssetBundleConfiguration = compose(
  createUIBlockingActionReducer('Failed to delete asset bundle configuration'),
  createFullStateResponseReducer()
);
