import React, { FC, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as R from 'ramda';
import { Error } from '@material-ui/icons';

interface ImageProps {
  className?: string;
  src: string;
  alt: string;
  height?: number;
  width?: number;
}

const Image: FC<ImageProps> = ({ className, src, alt, height, width, ...rest }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState('');

  const classes = makeStyles({
    unloadedImage: {
      display: 'none'
    },
    error: {
      color: 'red'
    }
  })();

  if (!R.isEmpty(error)) {
    return <Error className={classes.error} />
  }

  const handleImageLoaded = () => {
    setIsLoaded(true)
  }

  const handleImageLoadError = (errorEvent: React.SyntheticEvent<HTMLImageElement, Event>) => {
    errorEvent.preventDefault();
    console.error(errorEvent);
    setError(`${errorEvent.type}: Failed to load image.`);
  }

  return (
    <>
      {!isLoaded && <CircularProgress />}
      <img
        {...rest}
        className={classNames(className, !isLoaded && classes.unloadedImage)}
        src={src}
        alt={alt}
        height={height}
        width={width}
        onLoad={handleImageLoaded}
        onError={handleImageLoadError}
      />
    </>
  );
}

export default Image;