import PropTypes from 'prop-types';
import R from 'ramda';
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from '@material-ui/core';
import TextFieldDebounce from './TextFieldDebounce';

const styles = {
  button: {
    margin: '1em'
  }
};

class InputDialog extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    okText: PropTypes.string,
    okOnly: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      input: ''
    };
  }

  keyboardInput = e => {
    if (e.keyCode === 13 && !R.isEmpty(this.state.input)) {
      this.props.onSubmit(this.state.input);
      this.setState({ input: '' });
    }
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.input);
    this.setState({ input: '' });
  };

  handleCancel = () => {
    this.props.onCancel();
    this.setState({ input: '' });
  };

  render() {
    const { classes, open, title, text, okOnly, okText } = this.props;

    return (
      <Dialog
        onClose={this.handleCancel}
        open={open}
        onKeyDown={this.keyboardInput}
      >
        <DialogTitle id='simple-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <Typography>{text}</Typography>
          <TextFieldDebounce
            autoFocus={true}
            value={this.state.input}
            onChange={value => this.setState({ input: value })}
            fullWidth={true}
          />
        </DialogContent>
        <DialogActions>
          {!okOnly && (
            <Button
              className={classes.button}
              variant='contained'
              color='primary'
              onClick={this.handleCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            className={classes.button}
            variant='contained'
            color='primary'
            disabled={R.isEmpty(this.state.input)}
            onClick={this.handleSubmit}
          >
            {okText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

InputDialog.defaultProps = {
  okText: 'OK'
};

export default withStyles(styles)(InputDialog);
