import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';

export default function ErrorBox({ error, prefix }) {
  if (R.isNil(error)) return null;

  let message;
  if (error instanceof Error) message = error.message;
  else if (typeof error === 'string') message = error;
  else if (Array.isArray(error))
    message = error.map((err, i) => (
      <div key={i}>
        <small> {`${i + 1}. ${err}`} </small>
      </div>
    ));
  else message = JSON.stringify(error);

  if (typeof message === 'string') {
    return (
      <div
        className='error'
        dangerouslySetInnerHTML={{
          __html: `${!R.isNil(prefix) ? prefix + ': ' : ''}${message} `
        }}
      />
    );
  } else {
    return (
      <div className='error'>
        {!R.isNil(prefix) && prefix + ': '} {message}{' '}
      </div>
    );
  }
}

ErrorBox.propTypes = {
  error: PropTypes.any,
  prefix: PropTypes.string
};
