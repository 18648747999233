import React, { FC } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import * as R from 'ramda';
import HorizontalLayout from './HorizontalLayout';

interface TitleProps {
  icon?: React.ReactNode
}

const Title: FC<TitleProps> = ({ children, icon }) => {
  const classes = makeStyles({
    root: {
      color: '#828282'
    }
  })();

  return (
    <HorizontalLayout alignItems='center' gap={6}>
      {!R.isNil(icon) && icon}
      <Typography className={classes.root} variant='subtitle1' component='p'>
        {children}
      </Typography>
    </HorizontalLayout>
  );
};

export default Title;
