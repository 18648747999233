import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const ADD_PLAYER_TO_TEAM = `${parentName}/${NAME}/ADD_PLAYER_TO_TEAM`;
export const PROMOTE = `${parentName}/${NAME}/PROMOTE`;
export const DEMOTE = `${parentName}/${NAME}/DEMOTE`;
export const MODIFY = `${parentName}/${NAME}/MODIFY`;
export const SEARCH = `${parentName}/${NAME}/SEARCH`;
export const GET_AND_OPEN_TEAM = `${parentName}/${NAME}/GET_AND_OPEN_TEAM`;
export const SET_OPEN_TEAM = `${parentName}/${NAME}/SET_OPEN_TEAM`;
export const KICK = `${parentName}/${NAME}/KICK`;

export const ACCEPT_REQUEST = `${parentName}/${NAME}/ACCEPT_REQUEST`;
export const REJECT_REQUEST = `${parentName}/${NAME}/REJECT_REQUEST`;
