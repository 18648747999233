import * as R from 'ramda';
import { getState } from '../selectorUtils';
import { sortByCreated } from '../utils';

export const getLatestAssetBundleConfigurations = state =>
  sortByCreated(getState(state).latest.assetBundleConfigurations);
export const getLatestAssetBundleConfigurationsByLinkedId = (state, id) =>
  getState(state).assetBundleConfigurationsByLinkIdMap[id];

export const getLatestAssetBundleConfigurationsByAssetBundleName = (state, assetBundleName) =>
  getLatestAssetBundleConfigurations(state).filter(R.propEq('bundleName', assetBundleName));
