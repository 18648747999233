import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import {
  basePropTypes,
  getValue,
  componentMounts,
  setValue as trueSetValue,
  getTitle,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import dayjs from '~/dayjs';
import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';



const styles = {
  picker: {
    width: '100%'
  }
};

const setValue = (props, value) => {
  value = dayjs.utc(value);
  if (props.mode === 'startOfDay') value = value.startOf('day');
  if (props.mode === 'endOfDay') value = value.endOf('day');

  trueSetValue(props, value.toDate());
};

class DateTimeField extends PureComponent {
  static propTypes = {
    mode: PropTypes.oneOf(['none', 'startOfDay', 'endOfDay']),
    classes: PropTypes.object.isRequired,

    ...basePropTypes
  };

  static defaultProps = {
    defaultValue: dayjs.utc().toDate()
  };

  constructor(props) {
    assertProps(props, DateTimeField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnChange = date => {
    const { disabled } = this.props;
    if (disabled) return;

    date = dayjs.utc(localAsUtc(date.toDate()));

    setValue(this.props, date.toDate());
  };

  handleSetToToday = () => {
    setValue(this.props, dayjs.utc().toDate());
  };

  render() {
    if (this.props.visible === false) return null;
    const { disabled, className, classes } = this.props;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    var value = getValue(this.props);
    if (!R.isNil(value)) value = new Date(value);

    if (!dayjs(value).isValid()) value = new Date();
    // Material UI DatePicker & TimePicker don't support other then local timezones, so
    // we create date with same year/month/date/hour/etc which is in the local timezone at render
    // and when setting the changed date in this.handleOnChange, we do the same thing the other way around.
    if (!R.isNil(value)) value = utcAsLocal(value);

    return (
      <div className={className || 'col-xs'}>
        <div className='row middle-xs'>
          <div className='col-xs-12'>
            <DatePicker
              className={classes.picker}
              label={getTitle(this.props)}
              value={value}
              disabled={disabled}
              showTodayButton
              onChange={this.handleOnChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(wrap(DateTimeField));

const localAsUtc = date =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );

const utcAsLocal = date =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
