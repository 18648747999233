import * as actionTypes from './actionTypes';
import { LocalStorage } from '~/utils';

const loadedAutoLogoutDisabled = LocalStorage.get('auth.autoLogoutDisabled') === 'true';

const initialState = {
  serverClientVersionMismatch: false,
  serverVersion: null,

  // If set to true, the dashboard will not log out the user even though the login token seems to be expired.
  // QA requested this when doing testing using time traveling.
  autoLogoutDisabled: loadedAutoLogoutDisabled
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGOUT:
      return logout(state);
    case actionTypes.SERVER_CLIENT_VERSION_MISMATCH:
      return serverClientVersionMismatch(state, action);
    case actionTypes.TOGGLE_AUTO_LOGOUT:
      return toggleAutoLogout(state);
    default:
      return state;
  }
};

const logout = state => {
  state = { ...state, token: null };
  LocalStorage.delete('token');
  LocalStorage.delete('userId');
  return state;
};

const serverClientVersionMismatch = (state, action) => {
  return {
    ...state,
    serverClientVersionMismatch: true,
    serverVersion: action.payload.serverVersion
  };
};

const toggleAutoLogout = state => {
  const autoLogoutDisabled = !state.autoLogoutDisabled;
  LocalStorage.set('auth.autoLogoutDisabled', '' + autoLogoutDisabled);
  return {
    ...state,
    autoLogoutDisabled
  };
};
