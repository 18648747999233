// @ts-nocheck
import * as actionTypes from './actionTypes';

import { compose, onSuccess, createAPIStatusReducer } from '~/reducerUtils';

const initialState = {
  fetchInfractionsStatus: null,
  fetchInfractionsResult: {},
  addInfractionStatus: null,
  updateInfractionsStatus: null,
  fetchCommentsStatus: null,
  fetchCommentsResult: [],
  addCommentStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_INFRACTIONS:
      return fetchInfractions(state, action);
    case actionTypes.ADD_INFRACTION:
      return addInfraction(state, action);
    case actionTypes.UPDATE_INFRACTIONS:
      return updateInfractions(state, action);
    case actionTypes.FETCH_COMMENTS:
      return fetchComments(state, action);
    case actionTypes.ADD_COMMENT:
      return addComment(state, action);
    default:
      return state;
  }
};

const fetchInfractions = compose(
  createAPIStatusReducer(
    'fetchInfractionsStatus',
    'Failed to fetch infractions'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchInfractionsResult: action.payload
  }))
);

const addInfraction = compose(
  createAPIStatusReducer('addInfractionStatus', 'Failed to add an infraction'),
  onSuccess((state, action) => ({
    ...state,
    fetchInfractionsResult: action.payload
  }))
);

const updateInfractions = compose(
  createAPIStatusReducer(
    'updateInfractionsStatus',
    'Failed to update infractions'
  ),
  onSuccess((state, action) => ({
    ...state,
    fetchInfractionsResult: action.payload
  }))
);

const fetchComments = compose(
  createAPIStatusReducer('fetchCommentsStatus', 'Failed to fetch comments'),
  onSuccess((state, action) => ({
    ...state,
    fetchCommentsResult: action.payload.comments
  }))
);

const addComment = compose(
  createAPIStatusReducer('addCommentStatus', 'Failed to add a comment'),
  onSuccess((state, action) => ({
    ...state,
    fetchCommentsResult: action.payload.comments
  }))
);
