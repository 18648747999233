import * as actionTypes from './actionTypes';
import R from 'ramda';

const initialState = {
  tests: [],
  filters: [],
  fetchTestsStatus: null,
  fetchFiltersStatus: null,
  updateTestStatusStatus: null,
  addTestStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TESTS:
      return fetchTests(state, action);
    case actionTypes.ADD_TEST:
      return addTest(state, action);
    case actionTypes.START_TEST:
      return setTestStatus(state, action);
    case actionTypes.PAUSE_TEST:
      return setTestStatus(state, action);
    case actionTypes.ARCHIVE_TEST:
      return setTestStatus(state, action);
    case actionTypes.FETCH_FILTERS:
      return fetchFilters(state, action);
    case actionTypes.UPDATE_TEST:
      return updateTest(state, action);
    default:
      return state;
  }
};

function fetchTests(state, action) {
  const { payload } = action;
  const result = payload && payload.result;
  const content = payload && payload.content;

  if (action.pending) {
    return {
      ...state,
      fetchTestsStatus: 'pending'
    };
  } else if (action.error || result !== 100) {
    return {
      ...state,
      fetchTestsStatus: `failed${content ? ':' + content : ''}`
    };
  } else {
    return {
      ...state,
      fetchTestsStatus: null,
      tests: action.payload.content
    };
  }
}

function addTest(state, action) {
  const { payload } = action;
  const result = payload && payload.result;
  const content = payload && payload.content;

  if (action.pending) {
    return {
      ...state,
      addTestStatus: 'pending'
    };
  } else if (action.error || result !== 100) {
    return {
      ...state,
      addTestStatus: `failed${content ? ':' + content : ''}`
    };
  } else {
    return {
      ...state,
      addTestStatus: null,
      tests: [...state.tests, content]
    };
  }
}

function updateTest(state, action) {
  const { payload } = action;
  const result = payload && payload.result;
  const content = payload && payload.content;

  if (action.pending) {
    return {
      ...state,
      updateTestStatusStatus: 'pending'
    };
  } else if (action.error || result !== 100) {
    return {
      ...state,
      updateTestStatusStatus: `failed${content ? ':' + content : ''}`
    };
  } else {
    const updatedId = content.id;
    const updatedIndex = R.findIndex(({ id }) => id === updatedId, state.tests);
    let updatedTests = state.tests;
    if (updatedIndex !== -1) {
      updatedTests = R.update(updatedIndex, content, state.tests);
    }
    return {
      ...state,
      updateTestStatusStatus: null,
      tests: updatedTests
    };
  }
}

function fetchFilters(state, action) {
  const { payload } = action;
  const result = payload && payload.result;
  const content = payload && payload.content;
  if (action.pending) {
    return {
      ...state,
      fetchFiltersStatus: 'pending'
    };
  } else if (action.error || result !== 100) {
    return {
      ...state,
      fetchFiltersStatus: `failed${content ? ':' + content : ''}`
    };
  } else {
    return {
      ...state,
      fetchFiltersStatus: null,
      filters: action.payload.content.filters
    };
  }
}

function setTestStatus(state, action) {
  const { testId } = action.meta;
  const { payload } = action;
  const result = payload && payload.result;
  const content = payload && payload.content;

  if (action.pending) {
    return {
      ...state,
      updateTestStatusStatus: 'pending'
    };
  } else if (action.error || result !== 100) {
    return {
      ...state,
      updateTestStatusStatus: `failed${content ? ':' + content : ''}`
    };
  } else {
    const indexOfUpdatedTest = R.findIndex(R.propEq('id', testId), state.tests);
    const updatedTest = content;

    const found = indexOfUpdatedTest !== -1;
    let updatedTests = state.tests;
    if (found) {
      updatedTests = R.update(indexOfUpdatedTest, updatedTest, state.tests);
    }

    return {
      ...state,
      updateTestStatusStatus: null,
      tests: updatedTests
    };
  }
}
