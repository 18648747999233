import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    float: 'left',
    marginRight: '1px'
  },
  clickableRoot: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  text: {
    userSelect: 'none',
    position: 'relative',
    display: 'block',
    float: 'left',
    padding: '10px 0 10px 50px',
    marginRight: '-1px'
  },
  clickableText: {
    '&:hover': {
      background: theme.palette.grey[300],
      '&::after': {
        borderLeftColor: `${theme.palette.grey[300]} !important`
      }
    }
  },
  border: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    position: 'relative',
    width: '99%',
    height: '32px',
    zIndex: 100,
    display: 'block',
    pointerEvents: 'none'
  },
  borderLast: {
    borderRight: `1px solid ${theme.palette.grey[300]}`
  },
  borderFirst: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`
  },
  notLastChildText: {
    color: theme.palette.text.secondary,

    '&::after': {
      content: '" "',
      display: 'block',
      width: 0,
      height: 0,
      borderTop: '50px solid transparent',
      borderBottom: '50px solid transparent',
      borderLeft: '30px solid white',
      position: 'absolute',
      top: '50%',
      marginTop: '-50px',
      left: '100%',
      zIndex: 2
    },
    '&::before': {
      content: '" "',
      display: 'block',
      width: 0,
      height: 0,
      borderTop: '50px solid transparent',
      borderBottom: '50px solid transparent',
      borderLeft: `30px solid ${theme.palette.grey[300]}`,
      position: 'absolute',
      top: '50%',
      marginTop: '-50px',
      marginLeft: '1px',
      left: '100%',
      zIndex: 1
    }
  },
  firstChildText: {
    paddingLeft: '20px'
  },
  lastChildText: {
    color: theme.palette.text.primary,
    paddingRight: '20px'
  }
});

class BreadcrumbItem extends Component {
  static displayName = 'BreadcrumbItem';

  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    firstChild: PropTypes.bool.isRequired,
    lastChild: PropTypes.bool.isRequired
  };

  render() {
    const { text, classes, onClick, firstChild, lastChild } = this.props;

    return (
      <li
        className={classNames(classes.root, onClick && classes.clickableRoot)}
        onClick={onClick}
      >
        <Typography
          className={classNames(
            classes.text,
            firstChild && classes.firstChildText,
            lastChild && classes.lastChildText,
            !lastChild && classes.notLastChildText,
            onClick && classes.clickableText
          )}
          variant='button'
        >
          {' '}
          {text}{' '}
        </Typography>
        <span
          className={classNames(
            classes.border,
            firstChild && classes.borderFirst,
            lastChild && classes.borderLast
          )}
        />
      </li>
    );
  }
}

export default withStyles(styles)(BreadcrumbItem);
