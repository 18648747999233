import React, { FC, ReactNode } from 'react';
import * as R from 'ramda';
import useHasPermissions from '../auth/hooks/useHasPermissions';

interface AuthenticatorProps {
  children: ReactNode | ReactNode[];
  permissions: string[];
  hideIfUnauthorized?: boolean;
  replacement?: ReactNode;
}

const Authenticator: FC<AuthenticatorProps> = ({ permissions, hideIfUnauthorized = false, replacement, children }) => {
  const authorized = useHasPermissions(permissions);

  if (hideIfUnauthorized && !authorized) {
    return null;
  }

  if (!R.isNil(replacement) && !authorized) {
    return <>{replacement}</>;
  }

  return <>{children}</>;
};

export default Authenticator;
