import R from 'ramda';

export function extractRoles(accounts) {
  // TODO: Map all roles from the accounts?
  const roles = accounts.length > 0 ? R.pathOr([], ['idTokenClaims', 'roles'], accounts[0]) : [];
  return roles;
}

export function extractUserName(accounts) {
  const name = accounts.length > 0 ? R.pathOr([], ['idTokenClaims', 'name'], accounts[0]) : '<unknown>';
  return name;
}
