import { NAME } from './constants';
import { NAME as parentName} from '../constants';

export const SEARCH = `${parentName}/${NAME}/SEARCH`;
export const SEARCH_FIELDS_CHANGED = `${parentName}/${NAME}/SEARCH_FIELDS_CHANGED`;
export const CLEAR_SEARC_RESULTS = `${parentName}/${NAME}/CLEAR_SEARC_RESULTS`;
export const CREATE_CASE = `${parentName}/${NAME}/CREATE_CASE`;
export const GET_CASE = `${parentName}/${NAME}/GET_CASE`;
export const SET_ERASURE_REQUEST_STATUS = `${parentName}/${NAME}/SET_ERASURE_REQUEST_STATUS`;
export const GENERATE_ACCESS_LINK = `${parentName}/${NAME}/GENERATE_ACCESS_LINK`;
export const CLEAR_ACCESS_LINK = `${parentName}/${NAME}/CLEAR_ACCESS_LINK`;
export const TRIGGER_ERASURE = `${parentName}/${NAME}/TRIGGER_ERASURE`;
export const TRIGGER_ERASURE_FOR_SPECIFIC_REQUEST = `${parentName}/${NAME}/TRIGGER_ERASURE_FOR_SPECIFIC_REQUEST`;
export const ANONYMIZE_CASE = `${parentName}/${NAME}/ANONYMIZE_CASE`;