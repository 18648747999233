import React, { Component } from 'react';
import {
  basePropTypes,
  getValue,
  setValue,
  componentMounts,
  validate,
  getTitle,
  assertProps,
  wrap,
  fieldExists
} from './FieldBase';
import PropTypes from 'prop-types';
import R from 'ramda';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import ContentAdd from '@material-ui/icons/Add';
import ActionDelete from '@material-ui/icons/Delete';
import { LocalStorage } from '~/utils';
import { withStyles } from '@material-ui/core/styles';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';

const containerStyle = {
  marginBottom: '2em'
};
const errorStyle = {
  color: 'red'
};

const styles = theme => ({
  dividerStyle: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: -12,
    marginRight: -12
  },
  addButtonStyle: {
    marginLeft: -20,
    marginTop: -20
  },
  newRowStyle: {
    marginTop: '.5em',
    borderTop: 'dashed 1px #D4D4D4',
    width: '100%'
  },
  card: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class ListField extends Component {
  static propTypes = {
    // If true, allows list to be expanded and collapsed
    expandable: PropTypes.bool,
    // If true, the items can be added and removed from the list. Defaults to true.
    dynamic: PropTypes.bool,

    // Subtitle for the CardHeader
    subtitle: PropTypes.string,

    //Cards can be hidden from view
    visible: PropTypes.bool,

    //Create an anchor element for this if it's present
    navId: PropTypes.string,

    //Create an icon with a link to this documentation url if provided
    documentation: PropTypes.array,

    // Defines a primary key for lsit items of type EntityField.
    // When a primary key is defined there can be
    // no two list items with the same values for that property.
    primaryKey: PropTypes.string,

    // Defines which keys should be omitted when adding new items to the list. Otherwise the values from the previous
    // entry will be copied.
    omittedKeys: PropTypes.arrayOf(PropTypes.string),

    ...basePropTypes
  };

  static defaultProps = {
    defaultValue: [],
    dynamic: true,
    expandable: true,
    visible: true
  };

  constructor(props) {
    assertProps(props, ListField);
    super(props);

    this.state = {
      expanded: LocalStorage.get('ListField-' + props.field) === 'true'
    };
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  componentDidMount() {
    if (!R.isNil(this.props.navId))
      window.addEventListener(
        'hashchange',
        this.handleHashChange.bind(this),
        false
      );
  }

  componentWillUnmount() {
    if (!R.isNil(this.props.navId))
      window.removeEventListener('hashchange', this.handleHashChange, false);
  }

  handleHashChange() {
    const { expandable, navId } = this.props;
    const id = window.location.hash.replace('#', '');
    if (expandable && id == navId && !this.state.expanded)
      this.setState({ expanded: true });
  }

  handleExpandChange = () => {
    const expanded = !this.state.expanded;
    LocalStorage.set('ListField-' + this.props.field, expanded);
    this.setState({ expanded });
  };

  getError = value => validate(this.props, value);

  handleChange = newList =>
    setValue(this.props, newList, !R.isNil(this.getError(newList)));
  handleCreate = value => {
    const newList = R.append(value, getValue(this.props));
    setValue(this.props, newList, !R.isNil(this.getError(newList)));
  };

  getValuesDisabledByPrimaryKey() {
    if (R.isNil(this.props.primaryKey)) {
      return [];
    }
    return R.pluck(this.props.primaryKey, getValue(this.props));
  }

  renderEntity(list, listItem, index, primaryKeyDisabledValues) {
    const { dynamic, disabled, primaryKey } = this.props;
    const removable = !disabled && dynamic !== false;

    const child = React.cloneElement(this.props.children, {
      value: list,
      field: index,
      disabled: this.props.children.props.disabled || this.props.disabled,
      hideUndefined:
        this.props.children.props.hideUndefined || this.props.hideUndefined,
      skipDefaultValuesOnMount:
        this.props.children.props.skipDefaultValuesOnMount ||
        this.props.skipDefaultValuesOnMount,
      parentPrimaryKey: primaryKey,
      primaryKeyDisabledValues,
      onChange: this.handleChange
    });

    const removeItem = () => {
      this.handleChange(R.remove(index, 1, list));
    };

    return (
      <div className='row middle-xs'>
        <div className='col-xs-11'>{child}</div>
        {!disabled && removable && (
          <div className='col-xs-1'>
            <Fab size='small' onClick={removeItem}>
              <ActionDelete />
            </Fab>
          </div>
        )}
      </div>
    );
  }

  renderDocumentation = () => {
    const { documentation } = this.props;

    return (
      <div>
        {documentation.map((v, i) => (
          <Tooltip key={i} title={v.tooltip}>
            <IconButton onClick={() => window.open(v.url, '_blank')}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };

  render() {
    if (this.props.children instanceof Array) {
      throw new Error(
        'ListField can only have one child field. You may want to use an EntityField.'
      );
    }

    const {
      disabled,
      dynamic,
      primaryKey,
      className,
      visible,
      classes,
      expandable,
      subtitle,
      navId,
      documentation,
      omittedKeys = []
    } = this.props;
    const list = getValue(this.props);

    if (!Array.isArray(list)) {
      console.error('ListField did not recieve a proper array. Value = ', list);
      return null;
    }

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    if (!visible) return null;

    const error = this.getError(list);

    const primaryKeyDisabledValues = this.getValuesDisabledByPrimaryKey();

    if (expandable !== true) {
      return (
        <div style={containerStyle} className={className || 'col-xs'}>
          {navId && <a id={navId} />}
          <Card className={classes.card}>
            <CardHeader
              className='fast-form-card-header'
              title={getTitle(this.props)}
              subheader={this.props.subtitle}
              titleTypographyProps={{ variant: 'h6' }}
            />
            <CardContent>
              <span style={errorStyle}>{error}</span>
              {list &&
                list.map((listItem, i) => (
                  <span key={i}>
                    {this.renderEntity(
                      list,
                      listItem,
                      i,
                      primaryKeyDisabledValues
                    )}
                    <Divider className={classes.dividerStyle} />
                  </span>
                ))}
            </CardContent>
            {documentation && (
              <CardActions>{this.renderDocumentation()}</CardActions>
            )}
          </Card>
          {!disabled && dynamic !== false && (
            <NewEntityRow
              primaryKey={primaryKey}
              primaryKeyDisabledValues={primaryKeyDisabledValues}
              onCreate={this.handleCreate}
              classNames={classes}
              disabled={disabled}
              omittedKeys={omittedKeys}
            >
              {this.props.children}
            </NewEntityRow>
          )}
        </div>
      );
    } else {
      return (
        <div style={containerStyle} className={className || 'col-xs'}>
          {navId && <a id={navId} />}
          <Accordion
            className={classes.card}
            expanded={this.state.expanded}
            onChange={this.handleExpandChange}
          >
            <AccordionSummary
              className='fast-form-card-header'
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>
                {getTitle(this.props)}
              </Typography>
              {subtitle && (
                <Typography className={classes.secondaryHeading}>
                  {subtitle}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <div className='col-xs-12'>
                <span style={errorStyle}>{error}</span>
                {list &&
                  list.map((listItem, i) => (
                    <span key={i}>
                      {this.renderEntity(
                        list,
                        listItem,
                        i,
                        primaryKeyDisabledValues
                      )}
                      <Divider className={classes.dividerStyle} />
                    </span>
                  ))}
                {!disabled && dynamic !== false && (
                  <NewEntityRow
                    primaryKey={primaryKey}
                    primaryKeyDisabledValues={primaryKeyDisabledValues}
                    onCreate={this.handleCreate}
                    classNames={classes}
                    disabled={disabled}
                    omittedKeys={omittedKeys}
                  >
                    {this.props.children}
                  </NewEntityRow>
                )}
              </div>
            </AccordionDetails>
            {documentation && (
              <AccordionActions>{this.renderDocumentation()}</AccordionActions>
            )}
          </Accordion>
        </div>
      );
    }
  }
}

export default withStyles(styles)(wrap(ListField));

/**
 *  New row is it's own component, so that setting component state doesn't render the whole list again.
 */
class NewEntityRow extends Component {
  static propTypes = {
    primaryKey: PropTypes.string,
    primaryKeyDisabledValues: PropTypes.array,
    onCreate: PropTypes.func.isRequired,
    classNames: PropTypes.object,
    omittedKeys: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
      errors: null
    };
  }

  handleChange = (value, isErrored) => {
    this.setState({
      value: value,
      errors: isErrored
    });
  };

  handleCreate = evt => {
    evt.preventDefault();
    if (!this.hasError()) {
      const { onCreate, primaryKey, omittedKeys = [] } = this.props;
      onCreate(this.state.value);
      if (R.is(Object, this.state.value)) {
        this.setState({
          value: R.omit([ primaryKey, ...omittedKeys ], this.state.value)
        });
      } else {
        this.setState({ value: undefined });
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof this.state.value === 'undefined') {
      if (
        !(nextProps.children instanceof Array) &&
        !R.isNil(nextProps.children.props.defaultValue)
      ) {
        this.setState({
          value: nextProps.children.props.defaultValue
        });
      }
    }
  }

  hasError = () => this.state.errors === true;

  render() {
    const child = React.cloneElement(this.props.children, {
      value: this.state.value,
      parentPrimaryKey: this.props.primaryKey,
      primaryKeyDisabledValues: this.props.primaryKeyDisabledValues,
      onChange: this.handleChange
    });
    const { classNames } = this.props;

    return (
      <span>
        <Paper elevation={3} className={classNames.newRowStyle}>
          <form onSubmit={this.handleCreate}>
            {child}

            {/* Invisiable submit button is needed enable "Enter" -> Submit behaviour */}
            <input
              type='submit'
              style={{
                position: 'absolute',
                left: '-9999px',
                width: '1px',
                height: '1px'
              }}
              tabIndex='-1'
            />
          </form>
        </Paper>
        <Fab
          className={classNames.addButtonStyle}
          size='small'
          disabled={this.hasError() || this.props.disabled}
          onClick={this.handleCreate}
        >
          <ContentAdd />
        </Fab>
      </span>
    );
  }
}
