import * as actionTypes from './actionTypes';
import { compose, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  createFullStateResponseReducer
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LATEST_ASSET_BUNDLE_OPTION:
      return updateLatestAssetBundleOption(state, action);
    case actionTypes.PURGE_LATEST_UNPUBLISHED_ASSET_BUNDLE_OPTION:
      return purgeLatestUnpublishedAssetBundleOption(state, action);
    default:
      return state;
  }
};

const updateLatestAssetBundleOption = compose(
  createUIBlockingActionReducer('Failed to update asset bundle options'),
  onSuccess(assetBundleOptionsMergerReducer)
);

const purgeLatestUnpublishedAssetBundleOption = compose(
  createUIBlockingActionReducer(
    'Failed to delete unpublished asset bundle options changes'
  ),
  createFullStateResponseReducer()
);

/*** Utils  ***/

function assetBundleOptionsMergerReducer(state, action) {
  return {
    ...state,
    latest: {
      ...state.latest,
      assetBundleOptions: R.sortBy(
        R.prop('priority'),
        R.unionWith(
          R.eqBy(R.prop('linkedId')),
          [action.payload],
          state.latest.assetBundleOptions
        )
      )
    }
  };
}
