export const LOAD_EVENT_CONFIGS_PREFILL = 'LOAD_EVENT_CONFIGS_PREFILL';
export const LOAD_MISSION_EVENT_CONFIGS_PREFILL =
  'LOAD_MISSION_EVENT_CONFIGS_PREFILL';
export const LOAD_EVENT_CONFIGS = 'LOAD_EVENT_CONFIGS';
export const CREATE_TOURNAMENT_EVENT_CONFIG = 'CREATE_TOURNAMENT_EVENT_CONFIG';
export const CREATE_TEAM_CHEST_EVENT_CONFIG = 'CREATE_TEAM_CHEST_EVENT_CONFIG';
export const CREATE_END_GAME_EVENT_CONFIG = 'CREATE_END_GAME_EVENT_CONFIG';
export const CREATE_WIN_STREAK_EVENT_CONFIG = 'CREATE_WIN_STREAK_EVENT_CONFIG';
export const CREATE_MISSION_EVENT_CONFIG = 'CREATE_MISSION_EVENT_CONFIG';
export const CREATE_SALE_EVENT_CONFIG = 'CREATE_SALE_EVENT_CONFIG';
export const UPDATE_TOURNAMENT_EVENT_CONFIG = 'UPDATE_TOURNAMENT_EVENT_CONFIG';
export const UPDATE_TEAM_CHEST_EVENT_CONFIG = 'UPDATE_TEAM_CHEST_EVENT_CONFIG';
export const UPDATE_END_GAME_EVENT_CONFIG = 'UPDATE_END_GAME_EVENT_CONFIG';
export const UPDATE_WIN_STREAK_EVENT_CONFIG = 'UPDATE_WIN_STREAK_EVENT_CONFIG';
export const UPDATE_MISSION_EVENT_CONFIG = 'UPDATE_MISSION_EVENT_CONFIG';
export const UPDATE_SALE_EVENT_CONFIG = 'UPDATE_SALE_EVENT_CONFIG';
export const DELETE_TOURNAMENT_EVENT_CONFIG = 'DELETE_TOURNAMENT_EVENT_CONFIG';
export const DELETE_TEAM_CHEST_EVENT_CONFIG = 'DELETE_TEAM_CHEST_EVENT_CONFIG';
export const DELETE_END_GAME_EVENT_CONFIG = 'DELETE_END_GAME_EVENT_CONFIG';
export const DELETE_WIN_STREAK_EVENT_CONFIG = 'DELETE_WIN_STREAK_EVENT_CONFIG';
export const DELETE_MISSION_EVENT_CONFIG = 'DELETE_MISSION_EVENT_CONFIG';
export const DELETE_SALE_EVENT_CONFIG = 'DELETE_SALE_EVENT_CONFIG';

export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOAD_ALL_EVENTS = 'LOAD_ALL_EVENTS';
export const CREATE_TOURNAMENT_EVENT = 'CREATE_TOURNAMENT_EVENT';
export const CREATE_TEAM_CHEST_EVENT = 'CREATE_TEAM_CHEST_EVENT';
export const CREATE_END_GAME_EVENT = 'CREATE_END_GAME_EVENT';
export const CREATE_WIN_STREAK_EVENT = 'CREATE_WIN_STREAK_EVENT';
export const CREATE_MISSION_EVENT = 'CREATE_MISSION_EVENT';
export const CREATE_SALE_EVENT = 'CREATE_SALE_EVENT';
export const UPDATE_TOURNAMENT_EVENT = 'UPDATE_TOURNAMENT_EVENT';
export const UPDATE_TEAM_CHEST_EVENT = 'UPDATE_TEAM_CHEST_EVENT';
export const UPDATE_END_GAME_EVENT = 'UPDATE_END_GAME_EVENT';
export const UPDATE_WIN_STREAK_EVENT = 'UPDATE_WIN_STREAK_EVENT';
export const UPDATE_MISSION_EVENT = 'UPDATE_MISSION_EVENT';
export const UPDATE_SALE_EVENT = 'UPDATE_SALE_EVENT';
export const DELETE_TOURNAMENT_EVENT = 'DELETE_TOURNAMENT_EVENT';
export const DELETE_TEAM_CHEST_EVENT = 'DELETE_TEAM_CHEST_EVENT';
export const DELETE_END_GAME_EVENT = 'DELETE_END_GAME_EVENT';
export const DELETE_WIN_STREAK_EVENT = 'DELETE_WIN_STREAK_EVENT';
export const DELETE_MISSION_EVENT = 'DELETE_MISSION_EVENT';
export const DELETE_SALE_EVENT = 'DELETE_SALE_EVENT';

export const LOAD_ALL_AB_TEST_EVENTS = 'LOAD_ALL_AB_TEST_EVENTS';
export const CREATE_AB_TEST_EVENT = 'CREATE_AB_TEST_EVENT';
export const UPDATE_AB_TEST_EVENT = 'UPDATE_AB_TEST_EVENT';
export const DELETE_AB_TEST_EVENT = 'DELETE_AB_TEST_EVENT';
