import * as actionTypes from './actionTypes';

const initialState = {
  openTeam: null,
  searchResults: [],
  searchStatus: null,
  getStatus: null,
  modifyStatus: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEARCH:
      return search(state, action);
    case actionTypes.SET_OPEN_TEAM:
      return setOpenTeam(state, action);
    case actionTypes.GET_AND_OPEN_TEAM:
      return getAndOpenTeam(state, action);
    case actionTypes.MODIFY:
      return modify(state, action);
    case actionTypes.KICK:
    case actionTypes.PROMOTE:
    case actionTypes.DEMOTE:
    case actionTypes.ACCEPT_REQUEST:
    case actionTypes.REJECT_REQUEST:
    case actionTypes.ADD_PLAYER_TO_TEAM:
      return generalModificationReducer(state, action);
    default:
      return state;
  }
};

const generalModificationReducer = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      modifyStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      modifyStatus: 'Failed to modify team'
    };
  } else {
    const payload =
      (action.payload && action.payload.content) || action.payload;
    return {
      ...state,
      modifyStatus: null,
      openTeam: state.openTeam.id === payload.id ? payload : state.openTeam
    };
  }
};

const modify = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      modifyStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      modifyStatus: 'Failed to modify team'
    };
  } else {
    return {
      ...state,
      modifyStatus: null,
      openTeam:
        state.openTeam.id === action.payload.id
          ? action.payload
          : state.openTeam
    };
  }
};

const setOpenTeam = (state, { payload: { team } }) => ({
  ...state,
  openTeam: team
});

const search = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      searchStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      searchStatus: 'Failed to search for teams'
    };
  } else {
    return {
      ...state,
      searchStatus: null,
      searchResults: action.payload.results
    };
  }
};

const getAndOpenTeam = (state, action) => {
  if (action.pending) {
    return {
      ...state,
      getStatus: 'pending'
    };
  } else if (action.error) {
    return {
      ...state,
      getStatus: 'Failed to get team'
    };
  } else {
    return {
      ...state,
      getStatus: null,
      openTeam: action.payload
    };
  }
};
