import { useMsal } from '@azure/msal-react';
import { extractRoles } from '../utils';

function useRoles(): string[] {
  const { accounts } = useMsal();
  const roles = extractRoles(accounts);
  return roles;
}

export default useRoles;
