import { useMsal } from '@azure/msal-react';
import { TokenClaims } from '@azure/msal-common';

export type UserData = {
  id: string;
  name: string;
  email: string;
}

function useUserData(): UserData {
  const { accounts } = useMsal();
  const account = accounts[0];
  if (account?.idTokenClaims) {
    const idTokenClaims: TokenClaims = account.idTokenClaims;
    return {
      id: idTokenClaims.oid ?? 'unknown',
      name: idTokenClaims.name ?? '<unknown>',
      email: idTokenClaims.preferred_username ?? '<unknown>'
    }
  }
  throw new Error('Not logged in');
}

export default useUserData;
