import * as actionTypes from './actionTypes';
import { compose, onSuccess } from '~/reducerUtils';
import {
  createUIBlockingActionReducer,
  incrementNonPublishedEntitiesCount,
  createFullStateResponseReducer
} from '../reducerUtils';
import * as R from 'ramda';

export default (state, action) => {
  switch (action.type) {
    case actionTypes.ADD_USER_TAG:
      return reduceAddTag(state, action);
    case actionTypes.DELETE_USER_TAG:
      return reduceDeleteTag(state, action);
    case actionTypes.PURGE_LATEST_UNPUBLISHED_USER_GROUP_TAG:
      return purgeLatestUnpublishedUserGroupTag(state, action);
    default:
      return state;
  }
};

const reduceAddTag = compose(
  createUIBlockingActionReducer('Failed to add user group tag'),
  onSuccess(incrementNonPublishedEntitiesCount),
  onSuccess((state, action) => ({
    ...state,
    latest: {
      ...state.latest,
      userGroupTags: [...state.latest.userGroupTags, action.payload]
    }
  }))
);

const reduceDeleteTag = compose(
  createUIBlockingActionReducer('Failed to delete user group tag'),
  onSuccess(incrementNonPublishedEntitiesCount),
  onSuccess((state, action) => ({
    ...state,
    latest: {
      ...state.latest,
      userGroupTags: R.reject(
        R.propEq('id', action.meta.userGroupTag.id),
        state.latest.userGroupTags
      )
    }
  }))
);

const purgeLatestUnpublishedUserGroupTag = compose(
  createUIBlockingActionReducer(
    'Failed to delete unpublished user group tag change'
  ),
  createFullStateResponseReducer()
);
