import { NAME } from './constants';
import { NAME as parentName } from '../constants';

export const GET_META = `${parentName}/${NAME}/GET_META`;
export const LIST = `${parentName}/${NAME}/LIST`;
export const GET_ARCHIVED_GRAPH = `${parentName}/${NAME}/GET_ARCHIVED_GRAPH`;

export const CREATE_ARCHIVE = `${parentName}/${NAME}/CREATE_ARCHIVE`;
export const DELETE_ARCHIVE = `${parentName}/${NAME}/DELETE_ARCHIVE`;

export const GET_ARCHIVE_STATUS = `${parentName}/${NAME}/GET_ARCHIVE_STATUS`;
export const CLEAR_ARCHIVE_STATUS = `${parentName}/${NAME}/CLEAR_ARCHIVE_STATUS`;

export const CLEAR_ARCHIVE_ERRORS = `${parentName}/${NAME}/CLEAR_ARCHIVE_ERRORS`;

export const GET_COMPARISON = `${parentName}/${NAME}/GET_COMPARISON`;
export const COMPARE = `${parentName}/${NAME}/COMPARE`;

export const SET_FILTERS = `${parentName}/${NAME}/SET_FILTERS`;
