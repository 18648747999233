import { FULL_PATH as parentFullPath } from '../constants';
import R from 'ramda';

export const NAME = 'assetBundleConfigurations';
export const FULL_PATH = `${parentFullPath}/${NAME}`;

export const formattedPlatforms = ['iOS', 'Android', 'WebGL', 'Amazon'];
export const platforms = R.map(x => R.toLower(x), formattedPlatforms);
const platformToFormatted = R.zipObj(platforms, formattedPlatforms);
export const getFormattedPlatform = platform => platformToFormatted[platform];
