import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { MsalProvider } from '@azure/msal-react';
import Routes from './routes';
import ADAuthWrapper from './core/auth/components/ADAuthWrapper';

/*eslint-disable */
console.log(`[Seriously Dashboard ${DASHBOARD_ENV}] Version "${DASHBOARD_VERSION}"`);
/*eslint-enable */

const Root = ({ history, store, instance }) => {
  return (
    <ConnectedRouter history={history}>
      <MsalProvider instance={instance}>
        <ADAuthWrapper>{Routes(store)}</ADAuthWrapper>
      </MsalProvider>
    </ConnectedRouter>
  );
};

export default Root;
