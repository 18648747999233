import R from 'ramda';
import dayjs from '~/dayjs';



export const parseProductFromReceipt = (rawData, platform) => {
  const plat = R.toLower(platform);
  let product = 'UNKNOWN PRODUCT';

  if (!R.isNil(rawData)) {
    if (R.equals(plat, 'itunes')) {
      let iosProduct = null;
      const allReceipts = R.pathOr([], ['receipt', 'in_app'], rawData);
      if (allReceipts.length > 0)
        iosProduct = R.propOr('', 'product_id', allReceipts[0]);
      if (allReceipts.length > 1)
        iosProduct += ' + ' + (allReceipts.length - 1) + ' others';
      if (!R.isNil(iosProduct)) product = iosProduct;
    } else if (R.equals(plat, 'googleplay')) {
      const gpProduct = R.pathOr(null, ['json', 'productId'], rawData);
      if (!R.isNil(gpProduct)) product = gpProduct;
    } else if (R.equals(plat, 'facebook')) {
      //TODO
    } else if (R.equals(plat, 'amazon')) {
      //TODO
    }
  }

  return product;
};

export const parseSubscriptionExpireDate = rawData => {
  let dates = [];
  const allReceipts = R.pathOr([], ['receipt', 'in_app'], rawData);
  for (var i in allReceipts) {
    let expireDate = R.propOr(null, 'expires_date', allReceipts[i]);
    if (R.isNil(expireDate)) continue;
    expireDate = expireDate.substring(0, expireDate.lastIndexOf(' '));
    let purchaseDate = R.propOr(null, 'purchase_date', allReceipts[i]);
    if (R.isNil(purchaseDate)) continue;
    purchaseDate = purchaseDate.substring(0, purchaseDate.lastIndexOf(' '));

    dates.push(
      R.assoc(
        'from',
        dayjs(purchaseDate),
        R.assoc('to', dayjs(expireDate), {})
      )
    );
  }
  if (dates.length == 0) return null;
  else {
    const sortDates = (a, b) => b.to.diff(a.to, 'minutes');
    dates = R.sort(sortDates, dates);
    return dates[0];
  }
};

export const parseOrderIdFromGPReceipt = rawData => {
  return R.pathOr('', ['json', 'orderId'], rawData);
};

export const parseReceipt = receipt => {
  /* 
        Parse the receipt data. 
        It is proper JSON in new receipts (after format change Feb 2018), but may be encrypted for older formats.
        So we try to parse it as JSON, if it fails we just render the field as is.
    */
  let receiptData;
  try {
    receiptData = JSON.parse(receipt.receiptResponse || receipt.receipt);
    for (var key in receiptData) {
      if (R.is(String, receiptData[key])) {
        try {
          receiptData[key] = JSON.parse(receiptData[key]);
        } catch (e) {
          // Do nothing, we are just trying to deserialize the fields that are proper JSON.
        }
      }
    }
  } catch (e) {
    receiptData = receipt.receipt;
  }
  return receiptData;
};

export const isGPReceipt = (receipt, isOld) => {
  return isOld
    ? receipt.store == 'GooglePlay'
    : receipt.platform == 'GooglePlay';
};

export const getOrderId = (receipt, isOld) => {
  if (isOld) {
    try {
      const receiptData = JSON.parse(receipt.rawReceipt);
      receiptData.json = JSON.parse(receiptData.json);
      return parseOrderIdFromGPReceipt(receiptData);
    } catch (err) {
      console.log(err);
    }
  } else {
    let orderId = '';
    const parsedReceipt = parseReceipt(receipt);
    if (isGPReceipt) orderId = parseOrderIdFromGPReceipt(parsedReceipt);
    return orderId;
  }
};

export const getProductId = (receipt, isOld) => {
  if (isOld) {
    try {
      let productId =
        receipt.products && receipt.products.length === 1
          ? receipt.products[0].productId
          : null;
      if (!R.isNil(receipt.rawReceipt) && !R.isEmpty(receipt.rawReceipt)) {
        const receiptData = JSON.parse(receipt.rawReceipt);
        if (R.isNil(productId))
          productId = parseProductFromReceipt(receiptData, receipt.store);
      }
      return productId;
    } catch (err) {
      console.log(err);
    }
  } else {
    let productId = receipt.expectedProductId;
    const parsedReceipt = parseReceipt(receipt);
    if (R.isNil(productId))
      productId = parseProductFromReceipt(parsedReceipt, receipt.platform);
    return productId;
  }
};

// iTunes receipts pre this date didn't have enough data to show status.
const iTunesCutOffDate = dayjs.utc('2016-12-01T00:00:00.000Z');

const isiTunesReceiptUnknown = receipt =>
  receipt.store === 'iTunes' &&
  receipt.status !== 'OK' &&
  dayjs.utc(receipt.createdAt).isBefore(iTunesCutOffDate);
const receiptIsEmpty = receipt =>
  !receipt || !receipt.products || receipt.products.length === 0;

export const getStatus = (receipt, isOld) => {
  let status = '';
  let validity = '';
  if (isOld) {
    if (isiTunesReceiptUnknown(receipt)) {
      status = 'UNKNOWN (too old)';
    } else if (receiptIsEmpty(receipt)) {
      status = 'EMPTY';
    } else {
      status = receipt.status;
    }

    validity = 'invalid';
    if (receiptIsEmpty(receipt)) {
      validity = 'suspicious';
    } else if (status === 'OK') {
      if (R.toLower(receipt.store) === 'itunes') validity = 'valid';
      else validity = 'unknown';
    }
    status = status + ' (Old Format)';
  } else {
    status = receipt.verificationResult;
    validity = 'suspicious';
    if (status === 'Valid' || status === 'ValidRefresh') {
      validity = 'valid';
    } else if (status === 'Invalid') {
      validity = 'invalid';
    }
  }

  return { status, validity };
};
