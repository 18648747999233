import React, { Component } from 'react';
import {
  basePropTypes,
  getValue,
  setValue,
  componentMounts,
  getTitle,
  assertProps,
  wrap,
  isValidField,
  fieldExists
} from './FieldBase';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Tooltip from '@material-ui/core/Tooltip';

import { LocalStorage } from '~/utils';
import PropTypes from 'prop-types';
import R from 'ramda';

const styles = theme => ({
  card: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  condensedCard: {
    margin: '0.3em'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
});

class EntityField extends Component {
  static propTypes = {
    ...basePropTypes,

    // If true, allows entity to be expanded and collapsed (only works if isCard is true)
    expandable: PropTypes.bool,

    // If true, the entity will be rendered inside a Card (for more emphasis)
    isCard: PropTypes.bool,

    condenseCard: PropTypes.bool,

    // Subtitle for the CardHeader
    subtitle: PropTypes.string,

    //Cards can be hidden from view
    visible: PropTypes.bool,

    //Create an anchor element for this if it's present
    navId: PropTypes.string,

    //Create an icons with a links/tooltips to this documentation url if provided, format: [{url:'', tooltip:''}]
    documentation: PropTypes.array,

    /*** Internal ***/

    // Parent ListField injects possible primary key and disabled values.
    parentPrimaryKey: PropTypes.string,
    primaryKeyDisabledValues: PropTypes.array,

    //optional extra control elements to be rendered for this EntityField
    controls: PropTypes.node
  };

  static defaultProps = {
    defaultValue: {},
    expandable: true,
    visible: true,
    condenseCard: false
  };

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  componentDidMount() {
    if (!R.isNil(this.props.navId))
      window.addEventListener(
        'hashchange',
        this.handleHashChange.bind(this),
        false
      );
  }

  componentWillUnmount() {
    if (!R.isNil(this.props.navId))
      window.removeEventListener('hashchange', this.handleHashChange, false);
  }

  handleHashChange() {
    const { expandable, navId } = this.props;
    const id = window.location.hash.replace('#', '');
    if (expandable && id == navId && !this.state.expanded)
      this.setState({ expanded: true });
  }

  constructor(props) {
    assertProps(props, EntityField);
    super(props);
    this.state = {
      errors: {},
      expanded: LocalStorage.get('EntityField-' + props.field) === 'true'
    };
  }

  handleExpandChange = () => {
    const expanded = !this.state.expanded;
    LocalStorage.set('EntityField-' + this.props.field, expanded);
    this.setState({ expanded });
  };

  handleChange = field => (newData, errored) => {
    const errors = { ...this.state.errors };
    if (errored) {
      errors[field] = true;
    } else {
      delete errors[field];
    }

    setValue(this.props, newData, R.keys(errors).length > 0);
    this.setState(() => ({ errors }));
  };

  renderDocumentation = () => {
    const { documentation } = this.props;

    return (
      <div>
        {documentation.map((v, i) => (
          <Tooltip key={i} title={v.tooltip}>
            <IconButton onClick={() => window.open(v.url, '_blank')}>
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };

  render() {
    const {
      classes,
      visible,
      expandable,
      subtitle,
      navId,
      documentation,
      condenseCard,
      controls
    } = this.props;

    if (!visible) return null;

    if (this.props.hideUndefined && !fieldExists(this.props)) {
      return null;
    }

    const value = getValue(this.props);
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (isValidField(child)) {
        let childProps = {
          value,
          disabled: child.props.disabled || this.props.disabled,
          visible: !(
            child.props.visible === false || this.props.visible === false
          ),
          hideUndefined: child.props.hideUndefined || this.props.hideUndefined,
          skipDefaultValuesOnMount:
            child.props.skipDefaultValuesOnMount ||
            this.props.skipDefaultValuesOnMount,
          onChange: this.handleChange(child.props.field)
        };
        if (
          !R.isNil(this.props.parentPrimaryKey) &&
          child.props.field === this.props.parentPrimaryKey
        ) {
          childProps.disabledValues = this.props.primaryKeyDisabledValues;
        }
        return React.cloneElement(child, childProps);
      } else {
        return child;
      }
    });
    const title = getTitle(this.props);

    if (this.props.isCard !== true) {
      return (
        <div className={this.props.className || 'col-xs'}>
          <div className='row'>
            {title && <div className='col-xs-12'> {title} </div>}
            {controls && controls}
            {childrenWithProps}
          </div>
        </div>
      );
    } else if (expandable !== true) {
      return (
        <div className={this.props.className || 'col-xs'}>
          {navId && <a id={navId} />}
          <Card className={condenseCard ? classes.condensedCard : classes.card}>
            <CardHeader
              title={getTitle(this.props)}
              className='fast-form-card-header'
              subheader={subtitle}
              titleTypographyProps={{
                variant: 'h6'
              }}
              subheaderTypographyProps={{
                variant: 'subtitle1'
              }}
            />
            <CardContent>
              {controls && <div className='row'>{controls}</div>}
              <div className='row'>{childrenWithProps}</div>
            </CardContent>
            {documentation && (
              <CardActions>{this.renderDocumentation()}</CardActions>
            )}
          </Card>
        </div>
      );
    } else {
      return (
        <div className={this.props.className || 'col-xs'}>
          {navId && <a id={navId} />}
          <Accordion
            className={classes.card}
            expanded={this.state.expanded}
            onChange={this.handleExpandChange}
          >
            <AccordionSummary
              className='fast-form-card-header'
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading}>
                {getTitle(this.props)}
              </Typography>
              {subtitle && (
                <Typography className={classes.secondaryHeading}>
                  {subtitle}
                </Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>
              {controls && controls}
              <div className='row'>{childrenWithProps}</div>
            </AccordionDetails>
            {documentation && (
              <AccordionActions>{this.renderDocumentation()}</AccordionActions>
            )}
          </Accordion>
        </div>
      );
    }
  }
}

//export default wrap(EntityField);
export default withStyles(styles)(wrap(EntityField));
