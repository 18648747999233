import AvStop from 'material-ui/svg-icons/av/stop';
import AvPlayArrow from 'material-ui/svg-icons/av/play-arrow';
import AvPause from 'material-ui/svg-icons/av/pause';
import ActionDelete from 'material-ui/svg-icons/action/delete';

export const NAME = 'abtesting';

export const TestStatusId = {
  STOPPED: 0,
  STARTED: 1,
  PAUSED: 2,
  ARCHIVED: 3
};
export const TestStatus = {
  STOPPED: {
    id: TestStatusId.STOPPED,
    color: 'inherit',
    icon: AvStop,
    statusName: 'Stopped',
    actionName: 'STOP',
    canChangeTo: [TestStatusId.STARTED, TestStatusId.ARCHIVED]
  },
  STARTED: {
    id: TestStatusId.STARTED,
    color: 'inherit',
    icon: AvPlayArrow,
    statusName: 'Started',
    actionName: 'START',
    canChangeTo: [TestStatusId.PAUSED]
  },
  PAUSED: {
    id: TestStatusId.PAUSED,
    color: 'inherit',
    icon: AvPause,
    statusName: 'Paused',
    actionName: 'PAUSE',
    canChangeTo: [TestStatusId.STARTED, TestStatusId.ARCHIVED]
  },
  ARCHIVED: {
    id: TestStatusId.ARCHIVED,
    color: 'inherit',
    icon: ActionDelete,
    statusName: 'Archived',
    actionName: 'ARCHIVE',
    canChangeTo: []
  }
};
