import React, { PureComponent } from 'react';
import {
  basePropTypes,
  getValue,
  componentMounts,
  setValue,
  getTitle,
  assertProps,
  wrap,
  fieldExists,
  getTooltip,
  getTooltipDelay
} from './FieldBase';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';

class BoolField extends PureComponent {
  static propTypes = {
    ...basePropTypes
  };

  static defaultProps = {
    defaultValue: false
  };

  constructor(props) {
    assertProps(props, BoolField);
    super(props);
  }

  UNSAFE_componentWillMount() {
    componentMounts(this.props);
  }

  handleOnCheck = event => setValue(this.props, event.target.checked);

  getValue() {
    const value = getValue(this.props);

    if (typeof value === 'boolean') return value;
    else return value === 'true';
  }

  render() {
    if (this.props.visible === false) return null;

    if (this.props.hideUndefined && !fieldExists(this.props)) return null;

    const { disabled, className } = this.props;

    return (
      <div className={className || 'col-xs'}>
        <Tooltip
          title={getTooltip(this.props)}
          enterDelay={getTooltipDelay(this.props)}
        >
          <FormControlLabel
            control={
              <Switch
                checked={this.getValue()}
                disabled={disabled}
                onChange={this.handleOnCheck}
                color='primary'
              />
            }
            label={getTitle(this.props)}
          />
        </Tooltip>
      </div>
    );
  }
}

export default wrap(BoolField);
